import { createAction, props } from '@ngrx/store';
import { Topic, TopicObject } from './topics.interfaces';

export const cancelledRequest = createAction(
  '[Topics] Cancelled request',
);

export const loadTopics = createAction(
  '[Topics] Load topics',
);
export const loadTopicsSuccess = createAction(
  '[Topics] Load topics success',
  props<{ topics: TopicObject }>(),
);
export const loadTopicsFail = createAction(
  '[Topics] Load topics failure',
  props<{ error: any }>(),
);

export const addLocalTopic = createAction(
  '[Topics] Add local topic-dashboard',
  props<{ topic: Topic, topicParentProperty?: string }>(),
);

export const updateLocalTopic = createAction(
  '[Topics] Update local topic-dashboard',
  props<{ topic: Topic, topicProperty: string, subtopicProperty?:string }>(),
);

export const uploadTopics = createAction(
  '[Topics] Upload topics',
  props<{ topics: TopicObject }>(),
);
export const uploadTopicsSuccess = createAction(
  '[Topics] Upload topics success',
  props<{ topics: TopicObject }>(),
);
export const uploadTopicsFail = createAction(
  '[Topics] Upload topics fail',
  props<{ error: any }>(),
);
