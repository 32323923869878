import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MatPaginator } from '@angular/material';
import { EditorUser, EditorUserPreview, rolesKeys } from '../../../core/ngrx/editorUsers/editorUsers.interfaces';
import { select, Store } from '@ngrx/store';
import { deleteUser, loadUsers, openEditUserForm } from '../../../core/ngrx/editorUsers/editorUsers.actions';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { getSelectedUserState, getUsersState } from '../../../core/ngrx/editorUsers/editorUsers.selectors';
import { getAuthState } from '../../../core/ngrx/authentication/authentication.selectors';

@Component({
  selector: 'em-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit, OnDestroy {

  $destroy: Subject<boolean> = new Subject<boolean>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  defaultMatPaginatorValue = environment.defaultPaginatorValue;
  localeDateOptions = environment.localeDateOptions;

  currentUser: EditorUser;
  usersPreviews: EditorUserPreview[] = [];
  usersPreviewsFiltered: EditorUserPreview[] = [];
  usersPreviewsLength = 0;
  isLoading = true;
  roles = rolesKeys;

  constructor (private store: Store<any>) { }

  ngOnInit () {
    this.setStateSubscriptions();
  }

  setStateSubscriptions () {
    const states = combineLatest([
      this.store.pipe(takeUntil(this.$destroy), select(getUsersState)),
      this.store.pipe(takeUntil(this.$destroy), select(getSelectedUserState)),
    ]);
    states.subscribe(([users, selectedUser]) => {
      if (users.previewsTotalCount > 0) {
        this.usersPreviews = users.previews;
        this.usersPreviewsFiltered = users.previews.slice(0, this.defaultMatPaginatorValue);
        this.usersPreviewsLength = users.previewsTotalCount;
        if (this.paginator) {
          this.paginator.firstPage();
          this.changedPage({ pageIndex: 0, pageSize: this.paginator.pageSize });
        }
      } else {
        if (!users.loading && users.previewsTotalCount === -1) {
          this.store.dispatch(loadUsers());
        }
      }
      this.isLoading = users.loading || selectedUser.loading;
    });
    this.store.pipe(
      takeUntil(this.$destroy),
      select(getAuthState),
    ).subscribe((authState) => {
      this.currentUser = authState ? authState.user : undefined;
    });
  }

  getRole (key: string): string {
    const role = this.roles.find(r => r.value === key);
    return role ? role.text : '';
  }

  changedPage (event) {
    const startIndex = event.pageIndex * event.pageSize;
    this.usersPreviewsFiltered = this.usersPreviews.slice(startIndex, startIndex + event.pageSize);
  }

  openUserForm (userPreview?: EditorUserPreview) {
    this.store.dispatch(openEditUserForm({ userPreview }));
  }

  openUserDeletionDialog (user: EditorUserPreview) {
    Swal.fire(
      {
        type: 'warning',
        title: '¿Estás seguro?',
        text: `Al eliminar el usuario de ${user.email} no podrá accesar al editor y sus datos serán eliminados del sistema`,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Eliminar!',
      },
    ).then((result) => {
      if (result.value) {
        this.store.dispatch(deleteUser({ user }));
      }
    });
  }

  ngOnDestroy (): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
