import { Injectable } from '@angular/core';
import { Action, AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { firestore } from 'firebase/app';
import {
  Description,
  DescriptionCount,
  DescriptionDocPreview,
  DescriptionPreview,
} from '../../core/ngrx/descriptions/descriptions.interfaces';
import { Observable, of } from 'rxjs';
import * as _cloneDeep from 'lodash/cloneDeep';
import { fromPromise } from 'rxjs/internal-compatibility';
const Timestamp = firestore.Timestamp;

@Injectable({
  providedIn: 'root',
})
export class DescriptionService {

  constructor (private fireStoreDB: AngularFirestore) { }

  getDescriptionsSize (): Observable<number> {
    return this.fireStoreDB.doc(`${environment.projectAdminId}/counters/descriptions`).snapshotChanges().pipe(map(
      (action: any) => {
        const data: DescriptionCount = action.payload.data();
        return data ? data.totalCount : 0;
      }));
  }

  getDescriptions (index: number): Observable<DescriptionPreview[]> {
    return this.fireStoreDB.doc(`${environment.projectAdminId}/counters/descriptions/previews/desc_${index}`).snapshotChanges().pipe(map(
      (a: any) => {
        const preview: DescriptionDocPreview = a.payload.data();
        return preview ? preview.data : [];
      }),
    );
  }

  getDescription (reference: firestore.DocumentReference | string): Observable<Description> {
    const path = reference instanceof firestore.DocumentReference ? reference.path : reference;
    const doc = this.fireStoreDB.doc<Description>(path);
    if (!doc.ref) {
      return of(undefined);
    }
    return doc.snapshotChanges().pipe(map(
      (a: Action<DocumentSnapshot<Description>>) => {
        return a.payload.data();
      }),
    );
  }

  getDescriptionId (reference: firestore.DocumentReference | string): string {
    if (reference instanceof firestore.DocumentReference) {
      return reference.id;
    }
    return this.fireStoreDB.doc(reference).ref.id;
  }

  saveDescription (desc: Description): Observable<string> {
    const id = desc.id ? desc.id : this.fireStoreDB.createId();
    let description = _cloneDeep(desc);
    if (!description.created) {
      description = { ...description, created: Timestamp.now() };
    } else {
      description.created = new Timestamp(description.created.seconds, description.created.nanoseconds);
    }
    description = { ...description, updated: Timestamp.now() };
    delete description.id;
    const observable = fromPromise(this.fireStoreDB.doc(`${environment.projectId}/descriptions/${id}`).set(description));
    return observable.pipe(map(() => id));
  }
}
