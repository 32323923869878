import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {  MatPaginator } from '@angular/material';
import { combineLatest, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { QuestionCount, QuestionCountData, QuestionPreview, Status, statusArray } from '../../../core/ngrx/questions/questions.interfaces';
import { Topic } from '../../../core/ngrx/topics/topics.interfaces';
import { EditorUser } from '../../../core/ngrx/editorUsers/editorUsers.interfaces';
import { select, Store } from '@ngrx/store';
import { take, takeUntil, auditTime } from 'rxjs/operators';
import { getQuestionsCountersState, getQuestionsState, getSelectedQuestionState } from '../../../core/ngrx/questions/questions.selectors';
import { getAuthState } from '../../../core/ngrx/authentication/authentication.selectors';
import {
  changeQuestionsPaginationValue,
  deactivateQuestion, deleteQuestion,
  loadNewQuestionsPage,
  publishQuestion,
  reactivateQuestion,
} from '../../../core/ngrx/questions/questions.actions';
import { AppPemits } from '../../../core/ngrx/authentication/authentication.interfaces';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from '../../../services/Helper/helper.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'util';
// tslint:disable-next-line:import-name
import Swal from 'sweetalert2';

@Component({
  selector: 'em-question',
  templateUrl: './question-dashboard.component.html',
  styleUrls: ['./question-dashboard.component.scss'],
})
export class QuestionDashboardComponent implements OnInit, OnDestroy {

  $destroy: Subject<boolean> = new Subject<boolean>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  defaultMatPaginatorValue = environment.defaultPaginatorValue;
  loadedPaginator = false;
  filterForm: FormGroup;

  isLoading = true;
  pageLoading = false;
  isReversed = false;
  lastPaginationIndexLoaded = -1;
  questionsLength: number;
  maxIndex: number;
  questions: QuestionPreview[];
  statusCounters: QuestionCount;
  selectedStatus: Status | '';
  selectedStatusCount = 0;
  filteredQuestions: QuestionPreview[];
  topics: Topic[];
  statusValues = [{ value: '', text: 'Todas' }, ...statusArray];

  currentUser: EditorUser;
  userPermits: AppPemits;
  localeDateOptions = environment.localeDateOptions;

  constructor (private store: Store<any>, private route: ActivatedRoute, private helper: HelperService, private formBuilder: FormBuilder) {
    this.filterForm = this.formBuilder.group(
      {
        status: [''],
      },
    );
    this.filterForm.get('status').valueChanges.pipe(takeUntil(this.$destroy)).subscribe(v => this.changeStatusCounters(v, false));
  }

  ngOnInit () {
    const checkTime = 50;
    let interval;
    this.setStateSubscriptions();
    this.route.url.subscribe((segmentArray) => {
      if (!interval && segmentArray.length === 1 && segmentArray[0].path === 'editor') {
        this.pageLoading = true;
        interval = setInterval(
          () => {
            if (!this.isLoading && this.paginator) {
              this.setInitialPaginationValues();
              clearInterval(interval);
            }
          },
          checkTime,
        );
      }
    });
  }

  setStateSubscriptions () {
    const auditTimeVal = 150;
    this.store.pipe(takeUntil(this.$destroy), select(getAuthState)).subscribe((u) => {
      if (u && u.user) {
        this.isReversed = u.user.newToOld;
        this.currentUser = u.user;
        this.userPermits = u.appPermits;
      } else {
        this.currentUser = undefined;
      }
    });
    const states = combineLatest([
      this.store.pipe(takeUntil(this.$destroy), auditTime(auditTimeVal), select(getQuestionsState)),
      this.store.pipe(takeUntil(this.$destroy), auditTime(auditTimeVal), select(getSelectedQuestionState)),
    ]);
    states.subscribe(([questions, selectedQuestion]) => {
      if (!questions.loading && questions.counters.all.total >= 0) {
        this.questions = questions.previews;
        if (!this.filteredQuestions) {
          this.filteredQuestions = questions.previews.slice(0, this.defaultMatPaginatorValue);
        }
        this.questionsLength = questions.counters.all.total;
        this.lastPaginationIndexLoaded = questions.previewsIndexLoaded;
        if (this.maxIndex && this.maxIndex < questions.previewsMaxIndex) {
          this.store.dispatch(loadNewQuestionsPage());
          this.maxIndex = questions.previewsMaxIndex;
        } else {
          this.maxIndex = questions.previewsMaxIndex;
        }
        if (this.paginator) {
          this.changePageFromDashboard({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize }, false);
        }
      }
      this.isLoading = questions.loading || selectedQuestion.loading;
    });
    this.store.pipe(takeUntil(this.$destroy), select(getQuestionsCountersState)).subscribe((questionCounters) => {
      if (questionCounters) {
        this.statusCounters = questionCounters;
        this.changeStatusCounters('', true);
      }
    });
  }

  changeStatusCounters (status: string, fromSocket: boolean) {
    const filtered = this.statusValues.filter(s => s.value === status);
    if (filtered) {
      const counters: QuestionCountData = this.statusCounters[status];
      if (counters) {
        this.selectedStatus = <Status | ''>status;
        this.selectedStatusCount = counters.total;
      } else {
        this.selectedStatus = '';
        this.selectedStatusCount = this.statusCounters.all.total;
      }
      if (this.paginator) {
        const currentMinIndex = this.paginator.pageSize * this.paginator.pageIndex + 1;
        let pageIndex = this.paginator.pageIndex;
        if (currentMinIndex > this.selectedStatusCount) {
          pageIndex = Math.floor((this.selectedStatusCount - 1) / this.paginator.pageSize);
        }
        if (!fromSocket) {
          this.forcePaginatorPageChange(pageIndex);
          this.changePageFromDashboard({ pageIndex, pageSize: this.paginator.pageSize }, true);
        }
      }
    }
  }

  getStatusIcon (status: Status): string {
    switch (status) {
      case 'created':
        return 'fiber_new';
      case 'published':
        return 'cloud_done';
      case 'unpublished':
        return 'cloud_off';
      default:
        return 'priority_high';
    }
  }

  getStatusTitle (status: Status): string {
    switch (status) {
      case 'created':
        return 'Creado';
      case 'published':
        return 'Publicado';
      case 'unpublished':
        return 'Dado de baja';
      default:
        return 'Status desconocido';
    }
  }

  getStatusClass (status: Status): string {
    switch (status) {
      case 'created':
        return 'color-primary';
      case 'published':
        return 'color-success';
      case 'unpublished':
        return 'color-danger';
      default:
        return '';
    }
  }

  openQuestionForm (ques?: QuestionPreview) {
    if (ques) {
      if (this.userPermits.canEditQuestion(ques) && !ques.loading) {
        const route = `questions/edit/${this.helper.transformReferenceToId(ques.reference)}`;
        return this.helper.navigateTo(route);
      }
    } else {
      if (this.userPermits.canCreateQuestions) {
        return this.helper.navigateTo('questions/create');
      }
    }
  }

  openQuestionView (ques: QuestionPreview) {
    if (this.userPermits.canViewQuestion(ques) && !ques.loading) {
      const route = `questions/view/${this.helper.transformReferenceToId(ques.reference)}`;
      return this.helper.navigateTo(route);
    }
  }

  approveAndPublishQuestion (ques: QuestionPreview) {
    if (this.userPermits.canPublishQuestion(ques) && !ques.loading) {
      Swal.fire(
        {
          type: 'info',
          title: '¿Estás seguro de publicar esta pregunta?',
          text: `Al publicar la pregunta podrá ser asignada a nuevos exámenes.`,
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Publicar',
        },
      ).then((result) => {
        if (result.value) {
          this.store.dispatch(publishQuestion({ question: ques }));
        }
      });
    }
  }

  rejectAndDeactivate (ques: QuestionPreview) {
    if (this.userPermits.canPublishQuestion(ques) && !ques.loading) {
      Swal.fire(
        {
          type: 'info',
          title: '¿Estás seguro de desactivar esta pregunta?',
          text: `Al desactivar la pregunta no podrá ser asignada a nuevos exámenes.`,
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Desactivar',
        },
      ).then((result) => {
        if (result.value) {
          this.store.dispatch(deactivateQuestion({ question: ques }));
        }
      });
    }
  }

  deactivateQuestion (ques: QuestionPreview) {
    if (this.userPermits.canUnPublishQuestion(ques) && !ques.loading) {
      Swal.fire(
        {
          type: 'info',
          title: '¿Estás seguro de desactivar esta pregunta?',
          text: `Al desactivar la pregunta ya no será mostrada en los exámenes a los cuales pertenece.`,
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Desactivar',
        },
      ).then((result) => {
        if (result.value) {
          this.store.dispatch(deactivateQuestion({ question: ques }));
        }
      });
    }
  }

  reactivateQuestion (ques: QuestionPreview) {
    if (this.userPermits.canRePublishQuestion(ques) && !ques.loading) {
      Swal.fire(
        {
          type: 'info',
          title: '¿Estás seguro de reactivar esta pregunta?',
          text: `Al reactivar la pregunta los exámenes que la contienen volverán a mostrarla y podrá ser asignada a nuevos exámenes.`,
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Reactivar',
        },
      ).then((result) => {
        if (result.value) {
          this.store.dispatch(reactivateQuestion({ question: ques }));
        }
      });
    }
  }

  deleteQuestion (ques: QuestionPreview) {
    if (this.userPermits.canDeleteQuestion && !ques.loading) {
      Swal.fire(
        {
          type: 'warning',
          title: '¿Estás seguro de eliminar esta pregunta?',
          text: `Al eliminar la pregunta no se mostrará en los exámenes que está asignada y se removerá del editor de preguntas.`,
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Eliminar',
        },
      ).then((result) => {
        if (result.value) {
          this.store.dispatch(deleteQuestion({ question: ques }));
        }
      });
    }
  }

  setInitialPaginationValues () {
    const time = 500;
    if (!this.loadedPaginator) {
      this.loadedPaginator = true;
      this.store.pipe(select(getQuestionsState), auditTime(time), take(1)).subscribe((questionsState) => {
        if (!isNullOrUndefined(questionsState.pagination.statusFilter)) {
          this.filterForm.get('status').setValue(questionsState.pagination.statusFilter);
        } else {
          this.store.dispatch(changeQuestionsPaginationValue(
            { status: this.selectedStatus, newPagValue: this.defaultMatPaginatorValue, newIndex: 0 },
          ));
        }
        const paginator = this.paginator;
        this.pageLoading = true;
        paginator._changePageSize(questionsState.pagination.pageValue);
        paginator.pageIndex = questionsState.pagination.activeIndex;
        this.pageLoading = false;
      });
    }
  }

  changePageFromDashboard (event, refresh: boolean) {
    if (this.loadedPaginator && !this.isLoading && !this.pageLoading) {
      this.pageLoading = true;
      const firstIndexOfQuesRequired = event.pageSize * (event.pageIndex);
      const lastIndexOfQuesRequired = event.pageSize * (event.pageIndex + 1) - 1;
      let selectedData = this.questions.filter(q => q.status !== 'deleted');
      if (this.selectedStatus !== '') {
        selectedData = selectedData.filter(q => q.status === this.selectedStatus);
      }
      this.filteredQuestions = selectedData.slice(firstIndexOfQuesRequired, lastIndexOfQuesRequired + 1);
      if (refresh) {
        this.store.dispatch(changeQuestionsPaginationValue(
          { status: this.selectedStatus, newPagValue: event.pageSize, newIndex: event.pageIndex },
        ));
      }
      this.pageLoading = false;
    }
  }

  forcePaginatorPageChange (index: number) {
    if (this.paginator) {
      this.paginator.pageIndex = index;
    }
  }

  ngOnDestroy (): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
