import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TopicObject } from '../../core/ngrx/topics/topics.interfaces';

@Injectable({
  providedIn: 'root',
})
export class TopicService {

  constructor (private fireStoreDB: AngularFirestore) {}

  getTopics (): Observable<TopicObject> {
    return this.fireStoreDB.doc(`${environment.projectId}/processedToRead/topics`).snapshotChanges().pipe(map(
      (a: any) => {
        const topicsObj = a.payload.data();
        return topicsObj ? topicsObj : {};
      }),
    );
  }

  saveTopics (topics: TopicObject): Promise<void> {
    return this.fireStoreDB.doc(`${environment.projectId}/processedToRead/topics`).set(topics);
  }

  static getTopicObjectNextIndex (topicObject: TopicObject): number {
    let index = 0;
    Object.keys(topicObject).forEach((topic) => {
      const topicIndex = parseInt(topic.substr(1), 10);
      if (topicIndex > index) {
        index = topicIndex;
      }
    });
    return index + 1;
  }

}
