import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  cancelledRequest,
  loadFlashcards,
  loadFlashcardsCount,
  loadFlashcardsCountFail,
  loadFlashcardsCountSuccess,
  loadFlashcardsFail,
  loadFlashcardsSuccess, loadNewFlashcardsPage, loadNewFlashcardsPageFail, loadNewFlashcardsPageSuccess,
  openFlashcardForm,
  openFlashcardFormFail,
  openFlashcardFormSuccess,
  saveFlashcard,
  saveFlashcardFail,
  saveFlashcardSuccess,
} from './flashcards.actions';
import { catchError, defaultIfEmpty, map, mergeMap, take, tap } from 'rxjs/operators';
import { FlashcardService } from '../../../services/Flashcard/flashcard.service';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { showAlert } from '../alerts/alerts.actions';
import { firestore } from 'firebase/app';
import { Store } from '@ngrx/store';
import { getAuthState } from '../authentication/authentication.selectors';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material';
import { FlashcardEditionFormComponent } from '../../../components/flashcard-components/flashcard-form/flashcard-form.component';
import { Flashcard } from './flashcards.interfaces';
import { ImageService } from '../../../services/Image/image.service';
import { environment } from '../../../../environments/environment';
import { getFlashcardsState } from './flashcards.selectors';
import { HelperService } from '../../../services/Helper/helper.service';
import * as _cloneDeep from 'lodash/cloneDeep';
import UploadTaskSnapshot = firebase.storage.UploadTaskSnapshot;
import { ImageRefs } from '../dataTypes/dataTypes.interfaces';

@Injectable() export class FlashcardsEffects {

  constructor (
    private store: Store<any>,
    private actions$: Actions,
    private imageService: ImageService,
    private helperService: HelperService,
    private flashcardService: FlashcardService,
    private fireStoreDB: AngularFirestore,
    private dialog: MatDialog,
  ) {}

  loadFlashcards$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadFlashcards),
      mergeMap((action) => {
        const observables = combineLatest(
          [
            this.store.select(getAuthState).pipe(take(1)),
            this.store.select(getFlashcardsState).pipe(take(1)),
          ],
        );
        return observables.pipe(mergeMap(([authState, flashcardState]) => {
          const index = action.paginateVal;
          let indexCorrected = index;
          if (authState.user.newToOld) {
            indexCorrected = flashcardState.previewsMaxIndex;
            for (let i = 1; i <= index; i += 1) {
              indexCorrected -= 1;
            }
          }
          return this.flashcardService.getFlashcards(indexCorrected).pipe(
            map((previews) => {
              const flashcardsData = previews.map((p) => {
                if (p.explanationReference instanceof firestore.DocumentReference) {
                  p.explanationReference = p.explanationReference.path;
                }
                return p;
              });
              if (authState.user.newToOld) {
                flashcardsData.reverse();
              }
              return loadFlashcardsSuccess({ index, flashcardsData });
            }),
            catchError((error) => {
              return this.store.select(getAuthState).pipe(take(1), map((authState) => {
                if (authState && authState.user) {
                  return loadFlashcardsFail({ error });
                }
                return cancelledRequest();
              }));
            }),
          );
        }));
      }),
    ),
    { resubscribeOnError: false },
  );

  loadNewFlashcardsPage$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadNewFlashcardsPage),
      mergeMap(() => {
        return this.store.select(getFlashcardsState).pipe(take(1)).pipe(mergeMap((flashcardState) => {
          const index = flashcardState.previewsMaxIndex;
          return this.flashcardService.getFlashcards(index).pipe(
            map((previews) => {
              const flashcardsData = previews;
              flashcardsData.reverse();
              return loadNewFlashcardsPageSuccess({ flashcardsData, index });
            }),
            catchError((error) => {
              return this.store.select(getAuthState).pipe(take(1), map((authState) => {
                if (authState && authState.user) {
                  return loadNewFlashcardsPageFail({ error });
                }
                return cancelledRequest();
              }));
            }),
          );
        }));
      }),
    ),
    { resubscribeOnError: false },
  );

  loadFlashcardsCount$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadFlashcardsCount),
      mergeMap(() => {
        return this.flashcardService.getFlashcardsSize().pipe(
          map((flashcardsTotalLength) => {
            const paginationVal = environment.bigPaginateVal;
            let flashcardsMaxIndex = Math.floor((flashcardsTotalLength - 1) / paginationVal);
            flashcardsMaxIndex = flashcardsMaxIndex > 0 ? flashcardsMaxIndex : 0;
            return loadFlashcardsCountSuccess({ flashcardsTotalLength, flashcardsMaxIndex });
          }),
          catchError((error) => {
            return this.store.select(getAuthState).pipe(take(1), map((authState) => {
              if (authState && authState.user) {
                return loadFlashcardsFail({ error });
              }
              return cancelledRequest();
            }));
          }),
        );
      }),
    ),
    { resubscribeOnError: false },
  );

  openFlashcardForm$ = createEffect(() => this.actions$.pipe(
    ofType(openFlashcardForm),
    mergeMap((action) => {
      if (action.preview === undefined) {
        const dialogRef = this.dialog.open(FlashcardEditionFormComponent, {
          width: '1110px',
          disableClose: true,
        });
        dialogRef.beforeClosed().subscribe(() => {
          this.helperService.navigateBack();
        });
        return of(openFlashcardFormSuccess({ flashcard: undefined }));
      }
      const reference = typeof action.preview === 'string'
        ? `${environment.projectId}/flashcards/${action.preview}`
        : action.preview.id
      ;
      return this.flashcardService.getFlashcard(reference).pipe(
        take(1),
        map((flashcard: Flashcard) => {
          const flashcardToEdit: Flashcard = _cloneDeep(flashcard);
          flashcardToEdit.id = this.helperService.transformReferenceToId(reference);
          const dialogRef = this.dialog.open(FlashcardEditionFormComponent, {
            width: '1110px',
            disableClose: true,
          });
          dialogRef.beforeClosed().subscribe(() => {
            this.helperService.navigateBack();
          });
          return openFlashcardFormSuccess({ flashcard: flashcardToEdit });
        }),
        catchError((error) => {
          return of(openFlashcardFormFail({ error }));
        }),
      );
    }),
  ));

  saveFlashcard$ = createEffect(() => this.actions$.pipe(
    ofType(saveFlashcard),
    mergeMap((action) => {
      const flashToSave: Flashcard = _cloneDeep(action.flashcard);
      const uploadedImageNames: string[] = action.flashcard.images;
      const maxThumbnails = 3;
      const imagesLength = action.previews.length + uploadedImageNames.length - action.imagesToDelete.length;
      const previewsObservable: Observable<UploadTaskSnapshot>[] = action.previews.map((img: ImageRefs) => {
        return this.compressAndUploadImage(img, 'image');
      });
      let thumbnailObservable: Observable<Observable<UploadTaskSnapshot>[]> = of([]);
      let thumbnailsRefresh = false;
      let thumbnailsToDelete: string[] = [];
      if (action.previews.length > 0 && uploadedImageNames.length < maxThumbnails) {
        thumbnailsRefresh = true;
        thumbnailsToDelete = _cloneDeep(flashToSave.thumbnails);
        thumbnailObservable = this.regenerateThumbnails(uploadedImageNames, action.previews, imagesLength);
      }
      return thumbnailObservable.pipe(
        mergeMap((thumbnails: Observable<UploadTaskSnapshot>[]) => {
          return forkJoin([...previewsObservable, ...thumbnails]).pipe(
            defaultIfEmpty([]),
            mergeMap((data: UploadTaskSnapshot[]) => {
              const uploadedImages: UploadTaskSnapshot[] = _cloneDeep(data);
              const thumbnailsLength = imagesLength > maxThumbnails ? maxThumbnails : imagesLength;
              const thumbnails = uploadedImages.splice(uploadedImages.length - thumbnailsLength, uploadedImages.length - 1);
              flashToSave.images = [...uploadedImageNames, ...uploadedImages.map(img => img.metadata.name)];
              flashToSave.thumbnails = thumbnailsRefresh ? thumbnails.map(thumb => thumb.metadata.name) : flashToSave.thumbnails;
              return this.flashcardService.saveFlashcard(flashToSave).pipe(
                map(() => {
                  return saveFlashcardSuccess({ id: flashToSave.id });
                }),
                catchError((error) => {
                  return of(saveFlashcardFail({ error }));
                }),
              );
            }),
            tap(() => {
              if (thumbnailsToDelete.length > 0) {
                thumbnailsToDelete.forEach((thumbnail) => {
                  this.imageService.deleteImage(thumbnail, 'flashcards/thumbnails');
                });
              }
              if (action.imagesToDelete.length > 0) {
                action.imagesToDelete.forEach((image) => {
                  this.imageService.deleteImage(image.name, 'flashcards');
                });
              }
            }),
          );
        }),
      );
    }),
  ));

  flashcardSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(saveFlashcardSuccess),
    mergeMap((action) => {
      return of(showAlert(
        {
          toast: true,
          position: 'bottom-end',
          showConfirm: false,
          showCancel: false,
          timer: 10000,
          alertType: 'success',
          title: `Flashcard con ID ${action.id} correctamente guardada.`,
        },
      ));
    }),
  ));

  flashcardsErrors$ = createEffect(() => this.actions$.pipe(
    ofType(loadFlashcardsFail, loadNewFlashcardsPageFail, loadFlashcardsCountFail, openFlashcardFormFail, saveFlashcardFail),
    mergeMap((action) => {
      return of(showAlert({ alertType: 'error', error: action.error, title: 'Oops...' }));
    }),
  ));

  private regenerateThumbnails (imageNames: string[], previews: ImageRefs[], amount: number): Observable<Observable<UploadTaskSnapshot>[]> {
    const maxThumbnailAmount = 3;
    const thumbnailsAmount = amount > maxThumbnailAmount ? maxThumbnailAmount : amount;
    const combinedImages: ImageRefs[] = [
      ...imageNames.map(name => ({ name, URL: '', loading: false })),
      ...previews,
    ].slice(0, thumbnailsAmount);
    const observables: Observable<UploadTaskSnapshot>[] = combinedImages.map((img) => {
      return of(img).pipe(
        mergeMap((image) => {
          if (image.URL === '') {
            return this.imageService.getImageRef(image.name, 'flashcards').pipe(
              mergeMap((ref) => {
                return from(new Promise<Blob>((res) => {
                  const xhr = new XMLHttpRequest();
                  xhr.responseType = 'blob';
                  xhr.onload = function () {
                    res(xhr.response);
                  };
                  xhr.open('GET', ref);
                  xhr.send();
                }));
              }),
              mergeMap((blob: Blob) => {
                return from(new Promise<string | ArrayBuffer>((res) => {
                  const a = new FileReader();
                  a.onloadend = function () {
                    res(a.result);
                  };
                  a.readAsDataURL(blob);
                }));
              }),
              mergeMap((data: string) => {
                return of(<ImageRefs>{ ...image, previewData: data });
              }),
            );
          }
          return of(image);
        }),
        mergeMap((image: ImageRefs) => {
          return this.compressAndUploadImage(image, 'thumbnail', amount);
        }),
      );
    });
    return of(observables);
  }

  private compressAndUploadImage (data: ImageRefs, type: 'image' | 'thumbnail', imageLength?: number): Observable<UploadTaskSnapshot> {
    return of(data.previewData).pipe(
      mergeMap((image: string) => {
        return from(new Promise((resolve, reject) => {
          const imageInstance = new Image();
          imageInstance.src = image;
          imageInstance.onload = () => resolve(imageInstance);
          imageInstance.onerror = () => reject('Error while loading image');
        }));
      }),
      mergeMap((image: HTMLImageElement) => {
        const orientation = image.width > image.height ? -1 : 1;
        let requiredImageWidth = 1500;
        let requiredImageQuality = 85;
        if (type === 'thumbnail') {
          // tslint:disable-next-line:no-magic-numbers
          requiredImageWidth = imageLength === 1 ? 700 : 400;
          // tslint:disable-next-line:no-magic-numbers
          requiredImageQuality = imageLength === 1 ? 60 : 70;
        }
        return this.compress(image.src, orientation, image.width, requiredImageWidth, requiredImageQuality);
      }),
      mergeMap((compressedImage: string) => {
        if (type === 'thumbnail') {
          return from(this.imageService.uploadImage(compressedImage, 'flashcards/thumbnails'));
        }
        return from(this.imageService.uploadImage(compressedImage, 'flashcards'));
      }),
    );
  }

  private compress (fileInput: string, orientation: number, width: number, requiredWidth: number, quality: number): Observable<string> {
    const ratio = requiredWidth / width * 100;
    return this.imageService.compressImage(fileInput, orientation, ratio, quality);
  }

}
