import { Timestamp } from '@firebase/firestore-types';
import { DocumentReference } from '@angular/fire/firestore';
import { dataType, PaginationValue } from '../dataTypes/dataTypes.interfaces';

export interface QuestionState {
  selectedQuestion: SelectedQuestionState;
  questions: QuestionsState;
}

export interface QuestionsState {
  pagination: PaginationValue;
  previews: QuestionPreview[];
  previewsPages: QuestionPreview[][];
  counters: QuestionCount;
  previewsIndexLoaded: number;
  previewsMaxIndex: number;
  loading: boolean;
  error?: any;
}

export interface SelectedQuestionState {
  question: Question;
  uploadingImages: boolean;
  loading: boolean;
  error?: any;
}

export type Status = 'created' | 'published' | 'unpublished' | 'deleted';
export type LanguageCodes = 'es' | 'en';
export const statusArray = [
  { value: 'created', text: 'Creada' },
  { value: 'published', text: 'Publicada' },
  { value: 'unpublished', text: 'Desactivada' },
];
export const allStatusArray = [
  { value: 'created', text: 'Creada' },
  { value: 'published', text: 'Publicada' },
  { value: 'unpublished', text: 'Desactivada' },
  { value: 'deleted', text: 'Eliminada' },
];
export const languages = [
  { code: 'es', name: 'Español' },
  { code: 'en', name: 'Inglés' },
];

export interface Question {
  id: string;
  answer: string;
  audit: Audit;
  data: string[];
  descriptionReference?: DocumentReference | string;
  descriptionPreviewIndex?: number;
  explanationReference?: DocumentReference | string;
  explanationPreviewIndex?: number;
  index: number;
  language: LanguageCodes;
  options: Options;
  status: Status;
  subtopicID: string;
  title: string;
  topicID: string;
  type: dataType[];
  year?: number;
  created: Timestamp;
  lastUpdatedBy: string;
  lastUpdated: Timestamp;
}

export interface Audit {
  created?: Timestamp;
  createdBy?: string;
  published?: Timestamp;
  publishedBy?: string;
  unpublished?: Timestamp;
  unpublishedBy?: string;
  deleted?: Timestamp;
  deletedBy?: string;
}

export interface QuestionCount {
  all: QuestionCountData;
  allWithDeleted?: QuestionCountData;
  created: QuestionCountData;
  published: QuestionCountData;
  unpublished: QuestionCountData;
  deleted: QuestionCountData;
}

export interface QuestionCountData {
  pagination: number[];
  total: number;
}

export interface QuestionDocPreview {
  data: QuestionPreview[];
}

export interface QuestionPreview {
  title: string;
  created: Timestamp;
  updated: Timestamp;
  lastUpdatedBy: string;
  status: Status;
  reference: DocumentReference | string;
  loading?: boolean;
  hasExplanation: boolean;
  hasDescription: boolean;
}

export interface Options {
  a: Option;
  b: Option;
  c?: Option;
  d?: Option;
  e?: Option;
}

export interface Option {
  data: string[];
  type: dataType[];
}
