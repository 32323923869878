import { Explanation, ExplanationsState, ExplanationState, SelectedExplanationState } from './explanations.interfaces';
import { Action, ActionReducerMap, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import {
  changeExplanationsPaginationValue,
  loadExplanations,
  loadExplanationsCount,
  loadExplanationsCountFail,
  loadExplanationsCountSuccess,
  loadExplanationsFail,
  loadExplanationsSuccess, loadExplanationsWarn, loadNewExplanationsPage, loadNewExplanationsPageFail, loadNewExplanationsPageSuccess,
  openExplanationForm,
  openExplanationFormFail,
  openExplanationFormSuccess, resetSelectedExplanation,
  saveExplanation,
  saveExplanationFail,
  saveExplanationSuccess, saveExplanationWithImages, saveExplanationWithImagesFail, saveExplanationWithImagesSuccess,
} from './explanations.actions';
import { environment } from '../../../../environments/environment';
import { PaginationValue } from '../dataTypes/dataTypes.interfaces';
import { isNullOrUndefined } from 'util';

const explanationsInitialState: ExplanationsState = {
  pagination: { activeIndex: 0, pageValue: environment.defaultPaginatorValue },
  previews: [],
  previewsPages: [],
  previewsTotalCount: 0,
  previewsIndexLoaded: -1,
  previewsMaxIndex: -1,
  loading: false,
};

const explanationInitialState: SelectedExplanationState = {
  explanation: undefined,
  uploadingImages: false,
  loading: false,
};

const explanationsLocalReducer = createReducer(
  explanationsInitialState,
  on(changeExplanationsPaginationValue, (state: ExplanationsState, { newIndex, newPagValue }) => {
    const newPaginationVal: PaginationValue = { ...state.pagination };
    if (!isNullOrUndefined(newIndex)) {
      newPaginationVal.activeIndex = newIndex;
    }
    if (!isNullOrUndefined(newPagValue)) {
      newPaginationVal.pageValue = newPagValue;
    }
    const newState: ExplanationsState = {
      ...state,
      pagination: newPaginationVal,
    };
    return newState;
  }),
  on(loadExplanations, (state: ExplanationsState) => {
    const newState: ExplanationsState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(loadExplanationsSuccess, (state: ExplanationsState, { explanationPreviews, index }) => {
    const newState: ExplanationsState = {
      ...state,
      loading: false,
      error: undefined,
    };
    if (index > state.previewsIndexLoaded) {
      newState.previewsPages = state.previewsPages.concat([explanationPreviews]);
      newState.previewsIndexLoaded = index;
    } else {
      newState.previewsPages = state.previewsPages.map((p, i) => {
        if (i === index) {
          return explanationPreviews;
        }
        return p;
      });
    }
    newState.previews = _.flattenDepth(newState.previewsPages, 1);
    return newState;
  }),
  on(loadExplanationsWarn, (state: ExplanationsState) => {
    const newState: ExplanationsState = {
      ...state,
      loading: false,
    };
    return newState;
  }),
  on(loadExplanationsFail, (state: ExplanationsState, { error }) => {
    const newState: ExplanationsState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
  on(loadNewExplanationsPage, (state: ExplanationsState) => {
    const newState: ExplanationsState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(loadNewExplanationsPageSuccess, (state: ExplanationsState, { explanationPreviews }) => {
    const newState: ExplanationsState = {
      ...state,
      loading: false,
      error: undefined,
    };
    newState.previewsPages = [explanationPreviews].concat(state.previewsPages);
    newState.previewsIndexLoaded = state.previewsIndexLoaded + 1;
    newState.previews = _.flattenDepth(newState.previewsPages, 1);
    return newState;
  }),
  on(loadNewExplanationsPageFail, (state: ExplanationsState, { error }) => {
    const newState: ExplanationsState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
  on(loadExplanationsCount, (state: ExplanationsState) => {
    const newState: ExplanationsState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(loadExplanationsCountSuccess, (state: ExplanationsState, { explanationsTotalLength, explanationsMaxIndex }) => {
    const newState: ExplanationsState = {
      ...state,
      previewsTotalCount: explanationsTotalLength,
      previewsMaxIndex: explanationsMaxIndex,
      loading: false,
      error: undefined,
    };
    return newState;
  }),
  on(loadExplanationsCountFail, (state: ExplanationsState, { error }) => {
    const newState: ExplanationsState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
);

const explanationLocalReducer = createReducer(
  explanationInitialState,
  on(resetSelectedExplanation, (state: SelectedExplanationState) => {
    const newState: SelectedExplanationState = {
      ...state,
      explanation: undefined,
      loading: false,
      error: undefined,
    };
    return newState;
  }),
  on(openExplanationForm, (state: SelectedExplanationState) => {
    const newState: SelectedExplanationState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(openExplanationFormSuccess, (state: SelectedExplanationState, { explanation }) => {
    const newState: SelectedExplanationState = {
      ...state,
      explanation,
      loading: false,
      error: undefined,
    };
    return newState;
  }),
  on(openExplanationFormFail, (state: SelectedExplanationState, { error }) => {
    const newState: SelectedExplanationState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
  on(saveExplanation, (state: SelectedExplanationState, { explanation }) => {
    const newState: SelectedExplanationState = {
      ...state,
      explanation,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(saveExplanationSuccess, (state: SelectedExplanationState, { id }) => {
    const explanation: Explanation = {
      ...state.explanation,
      id,
    };
    const newState: SelectedExplanationState = {
      ...state,
      explanation,
      loading: false,
      error: undefined,
    };
    return newState;
  }),
  on(saveExplanationFail, (state: SelectedExplanationState, { error }) => {
    const newState: SelectedExplanationState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
  on(saveExplanationWithImages, (state: SelectedExplanationState, { explanation }) => {
    const newState: SelectedExplanationState = {
      ...state,
      explanation,
      uploadingImages: true,
      error: undefined,
    };
    return newState;
  }),
  on(saveExplanationWithImagesSuccess, (state: SelectedExplanationState) => {
    const newState: SelectedExplanationState = {
      ...state,
      uploadingImages: false,
      error: undefined,
    };
    return newState;
  }),
  on(saveExplanationWithImagesFail, (state: SelectedExplanationState, { error }) => {
    const newState: SelectedExplanationState = {
      ...state,
      error,
      uploadingImages: false,
    };
    return newState;
  }),
);

export function explanationsReducer (state: ExplanationsState | undefined, action: Action) {
  return explanationsLocalReducer(state, action);
}

export function selectedExplanationReducer (state: SelectedExplanationState | undefined, action: Action) {
  return explanationLocalReducer(state, action);
}

export const explanationReducer: ActionReducerMap<ExplanationState> = {
  selectedExplanation: selectedExplanationReducer,
  explanations: explanationsReducer,
};
