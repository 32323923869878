<ng-container [formGroup]="controlContainer.control">
  <div class="stepperButtons middle">
    <button type="button" mat-raised-button color="accent" (click)="addFlashcardElement()">Añadir flashcard</button>
  </div>
  <div class="imageInput" formArrayName="flashcardsArray" *ngFor="let option of getFlashcardsArray.controls; let i = index;">
    <div [formGroupName]="i" class="columns is-centered">
      <mat-form-field class="column is-6">
        <input matInput placeholder="ID de la flashcard" formControlName="id">
        <mat-error *ngIf="option.get('id').invalid && option.get('id').errors['required']">
          Este campo es obligatorio, es necesario ingresar un valor.
        </mat-error>
        <mat-error *ngIf="option.get('id').invalid && option.get('id').errors['pattern']">
          Formato de ID incorrecto, verifique que no haya espacios.
        </mat-error>
      </mat-form-field>
      <div class="column is-1">
        <button mat-mini-fab color="warn" (click)="removeFlashcardElement(i)">
          <mat-icon aria-label="Remove reference">delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>
