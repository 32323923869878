import { Component } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'em-flashcard-form',
  templateUrl: './flashcard-form.component.html',
  styleUrls: ['./flashcard-form.component.scss'],
})
export class FlashcardFormComponent {

  constructor (public controlContainer: ControlContainer, private formBuilder: FormBuilder) {}

  public get getFlashcardsArray (): FormArray {
    return <FormArray>this.controlContainer.control.get('flashcardsArray');
  }

  addFlashcardElement (id?: string) {
    const localId = id ? id : '';
    const arr = <FormArray>this.controlContainer.control.get('flashcardsArray');
    const newGroup = this.formBuilder.group(
      {
        id: [localId, [Validators.required, Validators.pattern(/^\w+$/)]],
      },
    );
    arr.push(newGroup);
  }

  removeFlashcardElement (index: number) {
    const arr = <FormArray>this.controlContainer.control.get('flashcardsArray');
    arr.removeAt(index);
  }

}
