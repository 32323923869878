import { createAction, props } from '@ngrx/store';
import { SweetAlertType } from 'sweetalert2';
import { dialogPositions } from './alerts.interfaces';

export const showAlert = createAction(
  '[Alerts] Show alert',
  props<{
    alertType: SweetAlertType,
    title: string,
    error?: any,
    message?: string,
    position?: dialogPositions,
    showCancel?: boolean,
    html?: boolean,
    toast?: boolean,
    timer?: number,
    showConfirm?:boolean,
  }>(),
);

export const closeAlert = createAction(
  '[Alerts] Close alert',
);
