import { createAction, props } from '@ngrx/store';
import { EditorUser, EditorUserPreview } from '../editorUsers/editorUsers.interfaces';
import { AppPemits } from './authentication.interfaces';

export const loginAttempt = createAction(
  '[Authentication] Login attempt',
  props<{ user: string, password: string }>(),
);
export const loginAttemptSuccess = createAction(
  '[Authentication] Login attempt success',
  props<{ user: EditorUser, appPermits: AppPemits }>(),
);
export const loginAttemptFail = createAction(
  '[Authentication] Login attempt failure',
  props<{ error: any }>(),
);

export const openEditUserForm = createAction(
  '[Authentication] Open edit user form',
);
export const openEditUserFormSuccess = createAction(
  '[Authentication] Open edit user form success',
);
export const openEditUserFormFail = createAction(
  '[Authentication] Open edit user form failure',
  props<{ error: any }>(),
);

export const saveUser = createAction(
  '[Authentication] Save user',
  props<{ user: EditorUser }>(),
);
export const saveUserSuccess = createAction(
  '[Authentication] Save user success',
);
export const saveUserFail = createAction(
  '[Authentication] Save user failure',
  props<{ error: any }>(),
);

export const checkUserStatus = createAction(
  '[Authentication] Check user status',
);
export const checkUserStatusSuccess = createAction(
  '[Authentication] Check user status success',
  props<{ user: EditorUser | undefined, appPermits: AppPemits | undefined }>(),
);

export const logoutAttempt = createAction(
  '[Authentication] Logout attempt',
);
export const logoutAttemptSuccess = createAction(
  '[Authentication] Logout attempt success',
);
export const logoutAttemptFail = createAction(
  '[Authentication] Logout attempt failure',
  props<{ error: any }>(),
);
