import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/Auth/auth.service';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' }) export class QuestionCreationGuard implements CanActivate {
  constructor (
    private auth: AuthService,
    private router: Router,
  ) {}
  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.getUser().pipe(take(1), map((user) => {
      return this.auth.canCreateQuestions(user) || this.router.parseUrl('/notAllowed');
    }));
  }
}

@Injectable({ providedIn: 'root' }) export class DescriptionCreationGuard implements CanActivate {
  constructor (
    private auth: AuthService,
    private router: Router,
  ) {}
  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.getUser().pipe(take(1), map((user) => {
      return this.auth.canCreateQuestions(user) || this.router.parseUrl('/notAllowed');
    }));
  }
}

@Injectable({ providedIn: 'root' }) export class ExplanationCreationGuard implements CanActivate {
  constructor (
    private auth: AuthService,
    private router: Router,
  ) {}
  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.getUser().pipe(take(1), map((user) => {
      return this.auth.canCreateQuestions(user) || this.router.parseUrl('/notAllowed');
    }));
  }
}

@Injectable({ providedIn: 'root' }) export class FlashcardCreationGuard implements CanActivate {
  constructor (
    private auth: AuthService,
    private router: Router,
  ) {}
  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.getUser().pipe(take(1), map((user) => {
      return this.auth.canCreateQuestions(user) || this.router.parseUrl('/notAllowed');
    }));
  }
}
