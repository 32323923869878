<div mat-dialog-title class="columns is-gapless">
  <div class="column is-1" *ngIf="isAnEditForm">
    <button mat-icon-button [matTooltip]="'Copiar ID de la pregunta seleccionada'"
            matTooltipPosition="above" (click)="copyIDToClipboard(question.id)">
      <mat-icon color="primary">bookmarks</mat-icon>
    </button>
    <button mat-icon-button [matTooltip]="'Copiar URL de la pregunta seleccionada'"
            matTooltipPosition="above" (click)="copyURLToClipboard(question.id)">
      <mat-icon color="primary">link</mat-icon>
    </button>
  </div>
  <div class="column" [ngClass]="isAnEditForm ? 'is-9' : 'is-10'">
    <h1 [matTooltip]="question.title" matTooltipPosition="below">
      {{ isAnEditForm ? 'Editar pregunta: ' + question.title : 'Crear pregunta' }}
    </h1>
  </div>
  <div class="columns is-2" *ngIf="statusSelectorEnabled && isAnEditForm">
    <mat-form-field style="width: 100%;">
      <mat-label>Status</mat-label>
      <mat-select [(value)]="questionStatus">
        <mat-option *ngFor="let status of availableStatus" [value]="status.value">
          {{ status.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-content>
  <mat-spinner class="initialLoading" *ngIf="!formsAreSetUp()"></mat-spinner>
  <mat-horizontal-stepper [linear]="true" #stepper *ngIf="formsAreSetUp()">
    <mat-step [stepControl]="dataAndTypeForm">
      <ng-template matStepLabel>Contenido de la pregunta</ng-template>
      <div class="stepperButtons end">
        <button mat-raised-button color="accent" matStepperNext>Siguiente sección</button>
      </div>
      <div class="columns">
        <form class="column" [formGroup]="dataAndTypeForm" autocomplete="off">
          <mat-form-field class="doubleFieldPadding">
            <input matInput placeholder="Título" formControlName="title">
            <mat-hint>El título se actualiza de forma automática con el primer párrafo,
              en caso de solo contar con imágenes es necesario ingresarlo manualmente.</mat-hint>
          </mat-form-field>
          <mat-form-field #textEntered>
            <textarea matInput placeholder="Texto" rows="4" formControlName="textEntered"></textarea>
            <mat-error *ngIf="dataAndTypeForm.get('textEntered').invalid && dataAndTypeForm.get('textEntered').errors?.empty">
              No se puede tener una pregunta vacía.
            </mat-error>
          </mat-form-field>
          <mat-divider></mat-divider>
          <div class="actionButtons" *ngIf="editing !== undefined">
            <button type="button" mat-raised-button (click)="updateText()" color="accent" [disabled]="loading">Actualizar texto</button>
            <button type="button" mat-raised-button (click)="cancelUpdateText()" color="accent" [disabled]="loading">Cancelar edición</button>
          </div>
          <div [ngClass]="{ 'actionButtons': editing === undefined }" [hidden]="editing !== undefined">
            <button type="button" mat-raised-button (click)="addText()" color="primary" [disabled]="loading">Agregar texto</button>
            <div class="imageInputs" *ngIf="dataAndTypeForm.get('images')">
              <div class="imageInput" formArrayName="images" *ngFor="let image of getImagesArray.controls; let i = index;">
                <div [formGroupName]="i">
                  <input [id]="'questionImage'+i" formControlName="image" type="file" mat-raised-button accept='image/jpeg, image/png'
                         (change)="addImage($event)" color="primary" placeholder="Agregar imagen">
                  <button type="button" mat-raised-button (click)="clickedAddImage(i)" color="primary" [disabled]="loading">Agregar imagen</button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="column preview">
          <h6 class="title is-6">Vista previa del texto e imágenes</h6>
          <mat-card *ngIf="imageLoading" >
            <mat-spinner></mat-spinner>
          </mat-card>
          <mat-card *ngIf="question" [ngClass]="{'is-hidden': imageLoading}" cdkDropList (cdkDropListDropped)="drop($event)">
            <p class="is-flex draggable" *ngFor="let contenido of question.data; let i = index" cdkDrag>
              <span *ngIf="question.type[i] === 'txt'" matLine>
                {{contenido}}
              </span>
              <figure *ngIf="question.type[i] === 'img' || question.type[i] === 'preview'" class="image is-responsive" matLine>
                <img [src]="getImageSrc(contenido, question.type[i])" (load)="loadedImage(contenido)">
              </figure>
              <span>
                <button *ngIf="question.type[i] === 'txt'" mat-icon-button color="primary" (click)="editItem(i)" [disabled]="loading">
                  <mat-icon aria-label="Editar">edit</mat-icon>
                </button>
                <button mat-icon-button color="warn" (click)="removeItem(i)" [disabled]="question.data.length === 1 || loading">
                  <mat-icon aria-label="Eliminar">delete</mat-icon>
                </button>
              </span>
            </p>
          </mat-card>
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="optionAndTopicForm">
      <ng-template matStepLabel>Materia, idioma y respuestas</ng-template>
      <div class="stepperButtons multiple">
        <button mat-raised-button color="accent" matStepperPrevious>Sección Anterior</button>
        <button mat-raised-button color="accent" matStepperNext>Siguiente sección</button>
      </div>
      <div class="columns">
        <form class="column" [formGroup]="optionAndTopicForm" autocomplete="off">
          <div class="columns">
            <div class="column">
              <h6 class="title is-6">Opciones mostradas como posibles respuestas (2 - 5).</h6>
              <div class="imageInput" formArrayName="options" *ngFor="let option of getOptionsArray.controls; let i = index;">
                <div [formGroupName]="i">
                  <mat-form-field>
                    <input matInput [placeholder]="'Opción \'' + ansArray[i] + '\''" formControlName="option">
                    <mat-error *ngIf="optionAndTopicForm.get('language').invalid && optionAndTopicForm.get('language').errors?.required">
                      Este campo es obligatorio, es necesario ingresar un valor.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="actionButtons">
                <button type="button" mat-raised-button (click)="addOption()" color="primary"
                        [disabled]="loading || getOptionsArray.length >= maxOptions">Agregar opción</button>
                <button type="button" mat-raised-button (click)="removeOption()" color="warn"
                        [disabled]="loading || getOptionsArray.length <= minOptions">Eliminar opción</button>
              </div>
            </div>
            <div class="column">
              <h6 class="title is-6">Opción marcada como correcta y datos de la pregunta.</h6>
              <mat-form-field>
                <mat-label>Respuesta correcta</mat-label>
                <mat-select formControlName="answer">
                  <mat-option *ngFor="let option of getOptionsArray.controls; let i = index" [value]="ansArray[i]">
                    Opción '{{ansArray[i]}}'
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="optionAndTopicForm.get('language').invalid && optionAndTopicForm.get('language').errors?.required">
                  Este campo es obligatorio, es necesario ingresar un valor.
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Idioma</mat-label>
                <mat-select formControlName="language">
                  <mat-option *ngFor="let lang of langs" [value]="lang.code">
                    {{lang.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="optionAndTopicForm.get('language').invalid && optionAndTopicForm.get('language').errors?.required">
                  Este campo es obligatorio, es necesario ingresar un valor.
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Materia</mat-label>
                <mat-select formControlName="topic">
                  <mat-option *ngFor="let topic of topics | keyvalue: sortTopics" [value]="topic.key" [disabled]="!topic.value.subtopics">
                    {{topic.value.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="optionAndTopicForm.get('language').invalid && optionAndTopicForm.get('language').errors?.required">
                  Este campo es obligatorio, es necesario ingresar un valor.
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Submateria</mat-label>
                <mat-select formControlName="subtopic" [disabled]="subtopicIsDisabled()">
                  <ng-container *ngIf="!subtopicIsDisabled()">
                    <mat-option *ngFor="let subtopic of topics[optionAndTopicForm.get('topic').value].subtopics | keyvalue: sortTopics" [value]="subtopic.key">
                      {{subtopic.value.name}}
                    </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error *ngIf="optionAndTopicForm.get('language').invalid && optionAndTopicForm.get('language').errors?.required">
                  Este campo es obligatorio, es necesario ingresar un valor.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </mat-step>
    <mat-step [optional]="true" [stepControl]="optionalsForm">
      <ng-template matStepLabel>Año, descripción, explicación</ng-template>
      <div class="stepperButtons start">
        <button mat-raised-button color="accent" matStepperPrevious>Sección Anterior</button>
      </div>
      <form [formGroup]="optionalsForm" autocomplete="off">
        <div class="columns">
          <mat-form-field class="column is-4">
            <input matInput placeholder="Año de la pregunta" formControlName="year">
            <mat-error *ngIf="optionalsForm.get('year').invalid && optionalsForm.get('year').errors?.year">
              El valor ingresado no es un año válido.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="column is-4">
            <mat-select formControlName="explanation" placeholder="Seleccionar explicación"
                        [disabled]="explanationsState.loading || createdExplanationLoading || searchingExplanation">
              <mat-option>
                <ngx-mat-select-search formControlName="explanationSearch" [placeholderLabel]="'Buscar explicación'"
                                       [noEntriesFoundLabel]="'Explicación no encontrada'"></ngx-mat-select-search>
              </mat-option>
              <mat-option value="">Sin explicación</mat-option>
              <mat-option *ngFor="let explanation of explanationFilteredPreviews"
                          [value]="explanation.reference && explanation.reference.path ? explanation.reference.path : explanation.reference">
                {{ explanation.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="column is-4">
            <mat-select formControlName="description" placeholder="Seleccionar descripción"
                        [disabled]="descriptionsState.loading || createdDescriptionLoading || searchingDescription">
              <mat-option>
                <ngx-mat-select-search formControlName="descriptionSearch" [placeholderLabel]="'Buscar descripción'"
                                       [noEntriesFoundLabel]="'Descripción no encontrada'"></ngx-mat-select-search>
              </mat-option>
              <mat-option value="">Sin descripción</mat-option>
              <mat-option *ngFor="let description of descriptionFilteredPreviews"
                          [value]="description.reference && description.reference.path ? description.reference.path : description.reference">
                {{ description.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="columns">
          <div class="column is-4 is-offset-4 has-text-centered">
            Mostrando {{ paginateChangeValue * (explanationShownIndex) + 1}} -
            {{ explanationsState.previewsTotalCount <= paginateChangeValue * (explanationShownIndex + 1) ?
            explanationsState.previewsTotalCount : paginateChangeValue * (explanationShownIndex + 1) }}
            de {{ explanationsState.previewsTotalCount }} explicaciones.<br>
            <div class="stepperButtons multiple">
              <button mat-mini-fab aria-label="prevExplanation" [title]="'Mostrar las '+paginateChangeValue+' anteriores'"
                      [disabled]="explanationShownIndex === 0 || explanationsState.loading || createdExplanationLoading || searchingExplanation"
                      (click)="refreshData('Exp', explanationShownIndex - 1)">
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <mat-spinner diameter="50" *ngIf="explanationsState.loading || createdExplanationLoading || searchingExplanation"></mat-spinner>
              <button mat-mini-fab color="accent" *ngIf="!explanationsState.loading" (click)="newExplanation()"
                      matTooltip="Nueva explicación" matTooltipPosition="below">
                <mat-icon>post_add</mat-icon>
              </button>
              <button mat-mini-fab color="accent" *ngIf="!explanationsState.loading" [disabled]="optionalsForm.get('explanation').value == ''" (click)="editSelectedExplanation()"
                      matTooltip="Editar explicación" matTooltipPosition="below">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-mini-fab aria-label="nextExplanation"  [title]="'Mostrar las '+paginateChangeValue+' siguientes'"
                      [disabled]="explanationShownIndex === explanationsState.previewsMaxIndex || explanationsState.loading || createdExplanationLoading || searchingExplanation"
                      (click)="refreshData('Exp', explanationShownIndex + 1)">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </div>
          <div class="column is-4 has-text-centered">
            Mostrando {{ paginateChangeValue * (descriptionShownIndex) + 1}} -
            {{ descriptionsState.previewsTotalCount <= paginateChangeValue * (descriptionShownIndex + 1) ?
            descriptionsState.previewsTotalCount : paginateChangeValue * (descriptionShownIndex + 1) }}
            de {{ descriptionsState.previewsTotalCount }} descripciones.<br>
            <div class="stepperButtons multiple">
              <button mat-mini-fab aria-label="prevDescription" [title]="'Mostrar las '+paginateChangeValue+' anteriores'"
                      [disabled]="descriptionShownIndex === 0 || descriptionsState.loading || createdDescriptionLoading || searchingDescription"
                      (click)="refreshData('Des', descriptionShownIndex - 1)">
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <mat-spinner diameter="50" *ngIf="descriptionsState.loading || createdDescriptionLoading || searchingDescription"></mat-spinner>
              <button mat-mini-fab color="accent" *ngIf="!descriptionsState.loading" (click)="newDescription()"
                      matTooltip="Nueva descripción" matTooltipPosition="below">
                <mat-icon>post_add</mat-icon>
              </button>
              <button mat-mini-fab color="accent" *ngIf="!descriptionsState.loading" [disabled]="optionalsForm.get('description').value === ''" (click)="editSelectedDescription()"
                      matTooltip="Editar descripción" matTooltipPosition="below">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-mini-fab aria-label="nextDescription"  [title]="'Mostrar las '+paginateChangeValue+' siguientes'"
                      [disabled]="descriptionShownIndex === descriptionsState.previewsIndexLoaded || descriptionsState.loading || createdDescriptionLoading || searchingDescription"
                      (click)="refreshData('Des', descriptionShownIndex + 1)">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4 is-offset-4 has-text-centered">
            <mat-form-field>
              <input matInput placeholder="Buscar Explicación por ID" formControlName="explanationIdSearch">
              <mat-icon matSuffix>search</mat-icon>
              <mat-error *ngIf="searchExplanationError">La explicación no existe</mat-error>
            </mat-form-field>
          </div>
          <div class="column is-4 has-text-centered">
            <mat-form-field>
              <input matInput placeholder="Buscar Descripción por ID" formControlName="descriptionIdSearch">
              <mat-icon matSuffix>search</mat-icon>
              <mat-error *ngIf="searchDescriptionError">La descripción no existe</mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>
<div id="questionForm" mat-dialog-actions align="end">
  <div class="has-text-danger" *ngIf="errorOnSave === 'save'">Error al guardar, intente de nuevo o intente más tarde.</div>
  <div class="has-text-danger" *ngIf="errorOnSave === 'size'">El tamaño de imagen admitido es de máximo 1 MB.</div>
  <div class="has-text-danger" *ngIf="errorOnSave === 'title'">Título automático no definido,
    en caso de contar únicamente con imágenes es necesario definirlo de forma manual.</div>
  <button [disabled]="loading" mat-raised-button (click)="close()">Regresar</button>
  <button [ngClass]="{ 'button is-loading': loading }" mat-raised-button (click)="attempt()" color="primary"
          [disabled]="!formsAreSetUp() || dataAndTypeForm.invalid || optionAndTopicForm.invalid">Guardar</button>
</div>
