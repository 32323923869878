<div class="constraint-height" *ngIf="activeForm && explanationsState">
  <form [formGroup]="activeForm" autocomplete="off">
    <div class="columns is-centered">
      <mat-form-field class="column is-4">
        <mat-select formControlName="explanation" placeholder="Seleccionar explicación"
                    [disabled]="explanationsState.loading || createdExplanationLoading || searchingExplanation">
          <mat-option>
            <ngx-mat-select-search formControlName="explanationSearch" [placeholderLabel]="'Buscar explicación'"
                                   [noEntriesFoundLabel]="'Explicación no encontrada'"></ngx-mat-select-search>
          </mat-option>
          <mat-option value="">Sin explicación</mat-option>
          <mat-option *ngFor="let explanation of explanationFilteredPreviews"
                      [value]="explanation.reference && explanation.reference.path ? explanation.reference.path : explanation.reference">
            {{ explanation.title }}
          </mat-option>
        </mat-select>
        <mat-hint>Cambiar la explicación recargará las referencias.</mat-hint>
      </mat-form-field>
    </div>
    <div class="columns is-centered">
      <div class="column is-4 has-text-centered">
        Mostrando {{ paginateChangeValue * (explanationShownIndex) + 1}} -
        {{ explanationsState.previewsTotalCount <= paginateChangeValue * (explanationShownIndex + 1) ?
        explanationsState.previewsTotalCount : paginateChangeValue * (explanationShownIndex + 1) }}
        de {{ explanationsState.previewsTotalCount }} explicaciones.<br>
        <div class="stepperButtons multiple">
          <button mat-mini-fab aria-label="prevExplanation" [title]="'Mostrar las ' + paginateChangeValue + ' anteriores'"
                  [disabled]="explanationShownIndex === 0 || explanationsState.loading || createdExplanationLoading || searchingExplanation"
                  (click)="refreshData(explanationShownIndex - 1)">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <mat-spinner diameter="50" *ngIf="explanationsState.loading || createdExplanationLoading || searchingExplanation"></mat-spinner>
          <button mat-mini-fab color="accent" *ngIf="!explanationsState.loading" (click)="newExplanation()"
                  matTooltip="Nueva explicación" matTooltipPosition="below">
            <mat-icon>post_add</mat-icon>
          </button>
          <button mat-mini-fab color="accent" *ngIf="!explanationsState.loading" [disabled]="activeForm.get('explanation').value === ''" (click)="editSelectedExplanation()"
                  matTooltip="Editar explicación" matTooltipPosition="below">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-mini-fab aria-label="nextExplanation"  [title]="'Mostrar las '+paginateChangeValue+' siguientes'"
                  [disabled]="explanationShownIndex === explanationsState.previewsMaxIndex || explanationsState.loading || createdExplanationLoading || searchingExplanation"
                  (click)="refreshData(explanationShownIndex + 1)">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-4 has-text-centered">
        <mat-form-field>
          <input matInput placeholder="Buscar Explicación por ID" formControlName="explanationIdSearch">
          <mat-icon matSuffix>search</mat-icon>
          <mat-error *ngIf="searchExplanationError">La explicación no existe</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div class="is-flex flex-center">
  <mat-spinner diameter="125" *ngIf="!(activeForm && explanationsState)"></mat-spinner>
</div>
