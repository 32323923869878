import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { showAlert } from './alerts.actions';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable() export class AlertsEffects {

  constructor (private actions$: Actions) { }

  showAlert$ = createEffect(
    () => this.actions$.pipe(
      ofType(showAlert),
      tap((action) => {
        let textToShow = '';
        if (action.alertType === 'error') {
          const error = action.error;
          const message = error.message ? error.message : 'Unexpected error, try again later.';
          const code = error.code ? error.code : '500';
          textToShow = `${code}: ${message}`;
        } else {
          textToShow = action.message;
        }
        const settings: SweetAlertOptions = {
          position: action.position ? action.position : 'center',
          type: action.alertType,
          title: action.title,
          confirmButtonText: 'Continuar',
          cancelButtonText: 'Cancelar',
          showCancelButton: !!action.showCancel,
          showConfirmButton: !!action.showConfirm,
        };
        if (action.toast) {
          settings.toast = true;
          settings.showConfirmButton = true;
          settings.confirmButtonColor = '#1d7d26'
          settings.confirmButtonText = '✔';
        }
        if (action.timer) {
          settings.timer = action.timer;
        }
        if (action.html) {
          settings.html = textToShow;
          settings.width = '700px';
        } else {
          settings.text = textToShow;
        }
        return Swal.fire(settings);
      }),
    ),
    { dispatch: false },
  );

}
