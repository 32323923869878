import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { HelperService } from '../../../services/Helper/helper.service';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { auditTime, take, takeUntil } from 'rxjs/operators';
import { getFlashcardsState, getSelectedFlashcardState } from '../../../core/ngrx/flashcards/flashcards.selectors';
import {
  changeFlashcardsPaginationValue,
  loadFlashcards,
  loadNewFlashcardsPage,
} from '../../../core/ngrx/flashcards/flashcards.actions';
import { getAuthState } from '../../../core/ngrx/authentication/authentication.selectors';
import { Flashcard } from '../../../core/ngrx/flashcards/flashcards.interfaces';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'em-flashcard-dashboard',
  templateUrl: './flashcard-dashboard.component.html',
  styleUrls: ['./flashcard-dashboard.component.scss'],
})
export class FlashcardDashboardComponent implements OnInit, OnDestroy {

  $destroy: Subject<boolean> = new Subject<boolean>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  isLoading = true;
  loadedPaginator = false;
  defaultMatPaginatorValue = environment.defaultPaginatorValue;

  pageLoading = true;
  isReversed = false;
  lastPaginationIndexLoaded = -1;
  flashcardsLength: number;
  maxIndex: number;
  flashcards: Flashcard[] = [];
  filteredFlashcards: Flashcard[];
  copiedId = '';

  constructor (private store: Store<any>, private route: ActivatedRoute, private helper: HelperService) {}

  ngOnInit () {
    const checkTime = 50;
    let interval;
    this.setStateSubscriptions();
    this.route.url.subscribe((segmentArray) => {
      if (!interval && segmentArray.length === 1 && segmentArray[0].path === 'editor') {
        this.pageLoading = true;
        interval = setInterval(
          () => {
            if (!this.isLoading && this.paginator) {
              this.setInitialPaginationValues();
              clearInterval(interval);
            }
          },
          checkTime,
        );
      }
    });
  }

  setStateSubscriptions () {
    const auditTimeVal = 150;
    const states = combineLatest([
      this.store.pipe(takeUntil(this.$destroy), auditTime(auditTimeVal), select(getFlashcardsState)),
      this.store.pipe(takeUntil(this.$destroy), auditTime(auditTimeVal), select(getSelectedFlashcardState)),
    ]);
    states.subscribe(([flashcards, selectedFlashcard]) => {
      if (!flashcards.loading && flashcards.previewsTotalCount > 0) {
        this.flashcards = flashcards.previews;
        this.filteredFlashcards = flashcards.previews.slice(0, this.defaultMatPaginatorValue);
        this.flashcardsLength = flashcards.previewsTotalCount;
        this.lastPaginationIndexLoaded = flashcards.previewsIndexLoaded;
        if (!isNaN(this.maxIndex) && this.maxIndex < flashcards.previewsMaxIndex) {
          this.store.dispatch(loadNewFlashcardsPage());
          this.maxIndex = flashcards.previewsMaxIndex;
        } else {
          this.maxIndex = flashcards.previewsMaxIndex;
        }
        if (this.paginator) {
          this.changedPage({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize });
        }
      }
      this.isLoading = flashcards.loading || selectedFlashcard.loading;
    });
    this.store.pipe(takeUntil(this.$destroy), select(getAuthState)).subscribe((authState) => {
      if (authState && authState.user) {
        this.isReversed = authState.user.newToOld;
      }
    });
  }

  openFlashcardForm (flashcard?: Flashcard) {
    if (flashcard) {
      const route = `flashcards/edit/${flashcard.id}`;
      return this.helper.navigateTo(route);
    }
    return this.helper.navigateTo('flashcards/create');
  }

  changePageFromDashboard (event) {
    if (!this.pageLoading) {
      this.store.dispatch(changeFlashcardsPaginationValue({ newPagValue: event.pageSize, newIndex: event.pageIndex }));
    }
    this.changedPage(event);
  }

  changedPage (event) {
    const firstIndexOfDescRequired = event.pageSize * (event.pageIndex);
    const lastIndexOfDescRequired = event.pageSize * (event.pageIndex + 1) - 1;
    this.filteredFlashcards = this.flashcards.slice(firstIndexOfDescRequired, lastIndexOfDescRequired + 1);
    let paginationIndexRequired = Math.trunc((lastIndexOfDescRequired) / environment.paginateVal);
    if (this.isReversed) {
      const lastPageSize = this.flashcardsLength % environment.paginateVal;
      if (lastIndexOfDescRequired % environment.paginateVal > lastPageSize) {
        paginationIndexRequired += 1;
      }
    }
    if (paginationIndexRequired > this.lastPaginationIndexLoaded && paginationIndexRequired <= this.maxIndex) {
      this.store.dispatch(loadFlashcards({ paginateVal: paginationIndexRequired }));
    }
  }

  setInitialPaginationValues () {
    if (!this.loadedPaginator) {
      this.loadedPaginator = true;
      this.store.pipe(select(getFlashcardsState), take(1)).subscribe((flashcardState) => {
        this.pageLoading = true;
        const paginator = this.paginator;
        paginator._changePageSize(flashcardState.pagination.pageValue);
        paginator.pageIndex = flashcardState.pagination.activeIndex;
        this.pageLoading = false;
      });
    }
  }

  copyIDToClipboard (id: string) {
    const time = 2500;
    this.helper.copyToClipboard(id);
    this.copiedId = id;
    setTimeout(() => this.copiedId = '', time);
  }

  ngOnDestroy (): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
