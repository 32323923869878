import { createAction, props } from '@ngrx/store';
import { ImageRefs } from '../dataTypes/dataTypes.interfaces';
import { Explanation, ExplanationPreview } from './explanations.interfaces';

export const cancelledRequest = createAction(
  '[Explanations] Cancelled request',
);
export const resetSelectedExplanation = createAction(
  '[Explanations] Reset selected explanation',
);
export const changeExplanationsPaginationValue = createAction(
  '[Explanations] Change pagination value',
  props<{ newIndex?: number, newPagValue?: number }>(),
);

export const loadExplanations = createAction(
  '[Explanations] Load explanations',
  props<{ paginateVal: number }>(),
);
export const loadExplanationsSuccess = createAction(
  '[Explanations] Load explanations success',
  props<{ explanationPreviews: ExplanationPreview[], index: number }>(),
);
export const loadExplanationsFail = createAction(
  '[Explanations] Load explanations failure',
  props<{ error: any }>(),
);
export const loadExplanationsWarn = createAction(
  '[Explanations] Load explanations warning',
  props<{ warning: any }>(),
);

export const loadNewExplanationsPage = createAction(
  '[Explanations] Load new explanations page',
);
export const loadNewExplanationsPageSuccess = createAction(
  '[Explanations] Load new explanations page success',
  props<{ explanationPreviews: ExplanationPreview[], index: number }>(),
);
export const loadNewExplanationsPageFail = createAction(
  '[Explanations] Load new explanations page failure',
  props<{ error: any }>(),
);

export const loadExplanationsCount = createAction(
  '[Explanations] Load explanations count',
);
export const loadExplanationsCountSuccess = createAction(
  '[Explanations] Load explanations count success',
  props<{ explanationsTotalLength: number, explanationsMaxIndex: number }>(),
);
export const loadExplanationsCountFail = createAction(
  '[Explanations] Load explanations count failure',
  props<{ error: any }>(),
);

export const openExplanationForm = createAction(
  '[Explanations] Open explanation form',
  props<{ preview: ExplanationPreview | string }>(),
);
export const openExplanationFormSuccess = createAction(
  '[Explanations] Open explanation form success',
  props<{ explanation: Explanation }>(),
);
export const openExplanationFormFail = createAction(
  '[Explanations] Open explanation form failure',
  props<{ error: any }>(),
);

export const saveExplanation = createAction(
  '[Explanations] Save explanation',
  props<{ explanation: Explanation, imagesToDelete: ImageRefs[] }>(),
);
export const saveExplanationSuccess = createAction(
  '[Explanations] Save explanation success',
  props<{ id: string }>(),
);
export const saveExplanationFail = createAction(
  '[Explanations] Save explanation fail',
  props<{ error: any }>(),
);

export const saveExplanationWithImages = createAction(
  '[Explanations] Save explanation with images',
  props<{ imagesToUpload: string[], imagesToDelete: ImageRefs[], explanation: Explanation }>(),
);
export const saveExplanationWithImagesSuccess = createAction(
  '[Explanations] Save explanation with images success',
  props<{ id: string }>(),
);
export const saveExplanationWithImagesFail = createAction(
  '[Explanations] Save explanation with images fail',
  props<{ error: any }>(),
);
