import { Component } from '@angular/core';

@Component({
  selector: 'em-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {

  constructor () {}

}
