import { Flashcard, FlashcardsState, FlashcardState, SelectedFlashcardState } from './flashcards.interfaces';
import { Action, ActionReducerMap, createReducer, on } from '@ngrx/store';
import {
  loadFlashcards,
  loadFlashcardsFail,
  loadFlashcardsSuccess,
  loadFlashcardsCount,
  loadFlashcardsCountSuccess,
  loadFlashcardsCountFail,
  saveFlashcard,
  saveFlashcardSuccess,
  saveFlashcardFail,
  openFlashcardForm,
  openFlashcardFormSuccess,
  openFlashcardFormFail,
  resetSelectedFlashcard,
  loadNewFlashcardsPage,
  loadNewFlashcardsPageSuccess,
  loadNewFlashcardsPageFail,
  changeFlashcardsPaginationValue,
} from './flashcards.actions';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { PaginationValue } from '../dataTypes/dataTypes.interfaces';
import { isNullOrUndefined } from 'util';

const flashcardsInitialState: FlashcardsState = {
  pagination: { activeIndex: 0, pageValue: environment.defaultPaginatorValue },
  previews: [],
  previewsPages: [],
  previewsTotalCount: 0,
  previewsIndexLoaded: -1,
  previewsMaxIndex: -1,
  loading: false,
};

const flashcardInitialState: SelectedFlashcardState = {
  flashcard: undefined,
  uploadingImages: false,
  loading: false,
};

const flashcardsLocalReducer = createReducer(
  flashcardsInitialState,
  on(changeFlashcardsPaginationValue, (state: FlashcardsState, { newIndex, newPagValue }) => {
    const newPaginationVal: PaginationValue = { ...state.pagination };
    if (!isNullOrUndefined(newIndex)) {
      newPaginationVal.activeIndex = newIndex;
    }
    if (!isNullOrUndefined(newPagValue)) {
      newPaginationVal.pageValue = newPagValue;
    }
    const newState: FlashcardsState = {
      ...state,
      pagination: newPaginationVal,
    };
    return newState;
  }),
  on(loadFlashcards, (state: FlashcardsState) => {
    const newState: FlashcardsState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(loadFlashcardsSuccess, (state: FlashcardsState, { flashcardsData, index }) => {
    const newState: FlashcardsState = {
      ...state,
      loading: false,
      error: undefined,
    };
    if (index > state.previewsIndexLoaded) {
      newState.previewsPages = state.previewsPages.concat([flashcardsData]);
      newState.previewsIndexLoaded = index;
    } else {
      newState.previewsPages = state.previewsPages.map((p, i) => {
        if (i === index) {
          return flashcardsData;
        }
        return p;
      });
    }
    newState.previews = _.flattenDepth(newState.previewsPages, 1);
    return newState;
  }),
  on(loadFlashcardsFail, (state: FlashcardsState, { error }) => {
    const newState: FlashcardsState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
  on(loadNewFlashcardsPage, (state: FlashcardsState) => {
    const newState: FlashcardsState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(loadNewFlashcardsPageSuccess, (state: FlashcardsState, { flashcardsData, index }) => {
    const newState: FlashcardsState = {
      ...state,
      loading: false,
      error: undefined,
    };
    newState.previewsPages = [flashcardsData].concat(state.previewsPages);
    newState.previewsIndexLoaded = state.previewsIndexLoaded + 1;
    newState.previews = _.flattenDepth(newState.previewsPages, 1);
    return newState;
  }),
  on(loadNewFlashcardsPageFail, (state: FlashcardsState, { error }) => {
    const newState: FlashcardsState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
  on(loadFlashcardsCount, (state: FlashcardsState) => {
    const newState: FlashcardsState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(loadFlashcardsCountSuccess, (state: FlashcardsState, { flashcardsTotalLength, flashcardsMaxIndex }) => {
    const newState: FlashcardsState = {
      ...state,
      previewsTotalCount: flashcardsTotalLength,
      previewsMaxIndex: flashcardsMaxIndex,
      loading: false,
      error: undefined,
    };
    return newState;
  }),
  on(loadFlashcardsCountFail, (state: FlashcardsState, { error }) => {
    const newState: FlashcardsState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
);

const flashcardLocalReducer = createReducer(
  flashcardInitialState,
  on(resetSelectedFlashcard, (state: SelectedFlashcardState) => {
    const newState: SelectedFlashcardState = {
      ...state,
      flashcard: undefined,
      loading: false,
      error: undefined,
    };
    return newState;
  }),
  on(openFlashcardForm, (state: SelectedFlashcardState) => {
    const newState: SelectedFlashcardState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(openFlashcardFormSuccess, (state: SelectedFlashcardState, { flashcard }) => {
    const newState: SelectedFlashcardState = {
      ...state,
      flashcard,
      loading: false,
      error: undefined,
    };
    return newState;
  }),
  on(openFlashcardFormFail, (state: SelectedFlashcardState, { error }) => {
    const newState: SelectedFlashcardState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
  on(saveFlashcard, (state: SelectedFlashcardState, { flashcard }) => {
    const newState: SelectedFlashcardState = {
      ...state,
      flashcard,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(saveFlashcardSuccess, (state: SelectedFlashcardState, { id }) => {
    const flashcard: Flashcard = {
      ...state.flashcard,
      id,
    };
    const newState: SelectedFlashcardState = {
      ...state,
      flashcard,
      loading: false,
      error: undefined,
    };
    return newState;
  }),
  on(saveFlashcardFail, (state: SelectedFlashcardState, { error }) => {
    const newState: SelectedFlashcardState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
);

export function flashcardsReducer (state: FlashcardsState | undefined, action: Action) {
  return flashcardsLocalReducer(state, action);
}

export function selectedFlashcardReducer (state: SelectedFlashcardState | undefined, action: Action) {
  return flashcardLocalReducer(state, action);
}

export const flashcardReducer: ActionReducerMap<FlashcardState> = {
  selectedFlashcard: selectedFlashcardReducer,
  flashcards: flashcardsReducer,
};
