import { QuestionsState, QuestionState, SelectedQuestionState } from './questions.interfaces';
import { Action, ActionReducerMap, createReducer, on } from '@ngrx/store';
import {
  loadQuestions,
  loadQuestionsFail,
  loadQuestionsSuccess,
  loadQuestionsCount,
  loadQuestionsCountSuccess,
  loadQuestionsCountFail,
  saveQuestion,
  saveQuestionSuccess,
  saveQuestionFail,
  openQuestionForm,
  openQuestionFormSuccess,
  openQuestionFormFail,
  saveQuestionWithImagesFail,
  saveQuestionWithImagesSuccess,
  saveQuestionWithImages,
  loadNewQuestionsPage,
  loadNewQuestionsPageSuccess,
  loadNewQuestionsPageFail,
  openQuestionView,
  openQuestionViewSuccess,
  openQuestionViewFail,
  publishQuestionFail,
  deactivateQuestionFail,
  reactivateQuestionFail,
  publishQuestionSuccess,
  deactivateQuestionSuccess,
  reactivateQuestionSuccess,
  changeQuestionsPaginationValue,
  changeQuestionsPaginationValueSuccess,
  deleteQuestionSuccess,
  deleteQuestionFail,
} from './questions.actions';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { PaginationValue } from '../dataTypes/dataTypes.interfaces';
import { isNullOrUndefined } from 'util';

const questionsInitialState: QuestionsState = {
  pagination: { activeIndex: 0, pageValue: environment.defaultPaginatorValue },
  previews: [],
  previewsPages: [],
  counters: {
    all: { total: -1, pagination: [] },
    created: { total: -1, pagination: [] },
    published: { total: -1, pagination: [] },
    unpublished: { total: -1, pagination: [] },
    deleted: { total: -1, pagination: [] },
  },
  previewsIndexLoaded: -1,
  previewsMaxIndex: -1,
  loading: false,
};

const questionInitialState: SelectedQuestionState = {
  question: undefined,
  uploadingImages: false,
  loading: false,
};

const questionsLocalReducer = createReducer(
  questionsInitialState,
  on(changeQuestionsPaginationValue, (state: QuestionsState) => {
    const newState: QuestionsState = {
      ...state,
      loading: true,
    };
    return newState;
  }),
  on(changeQuestionsPaginationValueSuccess, (state: QuestionsState, { status, newIndex, newPagValue }) => {
    const newPaginationVal: PaginationValue = { ...state.pagination, statusFilter: status };
    if (!isNullOrUndefined(newIndex)) {
      newPaginationVal.activeIndex = newIndex;
    }
    if (!isNullOrUndefined(newPagValue)) {
      newPaginationVal.pageValue = newPagValue;
    }
    const newState: QuestionsState = {
      ...state,
      loading: false,
      pagination: newPaginationVal,
    };
    return newState;
  }),
  on(loadQuestions, loadNewQuestionsPage, loadQuestionsCount, (state: QuestionsState) => {
    const newState: QuestionsState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(publishQuestionSuccess, deactivateQuestionSuccess, reactivateQuestionSuccess, deleteQuestionSuccess, (state: QuestionsState) => {
    const newState: QuestionsState = {
      ...state,
      loading: false,
    };
    return newState;
  }),
  on(loadQuestionsSuccess, (state: QuestionsState, { questionPreviews, index }) => {
    const newState: QuestionsState = {
      ...state,
      loading: false,
      error: undefined,
    };
    if (index > state.previewsIndexLoaded) {
      newState.previewsPages = state.previewsPages.concat([questionPreviews]);
      newState.previewsIndexLoaded = index;
    } else {
      newState.previewsPages = state.previewsPages.map((p, i) => {
        if (i === index) {
          return questionPreviews;
        }
        return p;
      });
    }
    newState.previews = _.flattenDepth(newState.previewsPages, 1);
    return newState;
  }),
  on(loadNewQuestionsPageSuccess, (state: QuestionsState, { questionPreviews }) => {
    const newState: QuestionsState = {
      ...state,
      loading: false,
      error: undefined,
    };
    newState.previewsPages = [questionPreviews].concat(state.previewsPages);
    newState.previewsIndexLoaded = state.previewsIndexLoaded + 1;
    newState.previews = _.flattenDepth(newState.previewsPages, 1);
    return newState;
  }),
  on(loadQuestionsCountSuccess, (state: QuestionsState, { questionCountObject, questionsMaxIndex }) => {
    const newState: QuestionsState = {
      ...state,
      counters: questionCountObject,
      previewsMaxIndex: questionsMaxIndex,
      loading: false,
      error: undefined,
    };
    return newState;
  }),
  on(loadQuestionsFail, loadNewQuestionsPageFail, loadQuestionsCountFail, publishQuestionFail, deactivateQuestionFail,
     reactivateQuestionFail, deleteQuestionFail, (state: QuestionsState, { error }) => {
    const newState: QuestionsState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
);

const questionLocalReducer = createReducer(
  questionInitialState,
  on(openQuestionForm, openQuestionView, (state: SelectedQuestionState) => {
    const newState: SelectedQuestionState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(openQuestionFormSuccess, openQuestionViewSuccess, (state: SelectedQuestionState, { question }) => {
    const newState: SelectedQuestionState = {
      ...state,
      question,
      loading: false,
      error: undefined,
    };
    return newState;
  }),
  on(saveQuestion, (state: SelectedQuestionState, { question }) => {
    const newState: SelectedQuestionState = {
      ...state,
      question,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(saveQuestionSuccess, (state: SelectedQuestionState) => {
    const newState: SelectedQuestionState = {
      ...state,
      loading: false,
      error: undefined,
    };
    return newState;
  }),
  on(saveQuestionWithImages, (state: SelectedQuestionState, { question }) => {
    const newState: SelectedQuestionState = {
      ...state,
      question,
      uploadingImages: true,
      error: undefined,
    };
    return newState;
  }),
  on(saveQuestionWithImagesSuccess, (state: SelectedQuestionState) => {
    const newState: SelectedQuestionState = {
      ...state,
      uploadingImages: false,
      error: undefined,
    };
    return newState;
  }),
  on(openQuestionFormFail, openQuestionViewFail, saveQuestionFail, saveQuestionWithImagesFail,
     (state: SelectedQuestionState, { error }) => {
    const newState: SelectedQuestionState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
);

export function questionsReducer (state: QuestionsState | undefined, action: Action) {
  return questionsLocalReducer(state, action);
}

export function selectedQuestionReducer (state: SelectedQuestionState | undefined, action: Action) {
  return questionLocalReducer(state, action);
}

export const questionReducer: ActionReducerMap<QuestionState> = {
  selectedQuestion: selectedQuestionReducer,
  questions: questionsReducer,
};
