import { Component } from '@angular/core';

@Component({
  selector: 'em-not-allowed',
  templateUrl: './not-allowed.component.html',
  styleUrls: ['./not-allowed.component.scss'],
})
export class NotAllowedComponent {

  constructor () { }

}
