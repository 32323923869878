import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { DescriptionPreview } from '../../../core/ngrx/descriptions/descriptions.interfaces';
import { environment } from '../../../../environments/environment';
import { auditTime, take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { getDescriptionsState, getSelectedDescriptionState } from '../../../core/ngrx/descriptions/descriptions.selectors';
import {
  changeDescriptionsPaginationValue,
  loadDescriptions,
  loadNewDescriptionsPage,
} from '../../../core/ngrx/descriptions/descriptions.actions';
import { getAuthState } from '../../../core/ngrx/authentication/authentication.selectors';
import { firestore } from 'firebase/app';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from '../../../services/Helper/helper.service';

@Component({
  selector: 'em-description',
  templateUrl: './description-dashboard.component.html',
  styleUrls: ['./description-dashboard.component.scss'],
})
export class DescriptionDashboardComponent implements OnInit, OnDestroy {

  $destroy: Subject<boolean> = new Subject<boolean>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  defaultMatPaginatorValue = environment.defaultPaginatorValue;
  loadedPaginator = false;

  isLoading = true;
  pageLoading = false;
  isReversed = false;
  lastPaginationIndexLoaded = -1;
  descriptionsLength: number;
  maxIndex: number;
  descriptions: DescriptionPreview[];
  filteredDescriptions: DescriptionPreview[];
  copiedId = '';

  constructor (private store: Store<any>, private route: ActivatedRoute, private helper: HelperService) {}

  ngOnInit () {
    const checkTime = 50;
    let interval;
    this.setStateSubscriptions();
    this.route.url.subscribe((segmentArray) => {
      if (!interval && segmentArray.length === 1 && segmentArray[0].path === 'editor') {
        this.pageLoading = true;
        interval = setInterval(
          () => {
            if (!this.isLoading && this.paginator) {
              this.setInitialPaginationValues();
              clearInterval(interval);
            }
          },
          checkTime,
        );
      }
    });
  }

  setStateSubscriptions () {
    const auditTimeVal = 150;
    const states = combineLatest([
      this.store.pipe(takeUntil(this.$destroy), auditTime(auditTimeVal), select(getDescriptionsState)),
      this.store.pipe(takeUntil(this.$destroy), auditTime(auditTimeVal), select(getSelectedDescriptionState)),
    ]);
    states.subscribe(([descriptions, selectedDescription]) => {
      if (!descriptions.loading && descriptions.previewsTotalCount > 0) {
        this.descriptions = descriptions.previews;
        this.filteredDescriptions = descriptions.previews.slice(0, this.defaultMatPaginatorValue);
        this.descriptionsLength = descriptions.previewsTotalCount;
        this.lastPaginationIndexLoaded = descriptions.previewsIndexLoaded;
        if (this.maxIndex && this.maxIndex < descriptions.previewsMaxIndex) {
          this.store.dispatch(loadNewDescriptionsPage());
          this.maxIndex = descriptions.previewsMaxIndex;
        } else {
          this.maxIndex = descriptions.previewsMaxIndex;
        }
        if (this.paginator) {
          this.changedPage({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize });
        }
      }
      this.isLoading = descriptions.loading || selectedDescription.loading;
    });
    this.store.pipe(takeUntil(this.$destroy), select(getAuthState)).subscribe((authState) => {
      if (authState && authState.user) {
        this.isReversed = authState.user.newToOld;
      }
    });
  }

  openDescriptionForm (desc?: DescriptionPreview) {
    if (desc) {
      const route = `descriptions/edit/${this.helper.transformReferenceToId(desc.reference)}`;
      return this.helper.navigateTo(route);
    }
    return this.helper.navigateTo('descriptions/create');
  }

  changePageFromDashboard (event) {
    if (!this.pageLoading) {
      this.store.dispatch(changeDescriptionsPaginationValue({ newPagValue: event.pageSize, newIndex: event.pageIndex }));
    }
    this.changedPage(event);
  }

  changedPage (event) {
    const firstIndexOfDescRequired = event.pageSize * (event.pageIndex);
    const lastIndexOfDescRequired = event.pageSize * (event.pageIndex + 1) - 1;
    this.filteredDescriptions = this.descriptions.slice(firstIndexOfDescRequired, lastIndexOfDescRequired + 1);
    let paginationIndexRequired = Math.trunc((lastIndexOfDescRequired) / environment.paginateVal);
    if (this.isReversed) {
      const lastPageSize = this.descriptionsLength % environment.paginateVal;
      if (lastIndexOfDescRequired % environment.paginateVal > lastPageSize) {
        paginationIndexRequired += 1;
      }
    }
    if (paginationIndexRequired > this.lastPaginationIndexLoaded && paginationIndexRequired <= this.maxIndex) {
      this.store.dispatch(loadDescriptions({ paginateVal: paginationIndexRequired }));
    }
  }

  copyIDToClipboard (id: string | firestore.DocumentReference) {
    const time = 2500;
    const stringId = this.helper.transformReferenceToId(id);
    this.helper.copyToClipboard(stringId);
    this.copiedId = stringId;
    setTimeout(() => this.copiedId = '', time);
  }

  setInitialPaginationValues () {
    if (!this.loadedPaginator) {
      this.loadedPaginator = true;
      this.store.pipe(select(getDescriptionsState), take(1)).subscribe((descriptionState) => {
        this.pageLoading = true;
        const paginator = this.paginator;
        paginator._changePageSize(descriptionState.pagination.pageValue);
        paginator.pageIndex = descriptionState.pagination.activeIndex;
        this.pageLoading = false;
      });
    }
  }

  ngOnDestroy (): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
