<div mat-dialog-title class="columns is-gapless">
  <div class="column is-1" *ngIf="isAnEditForm">
    <button mat-icon-button [matTooltip]="'Copiar ID de la descripción seleccionada'"
            matTooltipPosition="above" (click)="copyIDToClipboard(description.id)">
      <mat-icon color="primary">bookmarks</mat-icon>
    </button>
    <button mat-icon-button [matTooltip]="'Copiar URL de la descripción seleccionada'"
            matTooltipPosition="above" (click)="copyURLToClipboard(description.id)">
      <mat-icon color="primary">link</mat-icon>
    </button>
  </div>
  <div class="column" [ngClass]="isAnEditForm ? 'is-11' : 'is-12'">
    <h1 [matTooltip]="description.title" matTooltipPosition="below">
      {{ isAnEditForm ? 'Editar descripción: ' + description.title : 'Crear descripción' }}
    </h1>
  </div>
</div>
<div mat-dialog-content class="columns constraint-height">
  <form class="column" [formGroup]="descriptionDialogForm" autocomplete="off">
    <mat-form-field>
      <input matInput placeholder="Título" formControlName="title">
      <mat-hint>El título se actualiza de forma automática con el primer párrafo.</mat-hint>
    </mat-form-field>
    <em-html-text-editor #htmlEditor></em-html-text-editor>
    <mat-divider></mat-divider>
    <div class="actionButtons" *ngIf="editing !== undefined">
      <button mat-raised-button (click)="updateText()" color="accent" [disabled]="loading">Actualizar texto</button>
      <button mat-raised-button (click)="cancelUpdateText()" color="accent" [disabled]="loading">Cancelar edición</button>
    </div>
    <div [ngClass]="{ 'actionButtons': editing === undefined }" [hidden]="editing !== undefined">
      <button mat-raised-button (click)="addText()" color="primary" [disabled]="loading">Agregar texto</button>
      <div class="imageInputs" *ngIf="descriptionDialogForm.get('images')">
        <div class="imageInput" formArrayName="images" *ngFor="let image of getImagesArrayFromForm().controls; let i = index;">
          <div [formGroupName]="i">
            <input [id]="'descriptionImage'+i" formControlName="image" type="file" mat-raised-button accept='image/jpeg, image/png'
                   (change)="addImage($event)" color="primary" placeholder="Agregar imagen">
            <button type="button" mat-raised-button (click)="clickedAddImage(i)" color="primary" [disabled]="loading">Agregar imagen</button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="column preview">
    <h4 class="title is-4">Vista previa</h4>
    <mat-card *ngIf="imageLoading" >
      <mat-spinner></mat-spinner>
    </mat-card>
    <mat-card *ngIf="description" [ngClass]="{'is-hidden': imageLoading}" cdkDropList (cdkDropListDropped)="drop($event)">
      <div class="is-flex draggable preview-element" *ngFor="let content of description.data; let i = index" cdkDrag>
        <span *ngIf="description.type[i] === 'txt' && !isHtmlEditable(i)" matLine>
          {{content}}
        </span>
        <div *ngIf="description.type[i] === 'txt' && isHtmlEditable(i)" matLine>
          <em-html-text-previewer [html]="content"></em-html-text-previewer>
        </div>
        <figure *ngIf="description.type[i] === 'img' || description.type[i] === 'preview'" class="image is-responsive" matLine>
          <img [src]="getImageSrc(content, description.type[i])" (load)="loadedImage(content)">
        </figure>
        <span>
          <button *ngIf="description.type[i] === 'txt'" mat-icon-button color="primary" (click)="editItem(i)" [disabled]="loading">
            <mat-icon aria-label="Editar">edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="removeItem(i)" [disabled]="description.data.length === 1 || loading">
            <mat-icon aria-label="Eliminar">delete</mat-icon>
          </button>
        </span>
      </div>
    </mat-card>
  </div>
</div>
<div id="descriptionFooter" mat-dialog-actions align="end">
  <div class="has-text-danger" *ngIf="errorOnSave === 'save'">Error al guardar, intente de nuevo o intente más tarde.</div>
  <div class="has-text-danger" *ngIf="errorOnSave === 'size'">El tamaño de imagen admitido es de máximo 1 MB.</div>
  <div class="has-text-danger" *ngIf="errorOnSave === 'title'">Título automático no definido, en caso de contar únicamente con imágenes es necesario definirlo de forma manual.</div>
  <button [disabled]="loading" mat-raised-button (click)="close()">Regresar</button>
  <button [ngClass]="{ 'button is-loading': loading }" mat-raised-button (click)="attempt()" color="primary" [disabled]="!description || !description.data.length">Guardar</button>
</div>
