import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ImageService } from '../../../services/Image/image.service';
import { ExplanationService } from '../../../services/Explanation/explanation.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material';
import { catchError, concatMap, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import { getAuthState } from '../authentication/authentication.selectors';
import {
  cancelledRequest,
  loadExplanations,
  loadExplanationsCount,
  loadExplanationsCountFail,
  loadExplanationsCountSuccess,
  loadExplanationsFail,
  loadExplanationsSuccess,
  loadExplanationsWarn,
  loadNewExplanationsPage,
  loadNewExplanationsPageFail,
  loadNewExplanationsPageSuccess,
  openExplanationForm,
  openExplanationFormFail,
  openExplanationFormSuccess,
  saveExplanation,
  saveExplanationFail,
  saveExplanationSuccess,
  saveExplanationWithImages,
  saveExplanationWithImagesFail,
  saveExplanationWithImagesSuccess,
} from './explanations.actions';
import { ExplanationFormComponent } from '../../../components/explanation-components/explanation-form/explanation-form.component';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { Explanation } from './explanations.interfaces';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';
import { showAlert } from '../alerts/alerts.actions';
import { environment } from '../../../../environments/environment';
import { getExplanationsState } from './explanations.selectors';
import { HelperService } from '../../../services/Helper/helper.service';

@Injectable() export class ExplanationsEffects {
  constructor (
    private store: Store<any>,
    private actions$: Actions,
    private imageService: ImageService,
    private helperService: HelperService,
    private explanationService: ExplanationService,
    private fireStoreDB: AngularFirestore,
    private dialog: MatDialog,
  ) {}

  loadExplanations$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadExplanations),
      concatMap(action => of(action).pipe(withLatestFrom(
        this.store.pipe(select(getAuthState)),
        this.store.pipe(select(getExplanationsState)),
      ))),
      mergeMap(([action, authState, explanationState]) => {
        const index = action.paginateVal;
        const indexesToLoad: number[] = [];
        for (let i = explanationState.previewsIndexLoaded + 1; i <= index && i <= explanationState.previewsMaxIndex; i += 1) {
          let indexCorrected = i;
          if (authState.user.newToOld) {
            indexCorrected = explanationState.previewsMaxIndex;
            for (let j = 1; j <= i; j += 1) {
              indexCorrected -= 1;
            }
          }
          indexesToLoad.push(indexCorrected);
        }
        if (indexesToLoad.length === 0) {
          return of(loadExplanationsWarn({ warning: `Page already loaded: ${index}` }));
        }
        return from(indexesToLoad).pipe(
          mergeMap((index: number) => {
            return combineLatest([of(index), this.explanationService.getExplanations(index)]);
          }),
          map(([index, previews]) => {
            const explanationPreviews = previews.map((p) => {
              if (p.reference instanceof firestore.DocumentReference) {
                p.reference = p.reference.path;
              }
              return p;
            });
            if (authState.user.newToOld) {
              explanationPreviews.reverse();
            }
            let indexCorrected = index;
            if (authState.user.newToOld) {
              indexCorrected = 0;
              for (let j = explanationState.previewsMaxIndex - 1; j >= index; j -= 1) {
                indexCorrected += 1;
              }
              indexesToLoad.push(indexCorrected);
            }
            return loadExplanationsSuccess({ explanationPreviews, index: indexCorrected });
          }),
          catchError((error) => {
            if (authState && authState.user) {
              return of(loadExplanationsFail({ error }));
            }
            return of(cancelledRequest());
          }),
        );
      }),
    ),
    { resubscribeOnError: false },
  );

  loadNewExplanationsPage$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadNewExplanationsPage),
      concatMap(action => of(action).pipe(withLatestFrom(
        this.store.pipe(select(getAuthState)),
        this.store.pipe(select(getExplanationsState)),
      ))),
      mergeMap(([, authState, explanationState]) => {
        const index = explanationState.previewsMaxIndex;
        return this.explanationService.getExplanations(index).pipe(
          map((previews) => {
            const explanationPreviews = previews.map((p) => {
              if (p.reference instanceof firestore.DocumentReference) {
                p.reference = p.reference.path;
              }
              return p;
            });
            explanationPreviews.reverse();
            return loadNewExplanationsPageSuccess({ explanationPreviews, index });
          }),
          catchError((error) => {
            if (authState && authState.user) {
              return of(loadNewExplanationsPageFail({ error }));
            }
            return of(cancelledRequest());
          }),
        );
      }),
    ),
    { resubscribeOnError: false },
  );

  loadExplanationsCount$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadExplanationsCount),
      concatMap(action => of(action).pipe(withLatestFrom(
        this.store.pipe(select(getAuthState)),
      ))),
      mergeMap(([, authState]) => {
        return this.explanationService.getExplanationsSize().pipe(
          map((explanationsTotalLength) => {
            const paginationVal = environment.paginateVal;
            const explanationsMaxIndex = Math.floor((explanationsTotalLength - 1) / paginationVal);
            return loadExplanationsCountSuccess({ explanationsTotalLength, explanationsMaxIndex });
          }),
          catchError((error) => {
            if (authState && authState.user) {
              return of(loadExplanationsFail({ error }));
            }
            return of(cancelledRequest());
          }),
        );
      }),
    ),
    { resubscribeOnError: false },
  );

  openExplanationForm$ = createEffect(() => this.actions$.pipe(
    ofType(openExplanationForm),
    mergeMap((action) => {
      if (action.preview === undefined) {
        const dialogRef = this.dialog.open(ExplanationFormComponent, {
          width: '1240px',
          disableClose: true,
        });
        dialogRef.beforeClosed().subscribe(() => {
          this.helperService.navigateBack();
        });
        return of(openExplanationFormSuccess({ explanation: undefined }));
      }
      const reference = typeof action.preview === 'string'
        ? `${environment.projectId}/explanations/${action.preview}`
        : action.preview.reference
      ;
      return this.explanationService.getExplanation(reference).pipe(
        take(1),
        map((explanation: Explanation) => {
          explanation.id = this.explanationService.getExplanationId(reference);
          const dialogRef = this.dialog.open(ExplanationFormComponent, {
            width: '1240px',
            disableClose: true,
          });
          dialogRef.beforeClosed().subscribe(() => {
            this.helperService.navigateBack();
          });
          return openExplanationFormSuccess({ explanation });
        }),
        catchError((error) => {
          return of(openExplanationFormFail({ error }));
        }),
      );
    }),
  ));

  saveExplanation$ = createEffect(() => this.actions$.pipe(
    ofType(saveExplanation),
    mergeMap((action) => {
      if (action.imagesToDelete.length > 0) {
        action.imagesToDelete.forEach((image) => {
          this.imageService.deleteImage(image.name, 'explanations');
        });
      }
      return this.explanationService.saveExplanation(action.explanation).pipe(
        map((id) => {
          return saveExplanationSuccess({ id });
        }),
        catchError((error) => {
          return of(saveExplanationFail({ error }));
        }),
      );
    }),
  ));

  saveExplanationWithImages$ = createEffect(() => this.actions$.pipe(
    ofType(saveExplanationWithImages),
    mergeMap((action) => {
      const explToSave: Explanation = _.cloneDeep(action.explanation);
      if (action.imagesToDelete.length > 0) {
        action.imagesToDelete.forEach((image) => {
          this.imageService.deleteImage(image.name, 'explanations');
        });
      }
      const observables: Observable<UploadTaskSnapshot>[] = action.imagesToUpload.map((img) => {
        return from(this.imageService.uploadImage(img, 'explanations'));
      });
      return forkJoin(observables).pipe(
        mergeMap((imageResults: UploadTaskSnapshot[]) => {
          let imageResIndex = 0;
          explToSave.type.forEach((t, i) => {
            if (t === 'preview') {
              explToSave.data[i] = imageResults[imageResIndex].metadata.name;
              explToSave.type[i] = 'img';
              imageResIndex = imageResIndex + 1;
            }
          });
          return from(this.explanationService.saveExplanation(explToSave)).pipe(
            map(() => {
              return saveExplanationWithImagesSuccess({ id: explToSave.id });
            }),
            catchError((error) => {
              return of(saveExplanationWithImagesFail({ error }));
            }),
          );
        }),
        catchError((error) => {
          return of(saveExplanationWithImagesFail({ error }));
        }),
      );
    }),
  ));

  explanationSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(saveExplanationSuccess, saveExplanationWithImagesSuccess),
    mergeMap((action) => {
      return of(showAlert(
        {
          toast: true,
          position: 'bottom-end',
          showConfirm: false,
          showCancel: false,
          timer: 10000,
          alertType: 'success',
          title: `Explicación con ID ${action.id} correctamente guardada.`,
        },
      ));
    }),
  ));

  explanationsErrors$ = createEffect(() => this.actions$.pipe(
    ofType(loadExplanationsFail, loadExplanationsCountFail, openExplanationFormFail, saveExplanationFail, saveExplanationWithImagesFail),
    mergeMap((action) => {
      return of(showAlert({ alertType: 'error', error: action.error, title: 'Oops...' }));
    }),
  ));

  explanationsWarns$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadExplanationsWarn),
      mergeMap((action) => {
        console.warn(action.warning);
        return of(undefined);
      }),
    ),
    { dispatch: false },
  );

}
