import {
  checkUserStatus, checkUserStatusSuccess,
  loginAttempt,
  loginAttemptFail,
  loginAttemptSuccess,
  logoutAttempt,
  logoutAttemptFail,
  logoutAttemptSuccess, openEditUserForm, openEditUserFormFail, openEditUserFormSuccess, saveUser, saveUserFail, saveUserSuccess,
} from './authentication.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { AuthenticatedUserState } from './authentication.interfaces';

const authUserInitialState: AuthenticatedUserState = {
  user: undefined,
  appPermits: undefined,
  updated: false,
  loading: false,
};

const authenticationLocalReducer = createReducer(
  authUserInitialState,
  on(loginAttempt, openEditUserForm, saveUser, (state: AuthenticatedUserState) => {
    const newState: AuthenticatedUserState = {
      ...state,
      updated: false,
      loading: true,
    };
    return newState;
  }),
  on(openEditUserFormSuccess, (state:AuthenticatedUserState) => {
    const newState: AuthenticatedUserState = {
      ...state,
      loading: false,
    };
    return newState;
  }),
  on(saveUserSuccess, (state:AuthenticatedUserState) => {
    const newState: AuthenticatedUserState = {
      ...state,
      updated: true,
      loading: false,
    };
    return newState;
  }),
  on(loginAttemptSuccess, (state: AuthenticatedUserState, { user, appPermits }) => {
    const newState: AuthenticatedUserState = {
      ...state,
      user,
      appPermits,
      loading: false,
    };
    return newState;
  }),
  on(checkUserStatus, (state: AuthenticatedUserState) => {
    const newState: AuthenticatedUserState = {
      ...state,
      loading: true,
    };
    return newState;
  }),
  on(checkUserStatusSuccess, (state: AuthenticatedUserState, { user, appPermits }) => {
    const newState: AuthenticatedUserState = {
      ...state,
      user,
      appPermits,
      updated: false,
      loading: false,
    };
    return newState;
  }),
  on(logoutAttempt, (state: AuthenticatedUserState) => {
    const newState: AuthenticatedUserState = {
      ...state,
      loading: true,
    };
    return newState;
  }),
  on(logoutAttemptSuccess, (state: AuthenticatedUserState) => {
    const newState: AuthenticatedUserState = {
      user: undefined,
      appPermits: undefined,
      error: undefined,
      updated: false,
      loading: false,
    };
    return newState;
  }),
  on(logoutAttemptFail, loginAttemptFail, openEditUserFormFail, saveUserFail, (state: AuthenticatedUserState, { error }) => {
    const newState: AuthenticatedUserState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
);

export function clearStateOnLogoutReducer (reducer) {
  return function (state, action) {
    let newState = state;
    if (action.type === logoutAttemptSuccess.type) {
      newState = {
        auth: authUserInitialState,
      };
    }
    return reducer(newState, action);
  };
}

export function authenticationReducer (state: AuthenticatedUserState | undefined, action: Action) {
  return authenticationLocalReducer(state, action);
}
