import { Timestamp } from '@firebase/firestore-types';
import { DocumentReference } from '@angular/fire/firestore';

export interface UserState {
  users: UsersState;
  selectedUser: SelectedUserState;
}

export interface UsersState {
  previews: EditorUserPreview[];
  previewsTotalCount: number;
  loading: boolean;
  error?: any;
}

export interface SelectedUserState {
  user: EditorUser;
  loading: boolean;
  error?: any;
}

export interface EditorUserPreview {
  created: Timestamp;
  roles: string[];
  email: string;
  reference: DocumentReference | string;
  loading?: boolean;
}

export interface EditorUser {
  id?: string;
  created: Timestamp;
  email: string;
  roles: Roles;
  newToOld: boolean;
  password?: string;
}

export const rolesKeys = [
  { value: 'editor', text: 'Editor' },
  { value: 'approver', text: 'Aprobador' },
  { value: 'admin', text: 'Manejador de usuarios' },
];

export interface Roles {
  editor?: boolean;
  approver?: boolean;
  admin?: boolean;
}
