import { TopicState } from '../topics/topics.interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import { DashboardState } from './dashboard.interfaces';
import { changeActiveTab } from './dashboard.actions';

const dashboardInitalState: DashboardState = {
  activeTab: 'Questions',
};

const dashboardLocalReducer = createReducer(
  dashboardInitalState,
  on(changeActiveTab, (state: DashboardState, { tab }) => {
    const newState: DashboardState = {
      ...state,
      activeTab: tab,
    };
    return newState;
  }),
);

export function dashboardReducer (state: DashboardState | undefined, action: Action) {
  return dashboardLocalReducer(state, action);
}
