import { createAction, props } from '@ngrx/store';
import { EditorUser, EditorUserPreview } from './editorUsers.interfaces';

export const cancelledRequest = createAction(
  '[Users] Cancelled request',
);

export const loadUsers = createAction(
  '[Users] Load users',
);
export const loadUsersSuccess = createAction(
  '[Users] Load users success',
  props<{ userPreviews: EditorUserPreview[], total: number }>(),
);
export const loadUsersFail = createAction(
  '[Users] Load users failure',
  props<{ error: any }>(),
);

export const openEditUserForm = createAction(
  '[Users] Open edit user form',
  props<{ userPreview: EditorUserPreview }>(),
);
export const openEditUserFormSuccess = createAction(
  '[Users] Open edit user form success',
  props<{ user: EditorUser }>(),
);
export const openEditUserFormFail = createAction(
  '[Users] Open edit user form failure',
  props<{ error: any }>(),
);

export const saveUser = createAction(
  '[Users] Save user',
  props<{ user: EditorUser }>(),
);
export const saveUserSuccess = createAction(
  '[Users] Save user success',
  props<{ user: EditorUser }>(),
);
export const saveUserFail = createAction(
  '[Users] Save user failure',
  props<{ error: any }>(),
);

export const deleteUser = createAction(
  '[Users] Delete user',
  props<{ user: EditorUserPreview }>(),
);
export const deleteUserSuccess = createAction(
  '[Users] Delete user success',
  props<{ user: EditorUserPreview }>(),
);
export const deleteUserFail = createAction(
  '[Users] Delete user failure',
  props<{ error: any }>(),
);
