<section class="container">
  <div class="hero is-light is-bold">
    <div class="hero-body">
      <div class="container has-text-centered">
        <h1 class="title">Bienvenido al editor de Estudio Medicina</h1>
        <h2 class="subtitle">Ingresa para continuar</h2>
        <form [formGroup]="loginForm" autocomplete="off">
          <mat-form-field>
            <mat-icon matSuffix>contact_mail</mat-icon>
            <input matInput placeholder="Email" formControlName="email" type="text" autocomplete="email">
            <mat-error *ngIf="loginForm.get('email').invalid && loginForm.get('email').errors?.required">
              El campo email es obligatorio.
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-icon matSuffix>lock</mat-icon>
            <input matInput placeholder="Contraseña" formControlName="pass" type="password" autocomplete="password">
            <mat-error *ngIf="loginForm.get('pass').invalid && loginForm.get('pass').errors?.required">
              El campo contraseña es obligatorio.
            </mat-error>
          </mat-form-field>
          <div class="actionButtons">
            <button mat-raised-button color="primary" [ngClass]="{ 'button is-loading': loading }" (click)="login()">Ingresar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
