import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';
import { User } from 'firebase';
import { EditorUser } from '../../core/ngrx/editorUsers/editorUsers.interfaces';
import { Question, QuestionPreview } from '../../core/ngrx/questions/questions.interfaces';
import { QuestionValidationFunction } from '../../core/ngrx/authentication/authentication.interfaces';
import { firestore } from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private readonly user$: Observable<EditorUser>;
  private userId: string;

  constructor (private angularFireAuth: AngularFireAuth, private fireStoreDB: AngularFirestore) {
    this.user$ = this.angularFireAuth.authState.pipe(switchMap(
      (user: User) => {
        if (user) {
          this.userId = user.uid;
          return this.fireStoreDB.doc<EditorUser>(`editorUsers/${user.uid}`).valueChanges();
        }
        return of(null);
      },
    ));
  }

  loginWithEmail (email: string, pass: string) {
    return this.angularFireAuth.auth.signInWithEmailAndPassword(email, pass);
  }

  getUser (): Observable<EditorUser> {
    return this.user$;
  }

  getUserReference (): firestore.DocumentReference {
    return this.fireStoreDB.doc<EditorUser>(`editorUsers/${this.userId}`).ref;
  }

  logOut () {
    return this.angularFireAuth.auth.signOut();
  }

  canCreateQuestions (user: EditorUser): boolean {
    return this.isEditor(user);
  }

  canViewQuestion (user: EditorUser): QuestionValidationFunction {
    return (ques: QuestionPreview | Question) => this.isApprover(user) || (this.isEditor(user) && ques.status === 'published');
  }

  canEditQuestion (user: EditorUser): QuestionValidationFunction {
    const isBothRoles = this.isEditor(user) && this.isApprover(user);
    const isEditorAndQues = (ques: QuestionPreview | Question) => {
      return this.isEditor(user) && (ques.status === 'created' || ques.status === 'unpublished');
    };
    return (ques: QuestionPreview | Question) => isBothRoles || isEditorAndQues(ques);
  }

  canPublishQuestion (user: EditorUser): QuestionValidationFunction {
    return (ques: QuestionPreview | Question) => this.isApprover(user) && ques.status === 'created';
  }

  canUnPublishQuestion (user: EditorUser): QuestionValidationFunction {
    return (ques: QuestionPreview | Question) => this.isApprover(user) && ques.status === 'published';
  }

  canRePublishQuestion (user: EditorUser): QuestionValidationFunction {
    return (ques: QuestionPreview | Question) => this.isApprover(user) && ques.status === 'unpublished';
  }

  canDeleteQuestion (user: EditorUser): boolean {
    return this.isEditor(user) && this.isApprover(user);
  }

  isEditor (user: EditorUser): boolean {
    const allowed = ['editor'];
    return this.checkAuthorization(user, allowed);
  }

  isApprover (user: EditorUser): boolean {
    const allowed = ['approver'];
    return this.checkAuthorization(user, allowed);
  }

  isAdmin (user: EditorUser): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(user, allowed);
  }

  private checkAuthorization (user: EditorUser, allowedRoles: string[]): boolean {
    if (!user) return false;
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }

}
