<div class="container">
  <mat-card>
    <form class="filter-bar columns is-centered is-vcentered is-gapless" autocomplete="off">
      <div class="column is-6">
        <button mat-raised-button color="accent" (click)="openUserForm()">Nuevo Usuario</button>
      </div>
      <div class="column is-6" *ngIf="usersPreviews" >
        <mat-paginator [length]="usersPreviewsLength" [pageSize]="defaultMatPaginatorValue"
                       [pageSizeOptions]="[2, 5, 10, 20, 50]" (page)="changedPage($event)" [disabled]="isLoading"></mat-paginator>
      </div>
    </form>
  </mat-card>
  <ng-container *ngIf="!isLoading">
    <div class="columns">
      <div class="column is-4 is-flex flex-items-center flex-start">
        <h3 class="has-text-weight-bold">Usuario</h3>
      </div>
      <div class="column is-3 has-text-weight-bold is-flex flex-items-center flex-center">Permisos</div>
      <div class="column is-3 has-text-weight-bold is-flex flex-items-center flex-center">Fecha de creación</div>
      <div class="column is-2 has-text-weight-bold is-flex flex-items-center flex-end">Acciones</div>
    </div>
    <div class="columns text-small" *ngFor="let user of usersPreviewsFiltered">
      <div class="column is-4 is-flex flex-items-center flex-start">
        <mat-icon class="margin-right-small">person</mat-icon>
        <h3>{{ user.email }}</h3>
      </div>
      <div class="column is-3 is-flex flex-items-center flex-center">
        <ng-container *ngFor="let role of user.roles; let i = index">
          <span *ngIf="i">,&nbsp;</span>{{ getRole(role) }}
        </ng-container>
      </div>
      <div class="column is-3 is-flex flex-items-center flex-center">
        {{ user.created.toDate().toLocaleDateString('es-MX', localeDateOptions) }}
      </div>
      <div class="column is-2 is-flex flex-items-center flex-end">
        <ng-container *ngIf="user.email !== currentUser.email">
          <button [ngClass]="{'button is-loading': user.loading}" mat-raised-button color="primary"
                  (click)="openUserForm(user)" title="Editar">
            <mat-icon>edit</mat-icon>
          </button>
          <button [ngClass]="{'button is-loading': user.loading}" mat-raised-button color="warn"
                  (click)="openUserDeletionDialog(user)" title="Eliminar">
            <mat-icon>delete</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>
