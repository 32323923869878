import { Description, DescriptionsState, DescriptionState, SelectedDescriptionState } from './descriptions.interfaces';
import { Action, ActionReducerMap, createReducer, on } from '@ngrx/store';
import {
  loadDescriptions,
  loadDescriptionsFail,
  loadDescriptionsSuccess,
  loadDescriptionsCount,
  loadDescriptionsCountSuccess,
  loadDescriptionsCountFail,
  saveDescription,
  saveDescriptionSuccess,
  saveDescriptionFail,
  openDescriptionForm,
  openDescriptionFormSuccess,
  openDescriptionFormFail,
  saveDescriptionWithImagesFail,
  saveDescriptionWithImagesSuccess,
  saveDescriptionWithImages,
  resetSelectedDescription,
  loadNewDescriptionsPage,
  loadNewDescriptionsPageSuccess,
  loadNewDescriptionsPageFail,
  changeDescriptionsPaginationValue,
} from './descriptions.actions';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { PaginationValue } from '../dataTypes/dataTypes.interfaces';
import { isNullOrUndefined } from 'util';

const descriptionsInitialState: DescriptionsState = {
  pagination: { activeIndex: 0, pageValue: environment.defaultPaginatorValue },
  previews: [],
  previewsPages: [],
  previewsTotalCount: 0,
  previewsIndexLoaded: -1,
  previewsMaxIndex: -1,
  loading: false,
};

const descriptionInitialState: SelectedDescriptionState = {
  description: undefined,
  uploadingImages: false,
  loading: false,
};

const descriptionsLocalReducer = createReducer(
  descriptionsInitialState,
  on(changeDescriptionsPaginationValue, (state: DescriptionsState, { newIndex, newPagValue }) => {
    const newPaginationVal: PaginationValue = { ...state.pagination };
    if (!isNullOrUndefined(newIndex)) {
      newPaginationVal.activeIndex = newIndex;
    }
    if (!isNullOrUndefined(newPagValue)) {
      newPaginationVal.pageValue = newPagValue;
    }
    const newState: DescriptionsState = {
      ...state,
      pagination: newPaginationVal,
    };
    return newState;
  }),
  on(loadDescriptions, (state: DescriptionsState) => {
    const newState: DescriptionsState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(loadDescriptionsSuccess, (state: DescriptionsState, { descriptionPreviews, index }) => {
    const newState: DescriptionsState = {
      ...state,
      loading: false,
      error: undefined,
    };
    if (index > state.previewsIndexLoaded) {
      newState.previewsPages = state.previewsPages.concat([descriptionPreviews]);
      newState.previewsIndexLoaded = index;
    } else {
      newState.previewsPages = state.previewsPages.map((p, i) => {
        if (i === index) {
          return descriptionPreviews;
        }
        return p;
      });
    }
    newState.previews = _.flattenDepth(newState.previewsPages, 1);
    return newState;
  }),
  on(loadDescriptionsFail, (state: DescriptionsState, { error }) => {
    const newState: DescriptionsState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
  on(loadNewDescriptionsPage, (state: DescriptionsState) => {
    const newState: DescriptionsState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(loadNewDescriptionsPageSuccess, (state: DescriptionsState, { descriptionPreviews, index }) => {
    const newState: DescriptionsState = {
      ...state,
      loading: false,
      error: undefined,
    };
    newState.previewsPages = [descriptionPreviews].concat(state.previewsPages);
    newState.previewsIndexLoaded = state.previewsIndexLoaded + 1;
    newState.previews = _.flattenDepth(newState.previewsPages, 1);
    return newState;
  }),
  on(loadNewDescriptionsPageFail, (state: DescriptionsState, { error }) => {
    const newState: DescriptionsState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
  on(loadDescriptionsCount, (state: DescriptionsState) => {
    const newState: DescriptionsState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(loadDescriptionsCountSuccess, (state: DescriptionsState, { descriptionsTotalLength, descriptionsMaxIndex }) => {
    const newState: DescriptionsState = {
      ...state,
      previewsTotalCount: descriptionsTotalLength,
      previewsMaxIndex: descriptionsMaxIndex,
      loading: false,
      error: undefined,
    };
    return newState;
  }),
  on(loadDescriptionsCountFail, (state: DescriptionsState, { error }) => {
    const newState: DescriptionsState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
);

const descriptionLocalReducer = createReducer(
  descriptionInitialState,
  on(resetSelectedDescription, (state: SelectedDescriptionState) => {
    const newState: SelectedDescriptionState = {
      ...state,
      description: undefined,
      loading: false,
      error: undefined,
    };
    return newState;
  }),
  on(openDescriptionForm, (state: SelectedDescriptionState) => {
    const newState: SelectedDescriptionState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(openDescriptionFormSuccess, (state: SelectedDescriptionState, { description }) => {
    const newState: SelectedDescriptionState = {
      ...state,
      description,
      loading: false,
      error: undefined,
    };
    return newState;
  }),
  on(openDescriptionFormFail, (state: SelectedDescriptionState, { error }) => {
    const newState: SelectedDescriptionState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
  on(saveDescription, (state: SelectedDescriptionState, { description }) => {
    const newState: SelectedDescriptionState = {
      ...state,
      description,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(saveDescriptionSuccess, (state: SelectedDescriptionState, { id }) => {
    const description: Description = {
      ...state.description,
      id,
    };
    const newState: SelectedDescriptionState = {
      ...state,
      description,
      loading: false,
      error: undefined,
    };
    return newState;
  }),
  on(saveDescriptionFail, (state: SelectedDescriptionState, { error }) => {
    const newState: SelectedDescriptionState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
  on(saveDescriptionWithImages, (state: SelectedDescriptionState, { description }) => {
    const newState: SelectedDescriptionState = {
      ...state,
      description,
      uploadingImages: true,
      error: undefined,
    };
    return newState;
  }),
  on(saveDescriptionWithImagesSuccess, (state: SelectedDescriptionState) => {
    const newState: SelectedDescriptionState = {
      ...state,
      uploadingImages: false,
      error: undefined,
    };
    return newState;
  }),
  on(saveDescriptionWithImagesFail, (state: SelectedDescriptionState, { error }) => {
    const newState: SelectedDescriptionState = {
      ...state,
      error,
      uploadingImages: false,
    };
    return newState;
  }),
);

export function descriptionsReducer (state: DescriptionsState | undefined, action: Action) {
  return descriptionsLocalReducer(state, action);
}

export function selectedDescriptionReducer (state: SelectedDescriptionState | undefined, action: Action) {
  return descriptionLocalReducer(state, action);
}

export const descriptionReducer: ActionReducerMap<DescriptionState> = {
  selectedDescription: selectedDescriptionReducer,
  descriptions: descriptionsReducer,
};
