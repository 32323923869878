import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { from, of } from 'rxjs';
import {
  checkUserStatus,
  checkUserStatusSuccess,
  loginAttempt,
  loginAttemptFail,
  loginAttemptSuccess,
  logoutAttempt,
  logoutAttemptFail,
  logoutAttemptSuccess,
  openEditUserForm,
  openEditUserFormFail,
  openEditUserFormSuccess,
  saveUser,
  saveUserFail,
  saveUserSuccess,
} from './authentication.actions';
import { AuthService } from '../../../services/Auth/auth.service';
import { showAlert } from '../alerts/alerts.actions';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';
import { AppPemits } from './authentication.interfaces';
import { UserFormComponent } from '../../../components/user-components/user-form/user-form.component';
import { EditorUser } from '../editorUsers/editorUsers.interfaces';
import { UserService } from '../../../services/User/user.service';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';

@Injectable() export class AuthenticationEffects {

  constructor (
    private store: Store<any>,
    private actions$: Actions,
    private authService: AuthService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  authenticationAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(loginAttempt),
    mergeMap((action) => {
      return from(this.authService.loginWithEmail(action.user, action.password)).pipe(
        take(1),
        mergeMap((user: any) => {
          return this.authService.getUser().pipe(
            take(1),
            map((editorUser) => {
              if (!isNullOrUndefined(editorUser)) {
                const appPermits: AppPemits = {
                  canCreateQuestions: this.authService.canCreateQuestions(editorUser),
                  canViewQuestion: this.authService.canViewQuestion(editorUser),
                  canEditQuestion: this.authService.canEditQuestion(editorUser),
                  canPublishQuestion: this.authService.canPublishQuestion(editorUser),
                  canUnPublishQuestion: this.authService.canUnPublishQuestion(editorUser),
                  canRePublishQuestion: this.authService.canRePublishQuestion(editorUser),
                  canDeleteQuestion: this.authService.canDeleteQuestion(editorUser),
                };
                return loginAttemptSuccess({ appPermits, user: editorUser });
              }
              return loginAttemptFail({ error: {} });
            }),
          );
        }),
        catchError((error) => {
          return of(loginAttemptFail({ error }));
        }),
      );
    }),
  ));

  openEditUserForm$ = createEffect(() => this.actions$.pipe(
    ofType(openEditUserForm),
    mergeMap((action) => {
      const userReference = this.authService.getUserReference();
      return this.userService.getUser(userReference).pipe(
        take(1),
        map((user: EditorUser) => {
          user.id = this.userService.getUserId(userReference);
          this.dialog.open(UserFormComponent, {
            width: '1110px',
            disableClose: true,
            data: {
              user,
              isSelf: true,
            },
          });
          return openEditUserFormSuccess();
        }),
        catchError((error) => {
          return of(openEditUserFormFail({ error }));
        }),
      );
    }),
  ));

  saveUser$ = createEffect(() => this.actions$.pipe(
    ofType(saveUser),
    mergeMap((action) => {
      return from(this.userService.saveUser(action.user)).pipe(
        map(() => {
          const msg = 'Para evitar errores de visualización es recomendado cerrar e iniciar sesión nuevamente.';
          this.store.dispatch(showAlert({ alertType: 'info', title: 'Atención', message: msg }));
          return saveUserSuccess();
        }),
        catchError((error) => {
          return of(saveUserFail({ error }));
        }),
      );
    }),
  ));

  authenticationAttemptSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(loginAttemptSuccess),
      mergeMap(() => {
        return this.router.navigateByUrl('/editor');
      }),
    ),
    { dispatch: false },
  );

  checkUserStatus$ = createEffect(() => this.actions$.pipe(
    ofType(checkUserStatus),
    mergeMap((action) => {
      return this.authService.getUser().pipe(
        take(1),
        map((user) => {
          if (!isNullOrUndefined(user)) {
            const appPermits: AppPemits = {
              canCreateQuestions: this.authService.canCreateQuestions(user),
              canViewQuestion: this.authService.canViewQuestion(user),
              canEditQuestion: this.authService.canEditQuestion(user),
              canPublishQuestion: this.authService.canPublishQuestion(user),
              canUnPublishQuestion: this.authService.canUnPublishQuestion(user),
              canRePublishQuestion: this.authService.canRePublishQuestion(user),
              canDeleteQuestion: this.authService.canDeleteQuestion(user),
            };
            return checkUserStatusSuccess({ user, appPermits });
          }
          return checkUserStatusSuccess({ user: undefined, appPermits: undefined });
        }),
        catchError((error) => {
          return of(checkUserStatusSuccess({ user: undefined, appPermits: undefined }));
        }),
      );
    }),
  ));

  logoutAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(logoutAttempt),
    mergeMap(() => {
      return from(this.authService.logOut()).pipe(
        take(1),
        map(() => {
          return logoutAttemptSuccess();
        }),
        catchError((error) => {
          return of(checkUserStatusSuccess({ user: undefined, appPermits: undefined }));
        }),
      );
    }),
  ));

  logoutAttemptSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(logoutAttemptSuccess),
      mergeMap(() => {
        return this.router.navigateByUrl('/login');
      }),
    ),
    { dispatch: false },
  );

  authenticationErrors$ = createEffect(() => this.actions$.pipe(
    ofType(loginAttemptFail, logoutAttemptFail),
    mergeMap((action) => {
      return of(showAlert({ alertType: 'error', error: action.error, title: 'Oops...' }));
    }),
  ));

}
