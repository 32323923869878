import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  cancelledRequest, changeQuestionsPaginationValue, changeQuestionsPaginationValueFail, changeQuestionsPaginationValueSuccess,
  deactivateQuestion, deactivateQuestionFail, deactivateQuestionSuccess, deleteQuestion, deleteQuestionFail, deleteQuestionSuccess,
  loadNewQuestionsPage,
  loadNewQuestionsPageFail,
  loadNewQuestionsPageSuccess,
  loadQuestions,
  loadQuestionsCount,
  loadQuestionsCountFail,
  loadQuestionsCountSuccess,
  loadQuestionsFail,
  loadQuestionsSuccess,
  openQuestionForm,
  openQuestionFormFail,
  openQuestionFormSuccess,
  openQuestionView,
  openQuestionViewFail,
  openQuestionViewSuccess,
  publishQuestion,
  publishQuestionFail, publishQuestionSuccess,
  reactivateQuestion, reactivateQuestionFail, reactivateQuestionSuccess,
  saveQuestion,
  saveQuestionFail,
  saveQuestionSuccess,
  saveQuestionWithImages,
  saveQuestionWithImagesFail,
  saveQuestionWithImagesSuccess,
} from './questions.actions';
import { catchError, map, mergeMap, take, concatMap } from 'rxjs/operators';
import { QuestionService } from '../../../services/Question/question.service';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { showAlert } from '../alerts/alerts.actions';
import { firestore } from 'firebase/app';
import { Store } from '@ngrx/store';
import { getAuthState } from '../authentication/authentication.selectors';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material';
import { QuestionFormComponent } from '../../../components/question-components/question-form/question-form.component';
import { Question, QuestionCount, Status } from './questions.interfaces';
import { ImageService } from '../../../services/Image/image.service';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { getQuestionsState } from './questions.selectors';
import { QuestionViewComponent } from '../../../components/question-components/question-view/question-view.component';
import { HelperService } from '../../../services/Helper/helper.service';

@Injectable() export class QuestionsEffects {

  constructor (
    private store: Store<any>,
    private actions$: Actions,
    private imageService: ImageService,
    private helperService: HelperService,
    private questionService: QuestionService,
    private fireStoreDB: AngularFirestore,
    private dialog: MatDialog,
  ) {}

  loadQuestions$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadQuestions),
      mergeMap((action) => {
        const observables = combineLatest(
          [
            this.store.select(getAuthState).pipe(take(1)),
            this.store.select(getQuestionsState).pipe(take(1)),
          ],
        );
        return observables.pipe(mergeMap(([authState, questionState]) => {
          const index = action.paginateVal;
          let indexCorrected = index;
          if (authState.user.newToOld) {
            indexCorrected = questionState.previewsMaxIndex;
            for (let i = 1; i <= index; i += 1) {
              indexCorrected -= 1;
            }
          }
          return this.questionService.getQuestions(indexCorrected).pipe(
            map((previews) => {
              const questionPreviews = previews.map((p) => {
                if (p.reference instanceof firestore.DocumentReference) {
                  p.reference = p.reference.path;
                }
                return p;
              });
              if (authState.user.newToOld) {
                questionPreviews.reverse();
              }
              return loadQuestionsSuccess({ questionPreviews, index });
            }),
            catchError((error) => {
              return this.store.select(getAuthState).pipe(take(1), map((authState) => {
                if (authState && authState.user) {
                  return loadQuestionsFail({ error });
                }
                return cancelledRequest();
              }));
            }),
          );
        }));
      }),
    ),
    { resubscribeOnError: false },
  );

  loadNewQuestionsPage$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadNewQuestionsPage),
      mergeMap((action) => {
        return this.store.select(getQuestionsState).pipe(take(1)).pipe(mergeMap((questionState) => {
          const index = questionState.previewsMaxIndex;
          return this.questionService.getQuestions(index).pipe(
            map((previews) => {
              const questionPreviews = previews.map((p) => {
                if (p.reference instanceof firestore.DocumentReference) {
                  p.reference = p.reference.path;
                }
                return p;
              });
              questionPreviews.reverse();
              return loadNewQuestionsPageSuccess({ questionPreviews, index });
            }),
            catchError((error) => {
              return this.store.select(getAuthState).pipe(take(1), map((authState) => {
                if (authState && authState.user) {
                  return loadNewQuestionsPageFail({ error });
                }
                return cancelledRequest();
              }));
            }),
          );
        }));
      }),
    ),
    { resubscribeOnError: false },
  );

  loadQuestionsCount$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadQuestionsCount),
      mergeMap(() => {
        return this.questionService.getQuestionsCounters().pipe(
          map((questionCountObject) => {
            const paginationVal = environment.paginateVal;
            const countObj = _.cloneDeep(questionCountObject);
            countObj.allWithDeleted = { ...countObj.all };
            countObj.all.total -= countObj.deleted.total;
            // tslint:disable-next-line:no-parameter-reassignment
            countObj.all.pagination.forEach((p, i) => p -= countObj.deleted.pagination[i]);
            const questionsTotalLength = countObj.allWithDeleted.total;
            const questionsMaxIndex = Math.floor((questionsTotalLength - 1) / paginationVal);
            return loadQuestionsCountSuccess({ questionsMaxIndex, questionCountObject: countObj });
          }),
          catchError((error) => {
            return this.store.select(getAuthState).pipe(take(1), map((authState) => {
              if (authState && authState.user) {
                return loadQuestionsFail({ error });
              }
              return cancelledRequest();
            }));
          }),
        );
      }),
    ),
    { resubscribeOnError: false },
  );

  changeQuestionsPaginationValue$ = createEffect(
    () => this.actions$.pipe(
      ofType(changeQuestionsPaginationValue),
      mergeMap((action) => {
        // const lastPageIndexRequired = ((action.newIndex + 1) * action.newPagValue) - 1;
        const observables = combineLatest(
          [
            this.store.select(getAuthState).pipe(take(1)),
            this.store.select(getQuestionsState).pipe(take(1)),
          ],
        );
        return observables.pipe(
          mergeMap(([authState, qState]) => {
            // let paginationIndexRequired = Math.trunc((lastPageIndexRequired) / environment.paginateVal);
            const paginationIndexRequired =
              this.generateNextPaginationIndex(action.status, qState.counters, action.newPagValue, action.newIndex, authState.user.newToOld)
            ;
            /*if (authState.user.newToOld) {
              const counterSelector: Status | 'allWithDeleted' = action.status === '' ? 'allWithDeleted' : action.status;
              const lastPageSize = qState.counters[counterSelector].total % environment.paginateVal;
              if (lastPageIndexRequired % environment.paginateVal > lastPageSize) {
                paginationIndexRequired += 1;
              }
            }*/
            const indexesToLoad: number[] = [];
            for (let i = qState.previewsIndexLoaded + 1; i <= paginationIndexRequired && i <= qState.previewsMaxIndex; i = i + 1) {
              indexesToLoad.push(i);
            }
            if (indexesToLoad.length === 0) {
              return of(changeQuestionsPaginationValueSuccess(
                { status:action.status, newIndex: action.newIndex, newPagValue: action.newPagValue },
              ));
            }
            return forkJoin([from(indexesToLoad).pipe(
              concatMap((index) => {
                return of(this.store.dispatch(loadQuestions({ paginateVal: index })));
              }),
            )]).pipe(
              map(() => {
                return changeQuestionsPaginationValueSuccess(
                  { status:action.status, newIndex: action.newIndex, newPagValue: action.newPagValue },
                );
              }),
              catchError((error) => {
                return of(changeQuestionsPaginationValueFail({ error }));
              }),
            );
          }),
        );
      }),
    ),
    { resubscribeOnError: false },
  );

  openQuestionForm$ = createEffect(() => this.actions$.pipe(
    ofType(openQuestionForm),
    mergeMap((action) => {
      if (action.preview === undefined) {
        const dialogRef = this.dialog.open(QuestionFormComponent, {
          width: '1240px',
          disableClose: true,
          data: { origin: undefined },
        });
        dialogRef.beforeClosed().subscribe(() => {
          this.helperService.navigateBack();
        });
        return of(openQuestionFormSuccess({ question: undefined }));
      }
      const reference = typeof action.preview === 'string'
        ? `${environment.projectId}/questions/${action.preview}`
        : action.preview.reference
      ;
      return this.questionService.getQuestion(reference).pipe(
        take(1),
        map((question: Question) => {
          if (question.descriptionReference && question.descriptionReference instanceof firestore.DocumentReference) {
            question.descriptionReference = question.descriptionReference.path;
          }
          if (question.explanationReference && question.explanationReference instanceof firestore.DocumentReference) {
            question.explanationReference = question.explanationReference.path;
          }
          question.id = this.questionService.getQuestionId(reference);
          const dialogRef = this.dialog.open(QuestionFormComponent, {
            width: '1240px',
            disableClose: true,
            data: { origin: typeof action.preview === 'string' ? 'isSingleForm' : undefined },
          });
          dialogRef.beforeClosed().subscribe(() => {
            this.helperService.navigateBack();
          });
          return openQuestionFormSuccess({ question });
        }),
        catchError((error) => {
          return of(openQuestionFormFail({ error }));
        }),
      );
    }),
  ));

  openQuestionView$ = createEffect(() => this.actions$.pipe(
    ofType(openQuestionView),
    mergeMap((action) => {
      const reference = typeof action.preview === 'string'
        ? `${environment.projectId}/questions/${action.preview}`
        : action.preview.reference
      ;
      return this.questionService.getQuestion(reference).pipe(
        take(1),
        map((question: Question) => {
          if (question.descriptionReference && question.descriptionReference instanceof firestore.DocumentReference) {
            question.descriptionReference = question.descriptionReference.path;
          }
          if (question.explanationReference && question.explanationReference instanceof firestore.DocumentReference) {
            question.explanationReference = question.explanationReference.path;
          }
          question.id = this.questionService.getQuestionId(reference);
          const dialogRef = this.dialog.open(QuestionViewComponent, {
            width: '1240px',
            disableClose: true,
            data: { origin: typeof action.preview === 'string' ? 'isSingleForm' : undefined },
          });
          dialogRef.beforeClosed().subscribe(() => {
            this.helperService.navigateBack();
          });
          return openQuestionViewSuccess({ question });
        }),
        catchError((error) => {
          return of(openQuestionViewFail({ error }));
        }),
      );
    }),
  ));

  saveQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(saveQuestion),
    mergeMap((action) => {
      if (action.imagesToDelete.length > 0) {
        action.imagesToDelete.forEach((image) => {
          this.imageService.deleteImage(image.name, 'questions');
        });
      }
      return this.store.select(getAuthState).pipe(take(1), mergeMap((authState) => {
        if (authState && authState.user) {
          return from(this.questionService.saveQuestion(action.question, authState.user)).pipe(
            map(() => saveQuestionSuccess({ id: action.question.id })),
            catchError(error => of(saveQuestionFail({ error }))),
          );
        }
        return of(saveQuestionFail({ error: {} }));
      }));
    }),
  ));

  saveQuestionWithImages$ = createEffect(() => this.actions$.pipe(
    ofType(saveQuestionWithImages),
    mergeMap((action) => {
      const quesToSave: Question = _.cloneDeep(action.question);
      if (action.imagesToDelete.length > 0) {
        action.imagesToDelete.forEach((image) => {
          this.imageService.deleteImage(image.name, 'questions');
        });
      }
      const observables: Observable<UploadTaskSnapshot>[] = action.imagesToUpload.map((img) => {
        return from(this.imageService.uploadImage(img, 'questions'));
      });
      return forkJoin(observables).pipe(
        mergeMap((imageResults: UploadTaskSnapshot[]) => {
          let imageResIndex = 0;
          quesToSave.type.forEach((t, i) => {
            if (t === 'preview') {
              quesToSave.data[i] = imageResults[imageResIndex].metadata.name;
              quesToSave.type[i] = 'img';
              imageResIndex = imageResIndex + 1;
            }
          });
          return this.store.select(getAuthState).pipe(take(1), mergeMap((authState) => {
            if (authState && authState.user) {
              return from(this.questionService.saveQuestion(quesToSave, authState.user)).pipe(
                map(() => saveQuestionWithImagesSuccess({ id:quesToSave.id })),
                catchError(error => of(saveQuestionWithImagesFail({ error }))),
              );
            }
            return of(saveQuestionWithImagesFail({ error: {} }));
          }));
        }),
        catchError(error => of(saveQuestionWithImagesFail({ error }))),
      );
    }),
  ));

  publishQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(publishQuestion),
    mergeMap((action) => {
      const observables = forkJoin([
        this.store.select(getAuthState).pipe(take(1)),
        this.questionService.getQuestion(action.question.reference).pipe(take(1)),
      ]);
      return observables.pipe(mergeMap(([authState, question]) => {
        if (authState && authState.user) {
          question.id = this.questionService.getQuestionId(action.question.reference);
          return from(this.questionService.publishQuestion(question, authState.user)).pipe(
            map(() => publishQuestionSuccess()),
            catchError(error => of(publishQuestionFail({ error }))),
          );
        }
        return of(publishQuestionFail({ error: {} }));
      }));
    }),
  ));

  deactivateQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(deactivateQuestion),
    mergeMap((action) => {
      const observables = forkJoin([
        this.store.select(getAuthState).pipe(take(1)),
        this.questionService.getQuestion(action.question.reference).pipe(take(1)),
      ]);
      return observables.pipe(mergeMap(([authState, question]) => {
        if (authState && authState.user) {
          question.id = this.questionService.getQuestionId(action.question.reference);
          return from(this.questionService.unpublishQuestion(question, authState.user)).pipe(
            map(() => deactivateQuestionSuccess()),
            catchError(error => of(deactivateQuestionFail({ error }))),
          );
        }
        return of(deactivateQuestionFail({ error: {} }));
      }));
    }),
  ));

  reactivateQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(reactivateQuestion),
    mergeMap((action) => {
      const observables = forkJoin([
        this.store.select(getAuthState).pipe(take(1)),
        this.questionService.getQuestion(action.question.reference).pipe(take(1)),
      ]);
      return observables.pipe(mergeMap(([authState, question]) => {
        if (authState && authState.user) {
          question.id = this.questionService.getQuestionId(action.question.reference);
          return from(this.questionService.publishQuestion(question, authState.user)).pipe(
            map(() => reactivateQuestionSuccess()),
            catchError(error => of(reactivateQuestionFail({ error }))),
          );
        }
        return of(reactivateQuestionFail({ error: {} }));
      }));
    }),
  ));

  deleteQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(deleteQuestion),
    mergeMap((action) => {
      const observables = forkJoin([
        this.store.select(getAuthState).pipe(take(1)),
        this.questionService.getQuestion(action.question.reference).pipe(take(1)),
      ]);
      return observables.pipe(mergeMap(([authState, question]) => {
        if (authState && authState.user) {
          question.id = this.questionService.getQuestionId(action.question.reference);
          return from(this.questionService.deleteQuestion(question, authState.user)).pipe(
            map(() => deleteQuestionSuccess()),
            catchError(error => of(deleteQuestionFail({ error }))),
          );
        }
        return of(reactivateQuestionFail({ error: {} }));
      }));
    }),
  ));

  questionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(saveQuestionSuccess, saveQuestionWithImagesSuccess),
    mergeMap((action) => {
      return of(showAlert(
        {
          toast: true,
          position: 'bottom-end',
          showConfirm: false,
          showCancel: false,
          timer: 10000,
          alertType: 'success',
          title: `Pregunta con ID ${action.id} correctamente guardada.`,
        },
      ));
    }),
  ));

  questionsErrors$ = createEffect(() => this.actions$.pipe(
    ofType(loadQuestionsFail, loadQuestionsCountFail, openQuestionFormFail, saveQuestionFail, saveQuestionWithImagesFail),
    mergeMap((action) => {
      return of(showAlert({ alertType: 'error', error: action.error, title: 'Oops...' }));
    }),
  ));

  questionsErrors3$ = createEffect(() => this.actions$.pipe(
    ofType(changeQuestionsPaginationValueFail),
    mergeMap((action) => {
      return of(showAlert({ alertType: 'error', error: action.error, title: 'Oops...' }));
    }),
  ));

  questionsErrors2$ = createEffect(() => this.actions$.pipe(
    ofType(publishQuestionFail, deactivateQuestionFail, reactivateQuestionFail),
    mergeMap((action) => {
      return of(showAlert({ alertType: 'error', error: action.error, title: 'Oops...' }));
    }),
  ));

  private generateNextPaginationIndex (
    status: Status | '',
    statusCounters: QuestionCount,
    dataPerPage: number,
    pageIndex: number,
    reverse: boolean,
  ): number {
    const lastIndexOfQuesRequired = ((pageIndex + 1) * dataPerPage) - 1;
    let selectedData = statusCounters.all;
    if (status !== '') {
      selectedData = statusCounters[status];
    }
    let pageCounters = _.cloneDeep(selectedData.pagination);
    if (reverse) {
      pageCounters = pageCounters.reverse();
    }
    let quesCountForStatus = 0;
    let nextPagIndex = 0;
    pageCounters.forEach((pageCount, i) => {
      if (pageCount !== 0 && lastIndexOfQuesRequired > quesCountForStatus) {
        quesCountForStatus += pageCount;
        nextPagIndex = i;
      }
    });
    return nextPagIndex;
  }

}
