import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { loginAttempt } from '../../../core/ngrx/authentication/authentication.actions';
import { Subject } from 'rxjs';
import { getAuthState } from '../../../core/ngrx/authentication/authentication.selectors';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'em-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {

  loginForm: FormGroup;
  $destroy: Subject<boolean> = new Subject<boolean>();
  loading = false;

  constructor (private store: Store<any>, private formBuilder: FormBuilder) {
    this.loginForm = this.formBuilder.group(
      {
        email: ['', Validators.required],
        pass: ['', Validators.required],
      },
    );
    this.setStateSubscriptions();
  }

  setStateSubscriptions () {
    this.store.pipe(
      takeUntil(this.$destroy),
      select(getAuthState),
    ).subscribe((authState) => {
      this.loading = authState.loading;
    });
  }

  login () {
    if (this.loginForm.valid) {
      const email = this.loginForm.get('email').value;
      const pass = this.loginForm.get('pass').value;
      this.store.dispatch(loginAttempt({ user: email, password: pass }));
    }
  }

  ngOnDestroy (): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
