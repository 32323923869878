import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/Auth/auth.service';
import { auditTime, map, mergeMap, take } from 'rxjs/operators';
import { QuestionService } from '../services/Question/question.service';
import { DescriptionService } from '../services/Description/description.service';
import { ExplanationService } from '../services/Explanation/explanation.service';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { showAlert } from '../core/ngrx/alerts/alerts.actions';
import { FlashcardService } from '../services/Flashcard/flashcard.service';

@Injectable({ providedIn: 'root' }) export class QuestionEditionGuard implements CanActivate {
  constructor (
    private auth: AuthService,
    private router: Router,
    private questionService: QuestionService,
    private store: Store<any>,
  ) {}
  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const requestedId = route.params.id;
    if (!requestedId) return this.router.parseUrl('/notFound');
    return this.questionService.getQuestion(`${environment.projectId}/questions/${requestedId}`).pipe(
      take(1),
      mergeMap((question) => {
        if (!question) {
          const mockError = { code: 800, message: `Recurso "questions:${requestedId}" no encontrado` };
          this.store.dispatch(showAlert({ alertType: 'error', error: mockError, title: 'Oops...' }));
          return of(this.router.parseUrl('/editor'));
        }
        return this.auth.getUser().pipe(take(1), map((user) => {
          return this.auth.canEditQuestion(user)(question) || this.router.parseUrl('/notAllowed');
        }));
      }),
    );
  }
}

@Injectable({ providedIn: 'root' }) export class QuestionViewGuard implements CanActivate {
  constructor (
    private auth: AuthService,
    private router: Router,
    private questionService: QuestionService,
    private store: Store<any>,
  ) {}
  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const requestedId = route.params.id;
    if (!requestedId) return this.router.parseUrl('/notFound');
    return this.questionService.getQuestion(`${environment.projectId}/questions/${requestedId}`).pipe(
      take(1),
      mergeMap((question) => {
        if (!question) {
          const mockError = { code: 800, message: `Recurso "questions:${requestedId}" no encontrado` };
          this.store.dispatch(showAlert({ alertType: 'error', error: mockError, title: 'Oops...' }));
          return of(this.router.parseUrl('/editor'));
        }
        return this.auth.getUser().pipe(take(1), map((user) => {
          return this.auth.canViewQuestion(user)(question) || this.router.parseUrl('/notAllowed');
        }));
      }),
    );
  }
}

@Injectable({ providedIn: 'root' }) export class DescriptionEditionGuard implements CanActivate {
  constructor (
    private auth: AuthService,
    private router: Router,
    private descriptionService: DescriptionService,
    private store: Store<any>,
  ) {}
  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const requestedId = route.params.id;
    if (!requestedId) return this.router.parseUrl('/notFound');
    return this.descriptionService.getDescription(`${environment.projectId}/descriptions/${requestedId}`).pipe(
      take(1),
      mergeMap((description) => {
        if (!description) {
          const mockError = { code: 800, message: `Recurso "descriptions:${requestedId}" no encontrado` };
          this.store.dispatch(showAlert({ alertType: 'error', error: mockError, title: 'Oops...' }));
          return of(this.router.parseUrl('/editor'));
        }
        return this.auth.getUser().pipe(take(1), map((user) => {
          return user && (this.auth.isEditor(user) || this.auth.isApprover(user)) || this.router.parseUrl('/notAllowed');
        }));
      }),
    );
  }
}

@Injectable({ providedIn: 'root' }) export class ExplanationEditionGuard implements CanActivate {
  constructor (
    private auth: AuthService,
    private router: Router,
    private explanationService: ExplanationService,
    private store: Store<any>,
  ) {}
  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const requestedId = route.params.id;
    if (!requestedId) return this.router.parseUrl('/notFound');
    return this.explanationService.getExplanation(`${environment.projectId}/explanations/${requestedId}`).pipe(
      take(1),
      mergeMap((explanation) => {
        if (!explanation) {
          const mockError = { code: 800, message: `Recurso "explanations:${requestedId}" no encontrado` };
          this.store.dispatch(showAlert({ alertType: 'error', error: mockError, title: 'Oops...' }));
          return of(this.router.parseUrl('/editor'));
        }
        return this.auth.getUser().pipe(take(1), map((user) => {
          return user && (this.auth.isEditor(user) || this.auth.isApprover(user)) || this.router.parseUrl('/notAllowed');
        }));
      }),
    );
  }
}

@Injectable({ providedIn: 'root' }) export class FlashcardEditionGuard implements CanActivate {
  constructor (
    private auth: AuthService,
    private router: Router,
  ) {}
  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const requestedId = route.params.id;
    if (!requestedId) return this.router.parseUrl('/notFound');
    return this.auth.getUser().pipe(take(1), map((user) => {
      return user && (this.auth.isEditor(user) || this.auth.isApprover(user)) || this.router.parseUrl('/notAllowed');
    }));
  }
}
