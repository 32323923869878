<div class="container margin-top-small">
  <mat-card>
    <section class="filter-bar columns is-centered is-vcentered is-gapless">
      <div class="column is-5 is-flex flex-center">
        <button mat-raised-button color="primary" (click)="generateUid()">Generar ID</button>
      </div>
      <div class="column is-5 is-flex flex-center">
        <label class="id-container" [ngClass]="{ 'recently-generated': newFlag }">{{ currentId ? currentId : ' ' }}</label>
      </div>
    </section>
  </mat-card>
</div>
