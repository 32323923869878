<div mat-dialog-title class="columns is-gapless">
  <div class="column is-1" *ngIf="isAnEditForm">
    <button mat-icon-button [matTooltip]="'Copiar ID de la explicación seleccionada'"
            matTooltipPosition="above" (click)="copyIDToClipboard(explanation.id)">
      <mat-icon color="primary">bookmarks</mat-icon>
    </button>
    <button mat-icon-button [matTooltip]="'Copiar URL de la explicación seleccionada'"
            matTooltipPosition="above" (click)="copyURLToClipboard(explanation.id)">
      <mat-icon color="primary">link</mat-icon>
    </button>
  </div>
  <div class="column" [ngClass]="isAnEditForm ? 'is-11' : 'is-12'">
    <h1 [matTooltip]="explanation.title" matTooltipPosition="below">
      {{ isAnEditForm ? 'Editar explicación: ' + explanation.title : 'Crear explicación' }}
    </h1>
  </div>
</div>
<div mat-dialog-content>
  <mat-horizontal-stepper [linear]="false">
    <mat-step [stepControl]="explanationDialogForm">
      <ng-template matStepLabel>Contenido de la explicación</ng-template>
      <div class="stepperButtons end">
        <button mat-raised-button color="accent" matStepperNext>Siguiente sección</button>
      </div>
      <div class="columns constraint-height">
        <form class="column" [formGroup]="explanationDialogForm" autocomplete="off">
          <mat-checkbox formControlName="isHumanGeo" color="primary">Esta explicación es para Geometría Humana (Flashcards)</mat-checkbox>
          <mat-form-field>
            <input matInput placeholder="Título" formControlName="title">
            <mat-hint>El título se actualiza de forma automática con el primer párrafo.</mat-hint>
          </mat-form-field>
          <em-html-text-editor #htmlEditor></em-html-text-editor>
          <mat-divider></mat-divider>
          <div class="actionButtons" *ngIf="editing !== undefined">
            <button mat-raised-button (click)="updateText()" color="accent" [disabled]="loading">Actualizar texto</button>
            <button mat-raised-button (click)="cancelUpdateText()" color="accent" [disabled]="loading">Cancelar edición</button>
          </div>
          <div [ngClass]="{ 'actionButtons': editing === undefined }" [hidden]="editing !== undefined">
            <button mat-raised-button (click)="addText()" color="primary" [disabled]="loading">Agregar texto</button>
            <div class="imageInputs" *ngIf="explanationDialogForm.get('images')">
              <div class="imageInput" formArrayName="images" *ngFor="let image of getImagesArrayFromForm().controls; let i = index;">
                <div [formGroupName]="i">
                  <input [id]="'explanationImage'+i" formControlName="image" type="file" mat-raised-button accept='image/jpeg, image/png'
                         (change)="addImage($event)" color="primary" placeholder="Agregar imagen">
                  <button type="button" mat-raised-button (click)="clickedAddImage(i)" color="primary" [disabled]="loading">Agregar imagen</button>
                </div>
              </div>
            </div>
          </div>
          <div [hidden]="editing !== undefined" class="marginTop">
            <div class="listButtons">
              <button type="button" mat-raised-button (click)="addText('Introducción')" color="warn" [disabled]="loading">Agregar "Introducción"</button>
              <button type="button" mat-raised-button (click)="addText('Epidemiología')" color="warn" [disabled]="loading">Agregar "Epidemiología"</button>
            </div>
            <div class="listButtons">
              <button type="button" mat-raised-button (click)="addText('Cuadro clínico')" color="warn" [disabled]="loading">Agregar "Cuadro clínico"</button>
              <button type="button" mat-raised-button (click)="addText('Fisiopatología')" color="warn" [disabled]="loading">Agregar "Fisiopatología"</button>
            </div>
            <div class="listButtons">
              <button type="button" mat-raised-button (click)="addText('Tratamiento')" color="warn" [disabled]="loading">Agregar "Tratamiento"</button>
              <button type="button" mat-raised-button (click)="addText('Pronóstico')" color="warn" [disabled]="loading">Agregar "Pronóstico"</button>
            </div>
            <div class="actionButtons">
              <button type="button" mat-raised-button (click)="addText('Abordaje y diagnóstico')" color="warn" [disabled]="loading">Agregar "Abordaje y diagnóstico"</button>
            </div>
          </div>
        </form>
        <div class="column preview">
          <h4 class="title is-4">Vista previa</h4>
          <mat-card *ngIf="imageLoading" >
            <mat-spinner></mat-spinner>
          </mat-card>
          <mat-card *ngIf="explanation" [ngClass]="{'is-hidden': imageLoading}" cdkDropList (cdkDropListDropped)="drop($event)">
            <div class="is-flex draggable preview-element" *ngFor="let content of explanation.data; let i = index" cdkDrag matLine>
              <div *ngIf="explanation.type[i] === 'txt' && !isHtmlEditable(i)" class="title-text is-flex flex-items-center" matLine>
                <figure class="image is-32x32"><img [src]="getIconSrc(content)"></figure>
                {{content}}
              </div>
              <div *ngIf="explanation.type[i] === 'txt' && isHtmlEditable(i)" matLine>
                <em-html-text-previewer [html]="content"></em-html-text-previewer>
              </div>
              <figure *ngIf="explanation.type[i] === 'img' || explanation.type[i] === 'preview'" class="image is-responsive" matLine>
                <img [src]="getImageSrc(content, explanation.type[i])" (load)="loadedImage(content)">
              </figure>
              <span>
                <button *ngIf="explanation.type[i] === 'txt' && !isBannedText(content)" mat-icon-button color="primary"
                        matTooltip="Editar texto" matTooltipPosition="above" (click)="editItem(i)" [disabled]="loading">
                  <mat-icon aria-label="Editar">edit</mat-icon>
                </button>
                <button mat-icon-button color="warn" (click)="removeItem(i)" matTooltip="Eliminar elemento" matTooltipPosition="above"
                        [disabled]="explanation.data.length === 1 || loading">
                  <mat-icon aria-label="Eliminar">delete</mat-icon>
                </button>
              </span>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="resourcesForm">
      <ng-template matStepLabel>Referencias</ng-template>
      <div class="stepperButtons multiple">
        <button mat-raised-button color="accent" matStepperPrevious>Sección anterior</button>
        <button mat-raised-button color="accent" matStepperNext>Siguiente sección</button>
      </div>
      <form [formGroup]="resourcesForm" autocomplete="off">
        <em-reference-form #refFormComponent formGroupName="resources"></em-reference-form>
      </form>
    </mat-step>
    <mat-step [stepControl]="flashcardsForm">
      <ng-template matStepLabel>Flashcards</ng-template>
      <div class="stepperButtons start">
        <button mat-raised-button color="accent" matStepperPrevious>Sección anterior</button>
      </div>
      <form [formGroup]="flashcardsForm" autocomplete="off" [hidden]="explanationDialogForm.get('isHumanGeo') && explanationDialogForm.get('isHumanGeo').value">
        <em-flashcard-form #flashFormComponent formGroupName="flashcards"></em-flashcard-form>
      </form>
      <div class="has-text-centered" *ngIf="explanationDialogForm.get('isHumanGeo') && explanationDialogForm.get('isHumanGeo').value">
        Una explicación para Geometría Humana no puede tener Flashcards asignadas.
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
<div mat-dialog-actions align="end">
  <div class="has-text-danger" *ngIf="errorOnSave === 'save'">Error al guardar, intente de nuevo o intente más tarde.</div>
  <div class="has-text-danger" *ngIf="errorOnSave === 'size'">El tamaño de imagen admitido es de máximo 1 MB.</div>
  <div class="has-text-danger" *ngIf="errorOnSave === 'title'">Título automático no definido, en caso de contar únicamente con imágenes es necesario definirlo de forma manual.</div>
  <button [disabled]="loading" mat-raised-button (click)="close()">Regresar</button>
  <button [ngClass]="{ 'button is-loading': loading }" mat-raised-button (click)="attempt()" color="primary"
          [disabled]="!explanation.data.length || !resourcesForm.valid || !flashcardsForm.valid">Guardar</button>
</div>
