import { Component, OnDestroy } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { ExplanationPreview, ExplanationsState } from '../../../core/ngrx/explanations/explanations.interfaces';
import { environment } from '../../../../environments/environment';
import { auditTime, debounceTime, filter, take, takeUntil, timeout } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getExplanationsState, getSelectedExplanationState } from '../../../core/ngrx/explanations/explanations.selectors';
import { Subject, Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { HelperValidators } from '../../../validators/helper-validators';
import { ExplanationService } from '../../../services/Explanation/explanation.service';
import { getAuthState } from '../../../core/ngrx/authentication/authentication.selectors';
import { loadExplanations, loadExplanationsCount } from '../../../core/ngrx/explanations/explanations.actions';
import * as _cloneDeep from 'lodash/cloneDeep';
import { firestore } from 'firebase/app';
import { HelperService } from '../../../services/Helper/helper.service';

@Component({
  selector: 'em-flashcard-explanation-form',
  templateUrl: './flashcard-explanation-form.component.html',
  styleUrls: ['./flashcard-explanation-form.component.scss'],
})
export class FlashcardExplanationFormComponent implements OnDestroy {
  readonly paginateChangeValue: number = environment.paginateVal;
  $destroy: Subject<boolean> = new Subject<boolean>();
  $changedSelectedExplanation: Subject<string> = new Subject<string>();
  newExplanationSubscription: Subscription;
  explanationsState: ExplanationsState;
  explanationShownIndex = 0;
  explanationActivePreviews: ExplanationPreview[] = [];
  explanationFilteredPreviews: ExplanationPreview[] = [];
  createdExplanationLoading = false;
  searchingExplanation = false;
  searchExplanationError = false;
  isReversed = false;

  constructor (
    public controlContainer: ControlContainer,
    private store: Store<any>,
    private explanationService: ExplanationService,
    private helperService: HelperService,
  ) {}

  get activeForm (): FormGroup {
    return <FormGroup>this.controlContainer.control;
  }

  get getPreviewIndex () {
    return this.getCurrentIndexActive(this.activeForm.get('explanation').value);
  }

  generateForm (explanationRef?: string, previewIndex?: number) {
    if (explanationRef) {
      this.activeForm.get('explanation').setValue(explanationRef);
    }
    this.setSubscriptions(previewIndex);
  }

  async refreshData (newIndex: number) {
    const valueToLoad = newIndex + 1;
    return new Promise((resolve) => {
      if (valueToLoad > this.explanationsState.previewsIndexLoaded && valueToLoad <= this.explanationsState.previewsMaxIndex) {
        this.store.dispatch(loadExplanations({ paginateVal: valueToLoad }));
        this.store.pipe(
          select(getExplanationsState),
          filter(explanations => !explanations.loading && !explanations.error),
          auditTime(100),
          take(1),
        ).subscribe(() => {
          this.filterPreviews(newIndex).then(() => resolve());
        });
      } else {
        this.filterPreviews(newIndex).then(() => resolve());
      }
    });
  }

  newExplanation () {
    if (this.newExplanationSubscription) {
      this.newExplanationSubscription.unsubscribe();
      this.newExplanationSubscription = undefined;
    }
    this.helperService.navigateTo('explanations/create').then(() => {
      this.newExplanationSubscription = this.store.pipe(
        select(getSelectedExplanationState),
        filter(state => !state.loading && state.explanation && !!state.explanation.id),
        takeUntil(this.$destroy),
        take(1),
      ).subscribe((explanation) => {
        this.store.dispatch(loadExplanationsCount());
        this.createdExplanationLoading = true;
        this.selectActiveExplanation(explanation.explanation.id);
      });
    });
  }

  editSelectedExplanation () {
    const control = this.activeForm.get('explanation');
    if (control && control.value) {
      const explanation = this.helperService.transformReferenceToId(control.value);
      return this.helperService.navigateTo(`explanations/edit/${explanation}`);
    }
  }

  private setSubscriptions (requiredIndex?: number) {
    const debounceMs = 500;
    this.store.pipe(
      takeUntil(this.$destroy),
      select(getAuthState),
      filter(authState => !!(authState && authState.user)),
    ).subscribe(authState => this.isReversed = authState.user.newToOld);
    this.store.pipe(
      takeUntil(this.$destroy),
      select(getExplanationsState),
      filter(state => !state.loading),
    ).subscribe((explanationsState) => {
      if (this.explanationsState === undefined) {
        setTimeout(() => this.refreshData(0));
      }
      this.explanationsState = explanationsState;
      if (explanationsState.previewsIndexLoaded > 0) {
        const hasExplanation: boolean = !isNullOrUndefined(requiredIndex) && !isNaN(requiredIndex);
        let index = hasExplanation ? requiredIndex : 0;
        if (this.isReversed && hasExplanation) {
          const maxIndex = this.explanationsState.previewsMaxIndex;
          index = maxIndex - index;
        }
        setTimeout(() => this.refreshData(index));
      }
    });
    this.activeForm.get('explanationSearch').valueChanges.pipe(
      takeUntil(this.$destroy),
    ).subscribe((text: string) => {
      if (text === '' || isNullOrUndefined(text)) {
        return this.explanationFilteredPreviews = this.explanationActivePreviews;
      }
      return this.explanationFilteredPreviews = this.explanationActivePreviews.filter((preview) => {
        return preview.title.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) !== -1;
      });
    });
    this.activeForm.get('explanationIdSearch').valueChanges.pipe(
      takeUntil(this.$destroy),
      debounceTime(debounceMs),
      filter(val => val !== '' && !isNullOrUndefined(val)),
    ).subscribe(id => this.searchForResourceById(id));
    this.activeForm.get('explanation').valueChanges.pipe(
      takeUntil(this.$destroy),
      filter(val => val !== '' && !isNullOrUndefined(val)),
    ).subscribe(id => this.$changedSelectedExplanation.next(id));
  }

  private searchForResourceById (id: string) {
    const path = `${environment.projectId}/explanations/${id}`;
    const timeOut = 10000;
    this.searchingExplanation = true;
    this.activeForm.get('explanationIdSearch').disable({ emitEvent:false });
    this.explanationService.getExplanation(path).pipe(take(1)).subscribe((explanation) => {
      if (explanation) {
        let requiredIndex = Math.trunc(explanation.index / environment.paginateVal);
        this.activeForm.get('explanation').setValue(path);
        if (this.isReversed) {
          const maxIndex = this.explanationsState.previewsMaxIndex;
          requiredIndex = maxIndex - requiredIndex + 1;
        }
        this.store.dispatch(loadExplanations({ paginateVal: requiredIndex }));
        const subscribe = this.store.pipe(
          timeout(timeOut),
          select(getExplanationsState),
          filter(state => !(state.previewsIndexLoaded < requiredIndex)),
        ).subscribe(() => {
          this.activeForm.get('explanation').setValue(path);
          this.refreshData(this.isReversed ? requiredIndex - 1 : requiredIndex).then(() => {
            subscribe.unsubscribe();
            this.searchingExplanation = false;
          });
        });
      } else {
        this.searchExplanationError = true;
        this.searchingExplanation = false;
        this.activeForm.get('explanationIdSearch').setValidators([HelperValidators.invalidIf(true)]);
      }
      this.activeForm.get('explanationIdSearch').enable({ emitEvent:false });
    });
  }

  private async filterPreviews (index: number) {
    let previews: ExplanationPreview[] = _cloneDeep(this.explanationsState.previewsPages[index]);
    if (this.explanationsState.previewsPages[index + 1]) {
      previews = previews.concat(_cloneDeep(this.explanationsState.previewsPages[index + 1]));
    }
    if (this.isReversed) {
      const explanationOffset = this.explanationsState.previewsTotalCount % environment.paginateVal;
      const startVal = index === 0 ? 0 : environment.paginateVal - explanationOffset;
      previews = previews.slice(startVal, environment.paginateVal + startVal);
    }
    this.explanationActivePreviews = previews;
    this.explanationFilteredPreviews = previews;
    this.explanationShownIndex = index;
    return new Promise(res => res());
  }

  private getCurrentIndexActive (path?: string): number | null {
    if (path) {
      const maxIndex = this.explanationsState.previewsMaxIndex;
      const preview = this.explanationsState.previews.findIndex((p) => {
        if (p.reference instanceof firestore.DocumentReference) {
          return p.reference.path === path;
        }
        return p.reference === path;
      });
      const paginationVal = Math.floor(preview / this.paginateChangeValue);
      if (this.isReversed) {
        return maxIndex - paginationVal;
      }
      return paginationVal;
    }
    return null;
  }

  private selectActiveExplanation (id: string) {
    const path = `${environment.projectId}/explanations/${id}`;
    const expectedIndex = this.isReversed ? 0 : this.explanationsState.previewsMaxIndex;
    const intervalTime = 500;
    let interval;
    const operation = () => {
      this.refreshData(expectedIndex).then(() => {
        const preview = this.explanationsState.previewsPages[expectedIndex].find((preview) => {
          return this.helperService.transformReferenceToId(preview.reference) === id;
        });
        if (preview) {
          this.activeForm.get('explanation').setValue(path);
          this.createdExplanationLoading = false;
          clearInterval(interval);
        }
      });
    };
    interval = setInterval(operation, intervalTime);
  }

  ngOnDestroy () {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
