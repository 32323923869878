<div mat-dialog-title class="columns is-gapless">
  <div class="column is-1" *ngIf="isAnEditForm">
    <button mat-icon-button [matTooltip]="'Copiar ID de la flashcard seleccionada'"
            matTooltipPosition="above" (click)="copyIDToClipboard(flashcard.id)">
      <mat-icon color="primary">bookmarks</mat-icon>
    </button>
    <button mat-icon-button [matTooltip]="'Copiar URL de la flashcard seleccionada'"
            matTooltipPosition="above" (click)="copyURLToClipboard(flashcard.id)">
      <mat-icon color="primary">link</mat-icon>
    </button>
  </div>
  <div class="column" [ngClass]="isAnEditForm ? 'is-11' : 'is-12'">
    <h1 [matTooltip]="flashcard.name" matTooltipPosition="below">
      {{ isAnEditForm ? 'Editar flashcard: ' + flashcard.name : 'Crear flashcard' }}
    </h1>
  </div>
</div>
<div mat-dialog-content>
  <mat-horizontal-stepper [linear]="false" *ngIf="formsLoaded">
    <mat-step [stepControl]="flashcardDialogForm">
      <ng-template matStepLabel>Contenido de la Flashcard</ng-template>
      <div class="stepperButtons end">
        <button mat-raised-button color="accent" matStepperNext>Siguiente sección</button>
      </div>
      <div class="columns constraint-height">
        <form class="column" [formGroup]="flashcardDialogForm" autocomplete="off">
          <mat-form-field *ngIf="!isAnEditForm">
            <input matInput placeholder="ID" formControlName="id">
            <mat-hint>Este campo es opcional</mat-hint>
            <mat-error *ngIf="flashcardDialogForm.get('id').invalid && flashcardDialogForm.get('id').errors?.invalidId">
              ID no válido, sólo puede contener letras y números y no ser mayor a 30 caracteres ni menor a 15.
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="Nombre" formControlName="name">
            <mat-error *ngIf="flashcardDialogForm.get('name').invalid && flashcardDialogForm.get('name').errors?.required">
              Este campo es obligatorio, es necesario ingresar un valor.
            </mat-error>
          </mat-form-field>
          <div class="is-flex flex-space-around">
            <mat-slide-toggle formControlName="geoHum" color="primary">Geometría humana</mat-slide-toggle>
            <mat-slide-toggle formControlName="isFree" color="primary">Flashcard gratuita</mat-slide-toggle>
          </div>
          <mat-form-field>
            <mat-label>Materia</mat-label>
            <mat-select formControlName="topic">
              <mat-option *ngFor="let topic of topics | keyvalue: sortTopics" [value]="topic.key" [disabled]="!topic.value.subtopics">
                {{topic.value.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="flashcardDialogForm.get('topic').invalid && flashcardDialogForm.get('topic').errors?.required">
              Este campo es obligatorio, es necesario ingresar un valor.
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Submateria</mat-label>
            <mat-select formControlName="subtopic" [disabled]="subtopicIsDisabled()">
              <ng-container *ngIf="!subtopicIsDisabled() && flashcardDialogForm.get('topic').value !== 'FT1'">
                <mat-option *ngFor="let subtopic of topics[flashcardDialogForm.get('topic').value].subtopics | keyvalue: sortTopics" [value]="subtopic.key">
                  {{subtopic.value.name}}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error *ngIf="flashcardDialogForm.get('subtopic').invalid && flashcardDialogForm.get('subtopic').errors?.required">
              Este campo es obligatorio, es necesario ingresar un valor.
            </mat-error>
          </mat-form-field>
          <mat-divider></mat-divider>
          <div class="is-flex flex-space-between">
            <div class="imageInputs" *ngIf="flashcardDialogForm.get('images')">
              <div class="imageInput" formArrayName="images" *ngFor="let image of getImagesArrayFromForm().controls; let i = index;">
                <div [formGroupName]="i">
                  <input [id]="'flashcardImage'+i" formControlName="image" type="file" mat-raised-button accept='image/jpeg, image/png'
                         (change)="addImage($event)" color="primary" placeholder="Agregar imagen">
                  <button type="button" mat-raised-button (click)="clickedAddImage(i)" color="primary" [disabled]="loading">
                    Agregar imagen
                  </button>
                </div>
              </div>
            </div>
            <div [ngClass]="{ 'has-text-danger': allImages.length === 0 }">Imágenes: {{allImages.length}}</div>
          </div>
        </form>
        <div class="column preview">
          <h4 class="title is-4">Vista previa de imágenes</h4>
          <mat-card *ngIf="imageLoading" >
            <mat-spinner></mat-spinner>
          </mat-card>
          <mat-card *ngIf="flashcard" [ngClass]="{'is-hidden': imageLoading}">
            <div class="is-flex preview-element" *ngFor="let content of allImages; let i = index">
              <figure class="image is-responsive" matLine>
                <img [src]="getImageSrc(content.name)" (load)="loadedImage(content.name)">
              </figure>
              <span>
                <button mat-icon-button color="warn" (click)="removeImage(i)" [disabled]="loading">
                  <mat-icon aria-label="Eliminar">delete</mat-icon>
                </button>
              </span>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="explanationForm" [optional]="true">
      <ng-template matStepLabel>Explicación</ng-template>
      <div class="stepperButtons multiple">
        <button mat-raised-button color="accent" matStepperPrevious>Sección anterior</button>
        <button mat-raised-button color="accent" matStepperNext>Siguiente sección</button>
      </div>
      <em-flashcard-explanation-form #explanationFormComponent [formGroup]="explanationForm"></em-flashcard-explanation-form>
    </mat-step>
  </mat-horizontal-stepper>
</div>
<div id="descriptionFooter" mat-dialog-actions align="end">
  <div class="has-text-danger" *ngIf="errorOnSave === 'save'">Error al guardar, intente de nuevo o intente más tarde.</div>
  <div class="has-text-danger" *ngIf="errorOnSave === 'size'">El tamaño de imagen admitido es de máximo 8 MB.</div>
  <button [disabled]="loading" mat-raised-button (click)="close()">Regresar</button>
  <button [ngClass]="{ 'button is-loading': loading }" mat-raised-button (click)="attempt()" color="primary"
          [disabled]="!flashcard || allImages.length === 0 || flashcardDialogForm.invalid">
    Guardar
  </button>
</div>
