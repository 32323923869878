<div class="main-container">
  <mat-card>
    <form class="filter-bar columns is-centered is-vcentered is-gapless" autocomplete="off">
      <div class="column is-6">
        <button mat-raised-button color="accent" (click)="openFlashcardForm()">Nueva Flashcard</button>
      </div>
      <div class="column is-6">
        <mat-paginator [length]="flashcardsLength" [pageSize]="defaultMatPaginatorValue"
                       [pageSizeOptions]="[5, 10, 20, 50]" (page)="changePageFromDashboard($event)" [disabled]="isLoading"></mat-paginator>
      </div>
    </form>
  </mat-card>
  <ng-container *ngIf="!isLoading">
    <div class="columns">
      <div class="column is-2 has-text-weight-bold is-flex flex-items-center flex-center">ID</div>
      <div class="column is-7 has-text-weight-bold is-flex flex-items-center flex-center">Nombre</div>
      <div class="column is-1 has-text-weight-bold is-flex flex-items-center flex-center">Información</div>
      <div class="column is-1 has-text-weight-bold is-flex flex-items-center flex-center">Imágenes</div>
      <div class="column is-1 has-text-weight-bold is-flex flex-items-center flex-center">Acciones</div>
    </div>
    <div class="columns dataRow text-small" *ngFor="let flashcard of filteredFlashcards">
      <div class="column is-2 is-flex flex-items-center flex-start">
        <button mat-icon-button [matTooltip]="'Copiar ID'" matTooltipPosition="above" (click)="copyIDToClipboard(flashcard.id)">
          <mat-icon [color]="copiedId === flashcard.id ? 'primary' : undefined">bookmarks</mat-icon>
        </button>
        <small>{{ flashcard.id }}</small>
      </div>
      <div class="column is-7 is-flex flex-items-center flex-start">
        <h3>{{ flashcard.name }}</h3>
      </div>
      <div class="column is-1 is-flex flex-items-center flex-space-between padding-small items-help">
        <mat-icon [matTooltip]="(flashcard.hasExplanation ? 'Si' : 'No') + ' tiene explicación'"
                  matTooltipPosition="above" [color]="flashcard.hasExplanation ? 'primary' : undefined">notes</mat-icon>
      </div>
      <div class="column is-1 is-flex flex-center flex-items-center">
        {{ flashcard.images.length }}
      </div>
      <div class="column is-1 is-flex flex-items-center flex-end">
        <button [ngClass]="{'button is-loading': !flashcard}" mat-raised-button color="primary"
                (click)="openFlashcardForm(flashcard)" matTooltip="Editar" matTooltipPosition="above">
          <mat-icon matListIcon>edit</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>
