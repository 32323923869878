import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../services/Auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate {
  constructor (private auth: AuthService, private router: Router) {}
  canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.getUser().pipe(
      take(1),
      map(user => user ? true : this.router.parseUrl('/login')),
    );
  }
}

@Injectable({ providedIn: 'root' })
export class AuthenticationGuardInverse implements CanActivate {
  constructor (private auth: AuthService, private router: Router) {}
  canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.getUser().pipe(
      take(1),
      map(user => !user ? true : this.router.parseUrl('/editor')),
    );
  }
}

@Injectable({ providedIn: 'root' })
export class QuestionsGuard implements CanActivate {
  constructor (private auth: AuthService, private router: Router) {}
  canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.getUser().pipe(
      take(1),
      map((user) => {
        return user && (this.auth.isEditor(user) || this.auth.isApprover(user))
          ? true
          : this.router.parseUrl('/notAllowed');
      }),
    );
  }
}

@Injectable({ providedIn: 'root' })
export class UsersGuard implements CanActivate {
  constructor (private auth: AuthService, private router: Router) {}
  canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.getUser().pipe(
      take(1),
      map(user => user && this.auth.isAdmin(user) ? true : this.router.parseUrl('/notAllowed')),
    );
  }
}
