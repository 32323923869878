<nav>
  <mat-toolbar color="primary">
    <img class="nav-icon" src="../assets/icon_512.png">
    <span title="Estudio Medicina">EM Editor</span>
    <span>&nbsp;v{{ version }}</span>
    <button mat-icon-button color="accent" matTooltip="Información de la versión" [matTooltipPosition]="'below'" (click)="openInfoDialog()"><mat-icon>info</mat-icon></button>
    <button *ngIf="currentUser" (click)="goToEditor()" mat-button title="Ir al editor">Editor</button>
    <button *ngIf="currentUser" (click)="goToUsers()" mat-button title="Ir al gestor de usuarios">Usuarios</button>
    <span class="spacer"></span>
    <span class="normalText">{{ currentUser ? currentUser.email : '' }}</span>
    <button *ngIf="currentUser" (click)="openSettings()" mat-icon-button aria-label="Settings action" title="Configuración">
      <mat-icon>settings</mat-icon>
    </button>
    <button *ngIf="currentUser" (click)="logout()" mat-icon-button aria-label="Logout action" title="Salir">
      <mat-icon>exit_to_app</mat-icon>
    </button>
    <button *ngIf="!currentUser" (click)="login()" mat-icon-button aria-label="Login action" title="Accesar">
      <mat-icon>vpn_key</mat-icon>
    </button>
  </mat-toolbar>
</nav>

<main class="container is-fluid">
  <router-outlet></router-outlet>
</main>
