import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, mergeMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { firestore } from 'firebase/app';
import {
  Flashcard,
  FlashcardCount,
  FlashcardDocPreview, FlashcardsState,
} from '../../core/ngrx/flashcards/flashcards.interfaces';
import { from, Observable, of } from 'rxjs';
import * as _cloneDeep from 'lodash/cloneDeep';
import { HelperService } from '../Helper/helper.service';
import { select, Store } from '@ngrx/store';
import { getFlashcardsState } from '../../core/ngrx/flashcards/flashcards.selectors';
const Timestamp = firestore.Timestamp;

@Injectable({
  providedIn: 'root',
})
export class FlashcardService {

  readonly baseRoute = `${environment.projectId}/processedToRead/flashcards`;

  constructor (private fireStoreDB: AngularFirestore, private helper: HelperService, private store: Store<any>) {}

  getFlashcardsSize (): Observable<number> {
    return this.fireStoreDB.doc(`${this.baseRoute}`).snapshotChanges().pipe(
      map((action: any) => {
        const data: FlashcardCount = action.payload.data();
        return data ? data.totalCount : 0;
      }),
    );
  }

  getFlashcards (index: number): Observable<Flashcard[]> {
    return this.fireStoreDB.doc(`${this.baseRoute}/slices/slice_${index}`).snapshotChanges().pipe(
      map((a: any) => {
        const data: FlashcardDocPreview = a.payload.data();
        const preview: Flashcard[] = [];
        if (data) {
          Object.entries(data).forEach(([id, value]) => {
            preview.push({ ...value, id });
          });
        }
        return preview.sort((a, b) => a.index > b.index ? 1 : -1);
      }),
    );
  }

  getFlashcard (reference: firestore.DocumentReference | string): Observable<Flashcard> {
    return this.store.pipe(
      select(getFlashcardsState),
      map((flashcardState: FlashcardsState) => {
        return flashcardState.previews.find(f => f.id === this.helper.transformReferenceToId(reference));
      }),
    );
  }

  saveFlashcard (flashCard: Flashcard): Observable<string> {
    const id = flashCard.id ? flashCard.id : this.fireStoreDB.createId();
    const flashcard = this.prepareFlashcard(flashCard);
    const sliceId = Math.floor(flashcard.index / environment.bigPaginateVal);
    delete flashcard.id;
    const document = this.fireStoreDB.doc(`${this.baseRoute}/slices/slice_${sliceId}`);
    return document.snapshotChanges().pipe(
      take(1),
      mergeMap((snapshot) => {
        let documentData: any = _cloneDeep(snapshot.payload.data());
        if (!documentData) {
          documentData = {};
        }
        documentData[id] = flashcard;
        return from(document.set(documentData)).pipe(map(() => id));
      }),
    );
  }

  protected prepareFlashcard (flashCard: Flashcard): Flashcard {
    let flashcard: Flashcard = _cloneDeep(flashCard);
    if (flashcard.explanationReference) {
      flashcard.explanationReference = this.generateReference(flashcard.explanationReference);
    }
    if (!flashcard.created) {
      flashcard = { ...flashcard, created: Timestamp.now() };
    } else {
      flashcard.created = new Timestamp(flashcard.created.seconds, flashcard.created.nanoseconds);
    }
    flashcard = { ...flashcard, updated: Timestamp.now() };
    return flashcard;
  }

  generateReference (reference: firestore.DocumentReference | string): firestore.DocumentReference {
    if (reference instanceof firestore.DocumentReference) {
      return reference;
    }
    return this.fireStoreDB.doc(reference).ref;
  }

}
