<ng-container [formGroup]="controlContainer.control">
  <div class="stepperButtons middle">
    <button type="button" mat-raised-button color="accent" (click)="addReference()">Añadir referencia</button>
  </div>
  <div class="imageInput" formArrayName="resourcesArray" *ngFor="let option of getResourcesArray.controls; let i = index;">
    <div [formGroupName]="i" class="columns">
      <mat-form-field class="column is-4">
        <input matInput placeholder="Título de la referencia" formControlName="title">
        <mat-error *ngIf="option.get('title').invalid && option.get('title').errors['required']">
          Este campo es obligatorio, es necesario ingresar un valor.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="column is-3">
        <mat-label>Tipo de la referencia</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let type of resourcesTypes" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="option.get('type').invalid && option.get('type').errors['required']">
          Este campo es obligatorio, es necesario ingresar un valor.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="column is-4" *ngIf="option.get('type').value !== 'bibliography'">
        <input matInput placeholder="URL de la referencia" formControlName="url">
        <mat-error *ngIf="option.get('url').invalid && option.get('url').errors['required']">
          Este campo es obligatorio, es necesario ingresar un valor.
        </mat-error>
        <mat-error *ngIf="option.get('url').invalid && option.get('url').errors['url']">
          El valor ingresado no corresponde a una "URL".
        </mat-error>
        <mat-error *ngIf="option.get('url').invalid && option.get('url').errors['pdf']">
          El valor ingresado no corresponde a una URL de PDF.
        </mat-error>
      </mat-form-field>
      <div class="column is-4" *ngIf="option.get('type').value === 'bibliography'"></div>
      <div class="column is-1">
        <button mat-mini-fab color="warn" (click)="removeReference(i)">
          <mat-icon aria-label="Remove reference">delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>
