// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyA31_t05oT7R7FgGppLYXngpsaHExJFFsg',
    authDomain: 'estudio-medicina.firebaseapp.com',
    databaseURL: 'https://estudio-medicina.firebaseio.com',
    projectId: 'estudio-medicina',
    storageBucket: 'estudio-medicina.appspot.com',
    messagingSenderId: '32114113388',
  },
  paginateVal: 5,
  bigPaginateVal: 10,
  defaultPaginatorValue: 5,
  projectId: 'courses/develop',
  projectAdminId: 'courses/develop/admin/editor',
  projectUrl: 'developer.estudiomedicina.com',
  localeDateOptions: { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
