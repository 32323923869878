<section>
  <mat-tab-group mat-align-tabs="center" (selectedTabChange)="changedTab($event)" [selectedIndex]="activeTabIndex">
    <mat-tab label="Preguntas">
      <em-question #ques></em-question>
    </mat-tab>
    <mat-tab label="Descripciones">
      <em-description #desc></em-description>
    </mat-tab>
    <mat-tab label="Explicaciones">
      <em-explanation #expl></em-explanation>
    </mat-tab>
    <mat-tab label="Materias">
      <em-topic></em-topic>
    </mat-tab>
    <mat-tab label="Generar ID">
      <em-flashcard-id-generator></em-flashcard-id-generator>
    </mat-tab>
    <mat-tab label="Flashcards">
      <em-flashcard-dashboard></em-flashcard-dashboard>
    </mat-tab>
  </mat-tab-group>
</section>
