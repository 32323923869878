import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImageService } from '../../../services/Image/image.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { isNullOrUndefined } from 'util';
import { Flashcard } from '../../../core/ngrx/flashcards/flashcards.interfaces';
import { ImageRefs } from '../../../core/ngrx/dataTypes/dataTypes.interfaces';
import { filter, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { getFlashcardsState, getSelectedFlashcardState } from '../../../core/ngrx/flashcards/flashcards.selectors';
import { saveFlashcard } from '../../../core/ngrx/flashcards/flashcards.actions';
import { environment } from '../../../../environments/environment';
import * as _cloneDeep from 'lodash/cloneDeep';
import { HelperService } from '../../../services/Helper/helper.service';
import { Topic, TopicObject } from '../../../core/ngrx/topics/topics.interfaces';
import { getTopicsState } from '../../../core/ngrx/topics/topics.selectors';
import { HelperValidators } from '../../../validators/helper-validators';
import { FlashcardExplanationFormComponent } from '../flashcard-explanation-form/flashcard-explanation-form.component';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'em-flashcard-edition-form',
  templateUrl: './flashcard-form.component.html',
  styleUrls: ['./flashcard-form.component.scss'],
})
export class FlashcardEditionFormComponent implements OnDestroy, OnInit {

  $destroy: Subject<boolean> = new Subject<boolean>();

  flashcardDialogForm: FormGroup;
  explanationForm: FormGroup;
  @Output() saved = new EventEmitter<Flashcard>(true);
  @ViewChild('explanationFormComponent', { static: false }) explanationFormChild: FlashcardExplanationFormComponent;

  flashcard: Flashcard = {
    id: undefined, name: undefined, thumbnails: [], images: [], topicID: undefined, subtopicID: undefined,
    created: undefined, updated: undefined, hasExplanation: false, index: undefined, isFree: false,
  };
  imagesToRemove: ImageRefs[] = [];
  allImages: ImageRefs[] = [];
  topics: TopicObject;

  loading = false;
  isAnEditForm = false;
  imageLoading = true;
  errorOnSave: string;
  attemptedToSave = false;
  curentFlashcardIndex: number;

  constructor (
    private formBuilder: FormBuilder,
    private store: Store<any>,
    private dialogRef: MatDialogRef<FlashcardEditionFormComponent>,
    private imageService: ImageService,
    private helperService: HelperService,
    private imageCompressService: NgxImageCompressService,
  ) {}

  ngOnInit () {
    this.setStateSubscriptions();
  }

  setStateSubscriptions () {
    this.store.pipe(
      takeUntil(this.$destroy),
      select(getSelectedFlashcardState),
    ).subscribe((selectedFlashcard) => {
      this.loading = selectedFlashcard.loading || selectedFlashcard.uploadingImages;
      if (!this.loading && !selectedFlashcard.error && !this.attemptedToSave) {
        if (selectedFlashcard.flashcard) {
          this.flashcard = _cloneDeep(selectedFlashcard.flashcard);
          this.isAnEditForm = true;
          const explanationRef = this.flashcard.explanationReference
            ? this.helperService.transformReferenceToString(this.flashcard.explanationReference)
            : undefined
          ;
          this.generateEditForm(explanationRef);
        } else {
          this.generateCreateForm();
        }
      }
      if (!this.loading && !selectedFlashcard.uploadingImages && this.attemptedToSave && !selectedFlashcard.error) {
        this.dialogRef.close();
      }
      if (selectedFlashcard.error) {
        this.attemptedToSave = false;
      }
    });
    this.store.pipe(
      takeUntil(this.$destroy),
      select(getTopicsState),
    ).subscribe((topicState) => {
      this.topics = topicState.serverTopicList;
    });
    this.store.pipe(
      takeUntil(this.$destroy),
      select(getFlashcardsState),
      filter(state => !state.loading),
    ).subscribe((flashcardsState) => {
      this.curentFlashcardIndex = !isNaN(this.flashcard.index) ? this.flashcard.index : flashcardsState.previewsTotalCount;
    });
  }

  get formsLoaded (): boolean {
    return !isNullOrUndefined(this.flashcardDialogForm);
  }

  generateCreateForm () {
    this.imageLoading = false;
    this.flashcardDialogForm = this.formBuilder.group(
      {
        id: ['', HelperValidators.isValidFirebaseId],
        name: ['', Validators.required],
        images: this.formBuilder.array([this.createImageInput()]),
        geoHum: [false],
        isFree: [false],
        topic: ['', Validators.required],
        subtopic: ['', Validators.required],
      },
    );
    this.flashcardDialogForm.get('geoHum').valueChanges.pipe(takeUntil(this.$destroy)).subscribe((val) => {
      this.setHumanGeometricalListener(val);
    });
    this.explanationForm = this.formBuilder.group(
      {
        explanation: '',
        explanationSearch: '',
        explanationIdSearch: '',
      },
    );
    setTimeout(() => {
      this.explanationFormChild.generateForm();
    });
  }

  generateEditForm (explanationRef?: string) {
    this.flashcard.images.forEach((imgName) => {
      this.allImages.push({ name: imgName, URL: '', loading: true });
      this.imageService.getImageRef(imgName, 'flashcards').toPromise().then((ref) => {
        this.allImages.find(i => i.name === imgName).URL = ref;
      });
    });
    if (this.flashcard.images.length === 0) {
      this.imageLoading = false;
    }
    this.flashcardDialogForm = this.formBuilder.group(
      {
        name: [this.flashcard.name, Validators.required],
        images: this.formBuilder.array([this.createImageInput()]),
        geoHum: [false],
        isFree: [this.flashcard.isFree],
        topic: [this.flashcard.topicID, Validators.required],
        subtopic: [this.flashcard.subtopicID, Validators.required],
      },
    );
    this.flashcardDialogForm.get('geoHum').valueChanges.pipe(takeUntil(this.$destroy)).subscribe((val) => {
      this.setHumanGeometricalListener(val);
    });
    if (this.flashcard.topicID === 'FT1') {
      this.flashcardDialogForm.get('geoHum').setValue(true);
    }
    this.explanationForm = this.formBuilder.group(
      {
        explanation: '',
        explanationSearch: '',
        explanationIdSearch: '',
      },
    );
    setTimeout(() => {
      this.explanationFormChild.generateForm(explanationRef, this.flashcard.explanationPreviewIndex);
    });
  }

  setHumanGeometricalListener (value: boolean) {
    const topic = this.flashcardDialogForm.get('topic');
    const subtopic = this.flashcardDialogForm.get('subtopic');
    if (value) {
      topic.setValue('FT1');
      topic.updateValueAndValidity();
      topic.disable();
      subtopic.setValue('');
      subtopic.setValidators([]);
      subtopic.updateValueAndValidity();
      setTimeout(() => subtopic.disable());
    } else {
      topic.enable();
      topic.setValue('');
      topic.updateValueAndValidity();
      subtopic.enable();
      subtopic.setValue('');
      subtopic.setValidators([Validators.required]);
      subtopic.updateValueAndValidity();
    }
  }

  getImagesArrayFromForm (): FormArray {
    return <FormArray>this.flashcardDialogForm.get('images');
  }

  subtopicIsDisabled (): boolean {
    return isNullOrUndefined(this.flashcardDialogForm.get('topic').value) || this.flashcardDialogForm.get('topic').value === '';
  }

  sortTopics (a: KeyValue<string, Topic>, b: KeyValue<string, Topic>): number {
    return parseInt(a.key.substr(1), 10) > parseInt(b.key.substr(1), 10) ? 1 : -1;
  }

  loadedImage (imgName: string) {
    const image = this.allImages.find(image => image.name === imgName);
    if (image) {
      image.loading = false;
    }
    if (!this.allImages.filter(image => image.loading === true).length) {
      this.imageLoading = false;
    }
  }

  createImageInput () {
    return this.formBuilder.group({ image: '' });
  }

  clickedAddImage (i: number): void {
    const text = 'flashcardImage';
    document.getElementById(text + i).click();
  }

  addImage (event): void {
    const image = event.target.files[0];
    if (!image) return;
    this.errorOnSave = undefined;
    const mimeType = image.type;
    if (mimeType.match(/image\/jpeg/) === null && mimeType.match(/image\/png/) === null) return;
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      const factor = 1024;
      const mbAllowed = 9;
      const size = this.imageCompressService.byteCount(<string>reader.result);
      if (size > mbAllowed * factor * factor) {
        this.errorOnSave = 'size';
        return;
      }
      const imageId = this.helperService.generateUid();
      this.allImages.push(this.createPreviewImage(<string>reader.result, imageId));
    };
    const imageControls = this.getImagesArrayFromForm();
    imageControls.push(this.createImageInput());
  }

  createPreviewImage (data: string, imageId: string): ImageRefs {
    return {
      name: imageId,
      URL: 'preview',
      loading: false,
      previewData: data,
    };
  }

  removeImage (index: number): void {
    const image = this.allImages[index];
    if (image && image.URL === 'preview') {
      const imageControls = this.getImagesArrayFromForm();
      const onlyPreviewImages = this.allImages.filter(i => i.URL === 'preview');
      const previewIndex = onlyPreviewImages.findIndex(i => i.name === image.name);
      imageControls.removeAt(previewIndex);
      this.allImages.splice(index, 1);
    } else {
      this.imagesToRemove.push(image);
      this.allImages.splice(index, 1);
    }
  }

  attempt () {
    this.errorOnSave = undefined;
    if (!this.loading && this.flashcardDialogForm.valid) {
      const idField = this.flashcardDialogForm.get('id');
      const explanationForm = this.explanationFormChild.activeForm.getRawValue();
      if (!this.isAnEditForm && idField && idField.value) {
        this.flashcard.id = idField.value;
      }
      this.flashcard.name = this.flashcardDialogForm.get('name').value;
      this.flashcard.topicID = this.flashcardDialogForm.get('topic').value;
      this.flashcard.subtopicID = this.flashcardDialogForm.get('subtopic').value;
      this.flashcard.index = this.curentFlashcardIndex;
      this.flashcard.isFree = !!this.flashcardDialogForm.get('isFree').value;
      this.flashcard.explanationReference = explanationForm.explanation ? explanationForm.explanation : null;
      this.flashcard.explanationPreviewIndex = this.explanationFormChild.getPreviewIndex;
      const previews = this.allImages.filter(img => img.URL === 'preview');
      this.flashcard.images = this.allImages.filter(img => img.URL !== 'preview').map(img => img.name);
      const imagesToDelete = this.imagesToRemove;
      const data = { previews, imagesToDelete, flashcard: this.flashcard };
      this.store.dispatch(saveFlashcard(data));
      this.attemptedToSave = true;
    }
  }

  getImageSrc (imgName: string) {
    const img = this.allImages.find(img => img.name === imgName);
    if (img && img.URL === 'preview') {
      return img.previewData;
    }
    return img ? img.URL : '';
  }

  close (): void {
    this.dialogRef.close();
  }

  copyIDToClipboard (id: string) {
    this.helperService.copyToClipboard(id);
  }

  copyURLToClipboard (id: string) {
    const url = `${environment.projectUrl}/flashcards/edit/${id}`;
    this.helperService.copyToClipboard(url);
  }

  ngOnDestroy (): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
