import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ImageService } from '../../../services/Image/image.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgxImageCompressService } from 'ngx-image-compress';
import { isNullOrUndefined } from 'util';
import { Description } from '../../../core/ngrx/descriptions/descriptions.interfaces';
import { dataType, ImageRefs } from '../../../core/ngrx/dataTypes/dataTypes.interfaces';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { getSelectedDescriptionState } from '../../../core/ngrx/descriptions/descriptions.selectors';
import { saveDescription, saveDescriptionWithImages } from '../../../core/ngrx/descriptions/descriptions.actions';
import { environment } from '../../../../environments/environment';
import * as _cloneDeep from 'lodash/cloneDeep';
import { HelperService } from '../../../services/Helper/helper.service';
import { HtmlTextEditorComponent } from '../../em-shared/html-text-editor/html-text-editor.component';

@Component({
  selector: 'em-description-form',
  templateUrl: './description-form.component.html',
  styleUrls: ['./description-form.component.scss'],
})
export class DescriptionFormComponent implements OnDestroy {

  $destroy: Subject<boolean> = new Subject<boolean>();

  descriptionDialogForm: FormGroup;
  @ViewChild('htmlEditor', { static: true }) htmlEditor: HtmlTextEditorComponent;
  @Output() saved = new EventEmitter<Description>(true);

  description: Description = { id: null, title: null, data: [], type: [], created: null, updated: null };
  removedImages: ImageRefs[] = [];
  uploadedImages: ImageRefs[] = [];

  editing: number;
  loading = false;
  isAnEditForm = false;
  imageLoading = true;
  errorOnSave: string;
  attemptedToSave = false;

  constructor (
    private formBuilder: FormBuilder,
    private store: Store<any>,
    private dialogRef: MatDialogRef<DescriptionFormComponent>,
    private imageService: ImageService,
    private helperService: HelperService,
    private imageCompressService: NgxImageCompressService,
   ) {
    this.setStateSubscriptions();
  }

  setStateSubscriptions () {
    this.store.pipe(
      takeUntil(this.$destroy),
      select(getSelectedDescriptionState),
    ).subscribe((selectedDescription) => {
      this.loading = selectedDescription.loading || selectedDescription.uploadingImages;
      if (!this.loading && !selectedDescription.error && !this.attemptedToSave) {
        if (selectedDescription.description) {
          this.description = _cloneDeep(selectedDescription.description);
          this.isAnEditForm = true;
          this.generateEditForm();
        } else {
          this.generateCreateForm();
        }
      }
      if (!this.loading && !selectedDescription.uploadingImages && this.attemptedToSave && !selectedDescription.error) {
        this.dialogRef.close();
      }
      if (selectedDescription.error) {
        this.attemptedToSave = false;
      }
    });
  }

  generateCreateForm () {
    this.imageLoading = false;
    this.descriptionDialogForm = this.formBuilder.group(
      {
        title: [''],
        images: this.formBuilder.array([this.createImageInput()]),
      },
    );
    if (this.description.type.filter(desc => desc === 'img').length !== this.description.type.length || this.description.id === null) {
      this.descriptionDialogForm.get('title').disable();
    }
  }

  generateEditForm () {
    this.description.data.forEach((imgName, i) => {
      if (this.description.type[i] === 'img') {
        this.imageService.getImageRef(imgName, 'descriptions').toPromise().then((ref) => {
          this.uploadedImages.push({ name: imgName, URL: ref, loading: true });
        });
      }
    });
    if (this.description.type.filter(desc => desc === 'img').length === 0) {
      this.imageLoading = false;
    }
    this.description.title = this.htmlEditor.parseHtmlForTitle(this.description.title);
    this.descriptionDialogForm = this.formBuilder.group(
      {
        title: [this.description.title],
        images: this.formBuilder.array([this.createImageInput()]),
      },
    );
    if (this.description.type.filter(desc => desc === 'img').length !== this.description.type.length || this.description.id === null) {
      this.descriptionDialogForm.get('title').disable();
    }
  }

  getImagesArrayFromForm (): FormArray {
    return <FormArray>this.descriptionDialogForm.get('images');
  }

  drop (event: CdkDragDrop<string[]>) {
    moveItemInArray(this.description.data, event.previousIndex, event.currentIndex);
    moveItemInArray(this.description.type, event.previousIndex, event.currentIndex);
  }

  loadedImage (imgName: string) {
    const image = this.uploadedImages.find(image => image.name === imgName);
    if (image) {
      image.loading = false;
    }
    if (!this.uploadedImages.filter(image => image.loading === true).length) {
      this.imageLoading = false;
    }
  }

  createImageInput () {
    return this.formBuilder.group({ image: '' });
  }

  addText (): void {
    const content = this.htmlEditor.getContent();
    this.descriptionDialogForm.get('title').disable();
    if (content !== '' && !isNullOrUndefined(content)) {
      if (this.description.type.filter(desc => desc === 'txt').length === 0) {
        const title = this.htmlEditor.parseHtmlForTitle(content);
        this.description.title = title;
        this.descriptionDialogForm.get('title').enable();
        setTimeout(() => {
          this.descriptionDialogForm.get('title').setValue(title);
          this.descriptionDialogForm.get('title').disable();
        });
      }
      this.description.type.push('txt');
      this.description.data.push(content);
      this.htmlEditor.setContent('');
    }
  }

  clickedAddImage (i: number): void {
    const text = 'descriptionImage';
    document.getElementById(text + i).click();
  }

  addImage (event): void {
    const image = event.target.files[0];
    if (!image) return;
    this.errorOnSave = undefined;
    const mimeType = image.type;
    if (mimeType.match(/image\/jpeg/) === null && mimeType.match(/image\/png/) === null) return;
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      const factor = 1024;
      const mbAllowed = 1.5;
      const size = this.imageCompressService.byteCount(<string>reader.result);
      if (size > mbAllowed * factor * factor) {
        this.errorOnSave = 'size';
        return;
      }
      const imageInstance = new Image();
      imageInstance.src = <string>reader.result;
      imageInstance.onload = () => {
        const type = imageInstance.width > imageInstance.height ? -1 : 1;
        this.compress(<string>reader.result, type, size, mbAllowed * factor * factor);
      };
      this.description.type.push('preview');
      this.description.data.push(<string>reader.result);
    };
    const imageControls = this.getImagesArrayFromForm();
    imageControls.push(this.createImageInput());
  }

  removeItem (index: number): void {
    if (this.description.type[index] === 'preview') {
      const imageControls = this.getImagesArrayFromForm();
      let counter = 0;
      this.description.type.forEach((type, i) => {
        if (type === 'preview') {
          if (i === index) {
            imageControls.removeAt(counter);
            this.description.type.splice(index, 1);
            this.description.data.splice(index, 1);
          } else {
            counter = counter + 1;
          }
        }
      });
    } else {
      if (this.description.type[index] === 'img') {
        const img = this.uploadedImages.find(image => image.name === this.description.data[index]);
        this.removedImages.push(img);
      } else if (this.description.type.filter(desc => desc === 'txt').length === 1 && this.description.type[index] === 'txt') {
        this.descriptionDialogForm.get('title').enable();
      }
      this.description.type.splice(index, 1);
      this.description.data.splice(index, 1);
    }
  }

  editItem (index: number) {
    this.editing = index;
    this.htmlEditor.setContent(this.description.data[index]);
  }

  updateText () {
    const content = this.htmlEditor.getContent();
    let titleIndex = -1;
    this.description.type.forEach((tipo, i) => {
      if (tipo === 'txt' && titleIndex === -1) {
        titleIndex = i;
      }
    });
    if (this.editing === titleIndex) {
      const title = this.htmlEditor.parseHtmlForTitle(content);
      this.description.title = title;
      this.descriptionDialogForm.get('title').enable();
      setTimeout(() => {
        this.descriptionDialogForm.get('title').setValue(title);
        this.descriptionDialogForm.get('title').disable();
      });
    }
    this.description.data[this.editing] = content;
    this.cancelUpdateText();
  }

  cancelUpdateText () {
    this.editing = undefined;
    this.htmlEditor.setContent('');
  }

  attempt () {
    this.errorOnSave = undefined;
    if (!this.loading) {
      const control = this.descriptionDialogForm.get('title');
      if (this.description.title || (control.enabled && control.value !== '')) {
        if (control.enabled && control.value !== '') {
          this.description.title = this.htmlEditor.parseHtmlForTitle(control.value);
        }
        const previews = this.description.data.filter((img, i) => this.description.type[i] === 'preview');
        if (previews.length > 0) {
          this.store.dispatch(saveDescriptionWithImages(
            { imagesToUpload: previews, description: this.description, imagesToDelete: this.removedImages },
          ));
          this.attemptedToSave = true;
        } else {
          this.store.dispatch(saveDescription({ description: this.description, imagesToDelete: this.removedImages }));
          this.attemptedToSave = true;
        }
      } else {
        this.descriptionDialogForm.get('title').enable();
        this.errorOnSave = 'title';
      }
    }
  }

  getImageSrc (imgName: string, type: dataType) {
    const img = this.uploadedImages.find(img => img.name === imgName);
    if (type === 'img') {
      return img ? img.URL : '';
    }
    return imgName;
  }

  close (): void {
    this.dialogRef.close();
  }

  compress (fileInput: string, orientation: number, size: number, maxSizeAllowed: number) {
    const minSize = 40000;
    const minQualityFactor = 40;
    const minRatioFactor = 50;
    const maxFactor = 95;
    const ratio = Math.round(DescriptionFormComponent.mapRange(size, minSize, maxSizeAllowed, maxFactor, minRatioFactor));
    const quality = Math.round(DescriptionFormComponent.mapRange(size, minSize, maxSizeAllowed, maxFactor, minQualityFactor));
    this.imageCompressService.compressFile(fileInput, orientation, ratio, quality).then(
      (image) => {
        const updatedSize = this.imageCompressService.byteCount(<string>image);
        const percent = 100;
        const decimals = 4;
        const compressionPercent = percent - (updatedSize * percent / size);
        this.description.data.pop();
        this.description.data.push(image);
        console.info(`Compressed, from ${size} bytes to ${updatedSize} bytes` +
                       `(compressed ${compressionPercent.toFixed(decimals)}%) with ratio ${ratio}, and quality ${quality}`);
      },
      error => console.info('Error while converting', error),
    );
  }

  isHtmlEditable (dataIndex: number): boolean {
    const data = this.description.data[dataIndex];
    return !(isNullOrUndefined(data) || this.description.type[dataIndex] !== 'txt');
  }

  static mapRange (x: number, inMin: number, inMax: number, outMin: number, outMax: number): number {
    return (x - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  }

  copyIDToClipboard (id: string) {
    this.helperService.copyToClipboard(id);
  }

  copyURLToClipboard (id: string) {
    const url = `${environment.projectUrl}/descriptions/edit/${id}`;
    this.helperService.copyToClipboard(url);
  }

  ngOnDestroy (): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
