import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { checkUserStatus, logoutAttempt, openEditUserForm } from './core/ngrx/authentication/authentication.actions';
import { takeUntil } from 'rxjs/operators';
import { getAuthState } from './core/ngrx/authentication/authentication.selectors';
import { Subject } from 'rxjs';
import { EditorUser } from './core/ngrx/editorUsers/editorUsers.interfaces';
import { showAlert } from './core/ngrx/alerts/alerts.actions';

const versionInfo = `
  <section class="version-notes">
    <p>Esta versión trae la funcionalidad de <b>creación</b> y <b>edición</b> de flashcards<br>
      <br>
      Al crear una flashcard se puede añadir un ID, este ID <b>no</b> podrá ser actualizado posteriormente.
      <br><br>
      Ahora se puede editar el status de una pregunta en su vista de edición.
    </p>
  </section>
`;

@Component({
  selector: 'em-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  currentUser: EditorUser;
  $destroy: Subject<boolean> = new Subject<boolean>();
  version = '2.3.3';
  versionChanges = versionInfo;

  constructor (private store: Store<any>, private router: Router) {
    this.store.dispatch(checkUserStatus());
    this.setStateSubscriptions();
  }

  setStateSubscriptions () {
    this.store.pipe(
      takeUntil(this.$destroy),
      select(getAuthState),
    ).subscribe((authState) => {
      this.currentUser = authState ? authState.user : undefined;
    });
  }

  openSettings () {
    this.store.dispatch(openEditUserForm());
  }

  openInfoDialog () {
    this.store.dispatch(showAlert({
      alertType: 'info',
      title: `Versión ${this.version}`,
      message: this.versionChanges,
      html: true,
    }));
  }

  login () {
    return this.router.navigateByUrl('/login');
  }

  goToUsers () {
    return this.router.navigateByUrl('/users');
  }

  goToEditor () {
    return this.router.navigateByUrl('/editor');
  }

  logout () {
    this.store.dispatch(logoutAttempt());
  }

  ngOnDestroy (): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
