import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'em-html-text-previewer',
  templateUrl: './html-text-previewer.component.html',
  styleUrls: ['./html-text-previewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HtmlTextPreviewerComponent {
  htmlText: SafeHtml = '';

  constructor (private sanitizer: DomSanitizer) {}

  @Input('html') set setHtmlText (value: string) {
    this.htmlText = this.sanitizer.bypassSecurityTrustHtml(value);
  }

}
