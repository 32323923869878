<div class="loading" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!isLoading">
  <mat-card>
    <form [formGroup]="topicForm" class="filter-bar columns is-centered is-vcentered is-gapless" autocomplete="off">
      <div class="column is-4 is-flex align-items-center">
        <mat-form-field class="is-full-width">
          <input matInput placeholder="Añadir nueva materia" formControlName="newTopic">
          <mat-hint>Una vez creada la materia no se puede eliminar pero si editar.</mat-hint>
        </mat-form-field>
        <button mat-button mat-mini-fab color="primary" title="Añadir"
                *ngIf="topicForm.get('newTopic').value !== ''" (click)="addTopic()"><mat-icon>add</mat-icon></button>
        <button mat-button mat-mini-fab title="Añadir" *ngIf="topicForm.get('newTopic').value === ''"><mat-icon>add</mat-icon></button>
      </div>
      <div class="column is-4 is-flex align-items-center">
        <ng-container *ngIf="activeTopicProperty !== undefined || activeSubTopicProperty !== undefined">
          <mat-form-field class="is-full-width">
            <input matInput [placeholder]="activeTopicMessage" formControlName="updateTopic">
            <mat-hint *ngIf="activeTopicProperty !== undefined && isSubtopicCreation">Una vez creada la submateria no se puede eliminar pero si editar.</mat-hint>
          </mat-form-field>
          <button mat-button mat-mini-fab color="primary" title="Actualizar"
                  *ngIf="topicForm.get('updateTopic').value !== ''" (click)="updateTopic()"><mat-icon>check</mat-icon></button>
          <button mat-button mat-mini-fab title="Actualizar" *ngIf="topicForm.get('updateTopic').value === ''"><mat-icon>check</mat-icon></button>
          <button mat-button mat-mini-fab color="warn" title="Cancelar" (click)="cancelUpdate()"><mat-icon>close</mat-icon></button>
        </ng-container>
      </div>
      <div class="column is-4 is-flex flex-end">
        <button mat-stroked-button [color]="isDirty ? 'primary' : undefined" title="Subir cambios al servidor"
                [disabled]="!isDirty" (click)="uploadToServer()">Subir cambios al servidor <mat-icon>cloud_upload</mat-icon></button>
      </div>
    </form>
  </mat-card>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <span><button mat-icon-button disabled></button>
      <button mat-icon-button color="primary" (click)="edit(node)"><mat-icon class="mat-icon-rtl-mirror" title="Editar">edit</mat-icon></button>
      <button mat-icon-button color="accent" (click)="addSubTopic(node)" *ngIf="node.level === 0" title="Añadir submateria"><mat-icon
        class="mat-icon-rtl-mirror">add</mat-icon></button>
      {{node.name}}
    </span>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
    <span>
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="edit(node)"><mat-icon class="mat-icon-rtl-mirror" title="Editar">edit</mat-icon></button>
      <button mat-icon-button color="accent" (click)="addSubTopic(node)"><mat-icon class="mat-icon-rtl-mirror" title="Añadir submateria">add</mat-icon></button>
      {{node.name}}
    </span>
    </mat-tree-node>
  </mat-tree>
</div>
