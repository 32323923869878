<div class="main-container">
  <mat-card>
    <form class="filter-bar columns is-centered is-vcentered is-gapless" autocomplete="off">
      <div class="column is-6">
        <button mat-raised-button color="accent" (click)="openExplanationForm()">Nueva explicación</button>
      </div>
      <div class="column is-6" *ngIf="filteredExplanations" >
        <mat-paginator [length]="explanationsLength" [pageSize]="defaultMatPaginatorValue"
                       [pageSizeOptions]="[5, 10, 20, 50]" (page)="changePageFromDashboard($event)" [disabled]="isLoading"></mat-paginator>
      </div>
    </form>
  </mat-card>
  <ng-container *ngIf="!isLoading && !pageLoading">
    <div class="columns">
      <div class="column is-2 has-text-weight-bold is-flex flex-items-center flex-center">ID</div>
      <div class="column is-7 has-text-weight-bold is-flex flex-items-center flex-center">Título</div>
      <div class="column is-2 has-text-weight-bold is-flex flex-items-center flex-center">Información</div>
      <div class="column is-1 has-text-weight-bold is-flex flex-items-center flex-center">Acciones</div>
    </div>
    <div class="columns dataRow text-small" *ngFor="let explanation of filteredExplanations">
      <div class="column is-2 is-flex flex-items-center flex-start">
        <button mat-icon-button [matTooltip]="'Copiar ID'" matTooltipPosition="above" (click)="copyIDToClipboard(explanation.reference)">
          <mat-icon [color]="copiedId === helper.transformReferenceToId(explanation.reference) ? 'primary' : undefined">bookmarks</mat-icon>
        </button>
        <small>{{ helper.transformReferenceToId(explanation.reference) }}</small>
      </div>
      <div class="column is-7 is-flex flex-items-center flex-start">
        <h3>{{ explanation.title }}</h3>
      </div>
      <div class="column is-2 is-flex flex-items-center flex-space-between padding-small items-help">
        <figure class="image is-24x24" *ngIf="!explanation.isHumanGeo"></figure>
        <figure class="image is-24x24" *ngIf="explanation.isHumanGeo" [matTooltip]="'Explicación para Geometría Humana (Flashcards)'" matTooltipPosition="above">
          <img [src]="'assets/icons/iconsFlashcardB@3x.png'">
        </figure>
        <mat-icon [matTooltip]="(explanation.hasResources ? 'Si' : 'No') + ' tiene referencias'"
                  matTooltipPosition="above" [color]="explanation.hasResources ? 'primary' : undefined">menu_book</mat-icon>
        <mat-icon [matTooltip]="(explanation.hasFlashcards ? 'Si' : 'No') + ' tiene flashcards'"
                  matTooltipPosition="above" [color]="explanation.hasFlashcards ? 'primary' : undefined">view_carousel</mat-icon>
      </div>
      <div class="column is-1 is-flex flex-items-center flex-end">
        <button [ngClass]="{'button is-loading': explanation.loading}" mat-raised-button color="primary"
                (click)="openExplanationForm(explanation)" matTooltip="Editar" matTooltipPosition="above">
          <mat-icon matListIcon>edit</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <mat-spinner *ngIf="isLoading || pageLoading"></mat-spinner>
</div>
