import { Injectable } from '@angular/core';
import { Action, AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import { Question, QuestionCount, QuestionDocPreview, QuestionPreview, Status } from '../../core/ngrx/questions/questions.interfaces';
import { EditorUser } from '../../core/ngrx/editorUsers/editorUsers.interfaces';
import { Observable } from 'rxjs';
import * as _cloneDeep from 'lodash/cloneDeep';
import * as _uniq from 'lodash/uniq';
const Timestamp = firestore.Timestamp;

@Injectable({
  providedIn: 'root',
})
export class QuestionService {

  constructor (private fireStoreDB: AngularFirestore) { }

  getQuestionsCounters (): Observable<QuestionCount> {
    return this.fireStoreDB.doc(`${environment.projectAdminId}/counters/questions`).snapshotChanges().pipe(
      map((action: any): QuestionCount => {
        const data: any = action.payload.data();
        return data && data.counters ? data.counters : undefined;
      }),
    );
  }

  getQuestions (index: number): Observable<QuestionPreview[]> {
    return this.fireStoreDB.doc(`${environment.projectAdminId}/counters/questions/previews/ques_${index}`).snapshotChanges().pipe(map(
      (a: any) => {
        const preview: QuestionDocPreview = a.payload.data();
        return preview ? preview.data : [];
      }),
    );
  }

  getQuestion (reference: firestore.DocumentReference | string): Observable<Question> {
    const path = reference instanceof firestore.DocumentReference ? reference.path : reference;
    return this.fireStoreDB.doc<Question>(path).snapshotChanges().pipe(map(
      (a: Action<DocumentSnapshot<Question>>) => {
        return a.payload.data();
      }),
    );
  }

  getQuestionId (reference: firestore.DocumentReference | string): string {
    if (reference instanceof firestore.DocumentReference) {
      return reference.id;
    }
    return this.fireStoreDB.doc(reference).ref.id;
  }

  saveQuestion (question: Question, user: EditorUser): Promise<void> {
    const id = question.id ? question.id : this.fireStoreDB.createId();
    const loggedUser = user.email;
    let newQuestion = <Question>_cloneDeep(question);
    if (newQuestion.descriptionReference) {
      newQuestion.descriptionReference = this.generateReference(newQuestion.descriptionReference);
    }
    if (newQuestion.explanationReference) {
      newQuestion.explanationReference = this.generateReference(newQuestion.explanationReference);
    }
    if (!newQuestion.created) {
      newQuestion = { ...newQuestion, created: Timestamp.now() };
      newQuestion.audit = { ...newQuestion.audit, created: Timestamp.now(), createdBy: loggedUser };
    } else {
      newQuestion.created = new Timestamp(newQuestion.created.seconds, newQuestion.created.nanoseconds);
      if (newQuestion.audit && newQuestion.audit.created) {
        newQuestion.audit.created = new Timestamp(newQuestion.audit.created.seconds, newQuestion.audit.created.nanoseconds);
      } else {
        newQuestion.audit = { ...newQuestion.audit, created: Timestamp.now(), createdBy: loggedUser };
      }
    }
    newQuestion = { ...newQuestion, lastUpdated: Timestamp.now(), lastUpdatedBy: loggedUser };
    delete newQuestion.id;
    return this.fireStoreDB.doc(`${environment.projectId}/questions/${id}`).set(newQuestion);
  }

  generateReference (reference: firestore.DocumentReference | string): firestore.DocumentReference {
    if (reference instanceof firestore.DocumentReference) {
      return reference;
    }
    return this.fireStoreDB.doc(reference).ref;
  }

  publishQuestion (question: Question, user: EditorUser): Promise<void> {
    const currentQuestion = <Question>_cloneDeep(question);
    const id = currentQuestion.id;
    currentQuestion.status = 'published';
    currentQuestion.audit = { ...currentQuestion.audit, published: Timestamp.now(), publishedBy: user.email };
    delete currentQuestion.id;
    return this.fireStoreDB.doc(`${environment.projectId}/questions/${id}`).set(currentQuestion);
  }

  unpublishQuestion (question: Question, user: EditorUser): Promise<void> {
    const currentQuestion = <Question>_cloneDeep(question);
    const id = currentQuestion.id;
    currentQuestion.status = 'unpublished';
    currentQuestion.audit = { ...currentQuestion.audit, unpublished: Timestamp.now(), unpublishedBy: user.email };
    delete currentQuestion.id;
    return this.fireStoreDB.doc(`${environment.projectId}/questions/${id}`).set(currentQuestion);
  }

  deleteQuestion (question: Question, user: EditorUser): Promise<void> {
    const currentQuestion = <Question>_cloneDeep(question);
    const id = currentQuestion.id;
    currentQuestion.status = 'deleted';
    currentQuestion.audit = { ...currentQuestion.audit, deleted: Timestamp.now(), deletedBy: user.email };
    delete currentQuestion.id;
    return this.fireStoreDB.doc(`${environment.projectId}/questions/${id}`).set(currentQuestion);
  }

}
