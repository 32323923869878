import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  cancelledRequest,
  loadDescriptions,
  loadDescriptionsCount,
  loadDescriptionsCountFail,
  loadDescriptionsCountSuccess,
  loadDescriptionsFail,
  loadDescriptionsSuccess, loadNewDescriptionsPage, loadNewDescriptionsPageFail, loadNewDescriptionsPageSuccess,
  openDescriptionForm,
  openDescriptionFormFail,
  openDescriptionFormSuccess,
  saveDescription,
  saveDescriptionFail,
  saveDescriptionSuccess, saveDescriptionWithImages, saveDescriptionWithImagesFail, saveDescriptionWithImagesSuccess,
} from './descriptions.actions';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { DescriptionService } from '../../../services/Description/description.service';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { showAlert } from '../alerts/alerts.actions';
import { firestore } from 'firebase/app';
import { Store } from '@ngrx/store';
import { getAuthState } from '../authentication/authentication.selectors';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material';
import { DescriptionFormComponent } from '../../../components/description-components/description-form/description-form.component';
import { Description } from './descriptions.interfaces';
import { ImageService } from '../../../services/Image/image.service';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { getDescriptionsState } from './descriptions.selectors';
import { HelperService } from '../../../services/Helper/helper.service';

@Injectable() export class DescriptionsEffects {

  constructor (
    private store: Store<any>,
    private actions$: Actions,
    private imageService: ImageService,
    private helperService: HelperService,
    private descriptionService: DescriptionService,
    private fireStoreDB: AngularFirestore,
    private dialog: MatDialog,
  ) {}

  loadDescriptions$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadDescriptions),
      mergeMap((action) => {
        const observables = combineLatest(
          [
            this.store.select(getAuthState).pipe(take(1)),
            this.store.select(getDescriptionsState).pipe(take(1)),
          ],
        );
        return observables.pipe(mergeMap(([authState, descriptionState]) => {
          const index = action.paginateVal;
          let indexCorrected = index;
          if (authState.user.newToOld) {
            indexCorrected = descriptionState.previewsMaxIndex;
            for (let i = 1; i <= index; i += 1) {
              indexCorrected -= 1;
            }
          }
          return this.descriptionService.getDescriptions(indexCorrected).pipe(
            map((previews) => {
              const descriptionPreviews = previews.map((p) => {
                if (p.reference instanceof firestore.DocumentReference) {
                  p.reference = p.reference.path;
                }
                return p;
              });
              if (authState.user.newToOld) {
                descriptionPreviews.reverse();
              }
              return loadDescriptionsSuccess({ descriptionPreviews, index });
            }),
            catchError((error) => {
              return this.store.select(getAuthState).pipe(take(1), map((authState) => {
                if (authState && authState.user) {
                  return loadDescriptionsFail({ error });
                }
                return cancelledRequest();
              }));
            }),
          );
        }));
      }),
    ),
    { resubscribeOnError: false },
  );

  loadNewDescriptionsPage$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadNewDescriptionsPage),
      mergeMap((action) => {
        return this.store.select(getDescriptionsState).pipe(take(1)).pipe(mergeMap((descriptionState) => {
          const index = descriptionState.previewsMaxIndex;
          return this.descriptionService.getDescriptions(index).pipe(
            map((previews) => {
              const descriptionPreviews = previews.map((p) => {
                if (p.reference instanceof firestore.DocumentReference) {
                  p.reference = p.reference.path;
                }
                return p;
              });
              descriptionPreviews.reverse();
              return loadNewDescriptionsPageSuccess({ descriptionPreviews, index });
            }),
            catchError((error) => {
              return this.store.select(getAuthState).pipe(take(1), map((authState) => {
                if (authState && authState.user) {
                  return loadNewDescriptionsPageFail({ error });
                }
                return cancelledRequest();
              }));
            }),
          );
        }));
      }),
    ),
    { resubscribeOnError: false },
  );

  loadDescriptionsCount$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadDescriptionsCount),
      mergeMap(() => {
        return this.descriptionService.getDescriptionsSize().pipe(
          map((descriptionsTotalLength) => {
            const paginationVal = environment.paginateVal;
            const descriptionsMaxIndex = Math.floor((descriptionsTotalLength - 1) / paginationVal);
            return loadDescriptionsCountSuccess({ descriptionsTotalLength, descriptionsMaxIndex });
          }),
          catchError((error) => {
            return this.store.select(getAuthState).pipe(take(1), map((authState) => {
              if (authState && authState.user) {
                return loadDescriptionsFail({ error });
              }
              return cancelledRequest();
            }));
          }),
        );
      }),
    ),
    { resubscribeOnError: false },
  );

  openDescriptionForm$ = createEffect(() => this.actions$.pipe(
    ofType(openDescriptionForm),
    mergeMap((action) => {
      if (action.preview === undefined) {
        const dialogRef = this.dialog.open(DescriptionFormComponent, {
          width: '1110px',
          disableClose: true,
        });
        dialogRef.beforeClosed().subscribe(() => {
          this.helperService.navigateBack();
        });
        return of(openDescriptionFormSuccess({ description: undefined }));
      }
      const reference = typeof action.preview === 'string'
        ? `${environment.projectId}/descriptions/${action.preview}`
        : action.preview.reference
      ;
      return this.descriptionService.getDescription(reference).pipe(
        take(1),
        map((description: Description) => {
          description.id = this.descriptionService.getDescriptionId(reference);
          const dialogRef = this.dialog.open(DescriptionFormComponent, {
            width: '1110px',
            disableClose: true,
          });
          dialogRef.beforeClosed().subscribe(() => {
            this.helperService.navigateBack();
          });
          return openDescriptionFormSuccess({ description });
        }),
        catchError((error) => {
          return of(openDescriptionFormFail({ error }));
        }),
      );
    }),
  ));

  saveDescription$ = createEffect(() => this.actions$.pipe(
    ofType(saveDescription),
    mergeMap((action) => {
      if (action.imagesToDelete.length > 0) {
        action.imagesToDelete.forEach((image) => {
          this.imageService.deleteImage(image.name, 'descriptions');
        });
      }
      return from(this.descriptionService.saveDescription(action.description)).pipe(
        map((id) => {
          return saveDescriptionSuccess({ id });
        }),
        catchError((error) => {
          return of(saveDescriptionFail({ error }));
        }),
      );
    }),
  ));

  saveDescriptionWithImages$ = createEffect(() => this.actions$.pipe(
    ofType(saveDescriptionWithImages),
    mergeMap((action) => {
      const descToSave: Description = _.cloneDeep(action.description);
      if (action.imagesToDelete.length > 0) {
        action.imagesToDelete.forEach((image) => {
          this.imageService.deleteImage(image.name, 'descriptions');
        });
      }
      const observables: Observable<UploadTaskSnapshot>[] = action.imagesToUpload.map((img) => {
        return from(this.imageService.uploadImage(img, 'descriptions'));
      });
      return forkJoin(observables).pipe(
        mergeMap((imageResults: UploadTaskSnapshot[]) => {
          let imageResIndex = 0;
          descToSave.type.forEach((t, i) => {
            if (t === 'preview') {
              descToSave.data[i] = imageResults[imageResIndex].metadata.name;
              descToSave.type[i] = 'img';
              imageResIndex = imageResIndex + 1;
            }
          });
          return from(this.descriptionService.saveDescription(descToSave)).pipe(
            map(() => {
              return saveDescriptionWithImagesSuccess({ id: descToSave.id });
            }),
            catchError((error) => {
              return of(saveDescriptionWithImagesFail({ error }));
            }),
          );
        }),
        catchError((error) => {
          return of(saveDescriptionWithImagesFail({ error }));
        }),
      );
    }),
  ));

  dscriptionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(saveDescriptionSuccess, saveDescriptionWithImagesSuccess),
    mergeMap((action) => {
      return of(showAlert(
        {
          toast: true,
          position: 'bottom-end',
          showConfirm: false,
          showCancel: false,
          timer: 10000,
          alertType: 'success',
          title: `Descripción con ID ${action.id} correctamente guardada.`,
        },
      ));
    }),
  ));

  descriptionsErrors$ = createEffect(() => this.actions$.pipe(
    ofType(loadDescriptionsFail, loadDescriptionsCountFail, openDescriptionFormFail, saveDescriptionFail, saveDescriptionWithImagesFail),
    mergeMap((action) => {
      return of(showAlert({ alertType: 'error', error: action.error, title: 'Oops...' }));
    }),
  ));

}
