import { Injectable } from '@angular/core';
import { Action, AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { firestore } from 'firebase/app';
import {
  Explanation,
  ExplanationCount,
  ExplanationDocPreview,
  ExplanationPreview,
} from '../../core/ngrx/explanations/explanations.interfaces';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { dataType } from '../../core/ngrx/dataTypes/dataTypes.interfaces';
import { fromPromise } from 'rxjs/internal-compatibility';
const Timestamp = firestore.Timestamp;

@Injectable({
  providedIn: 'root',
})
export class ExplanationService {

  private bannedText: string[] = [
    'Introducción',
    'Epidemiología',
    'Cuadro clínico',
    'Fisiopatología',
    'Tratamiento',
    'Pronóstico',
    'Abordaje y diagnóstico',
  ];

  constructor (private fireStoreDB: AngularFirestore) { }

  getExplanationsSize (): Observable<number> {
    return this.fireStoreDB.doc(`${environment.projectAdminId}/counters/explanations`).snapshotChanges().pipe(map(
      (action: any) => {
        const data: ExplanationCount = action.payload.data();
        return data ? data.totalCount : 0;
      }));
  }

  getExplanations (index: number): Observable<ExplanationPreview[]> {
    return this.fireStoreDB.doc(`${environment.projectAdminId}/counters/explanations/previews/expl_${index}`).snapshotChanges().pipe(
      map((a: any) => {
        const preview: ExplanationDocPreview = a.payload.data();
        return preview ? preview.data : [];
      }),
    );
  }

  getExplanation (reference: firestore.DocumentReference | string): Observable<Explanation> {
    const path = reference instanceof firestore.DocumentReference ? reference.path : reference;
    const doc = this.fireStoreDB.doc<Explanation>(path);
    if (!doc.ref) {
      return of(undefined);
    }
    return doc.snapshotChanges().pipe(map(
      (a: Action<DocumentSnapshot<Explanation>>) => {
        return a.payload.data();
      }),
    );
  }

  getExplanationId (reference: firestore.DocumentReference | string): string {
    if (reference instanceof firestore.DocumentReference) {
      return reference.id;
    }
    return this.fireStoreDB.doc(reference).ref.id;
  }

  saveExplanation (explanation: Explanation): Observable<string> {
    const id = explanation.id ? explanation.id : this.fireStoreDB.createId();
    let newExplanation: Explanation = _.cloneDeep(explanation);
    if (this.bannedText.indexOf(newExplanation.title) !== -1) {
      newExplanation.title = this.processBannedText(newExplanation.title, newExplanation.data, newExplanation.type);
    }
    if (!newExplanation.created) {
      newExplanation = { ...newExplanation, created: Timestamp.now() };
    } else {
      newExplanation.created = new Timestamp(newExplanation.created.seconds, newExplanation.created.nanoseconds);
    }
    newExplanation = { ...newExplanation, updated: Timestamp.now() };
    delete newExplanation.id;
    const observable = fromPromise(this.fireStoreDB.doc(`${environment.projectId}/explanations/${id}`).set(newExplanation));
    return observable.pipe(map(() => id));
  }

  private processBannedText (title: string, content: string[], type: dataType[]): string {
    const nonBannedText = content.filter((text, i) => {
      return type[i] === 'txt' && this.bannedText.indexOf(text) === -1;
    });
    if (nonBannedText.length === 0) {
      return title;
    }
    return `${title}: ${nonBannedText[0]}`;
  }

}
