<div class="columns is-vcentered is-centered">
  <div class="column is-2">
    <img class="em-icon" src="../../../../assets/icon_512.png">
  </div>
  <div class="column is-6">
    <p class="is-size-1 has-text-danger">401</p>
    <p class="is-size-3 has-text-danger">Página no autorizada</p>

    <p class="has-text-danger">
      Parece ser que no tienes los permisos suficientes para accesar lo que estabas buscando,
      contacta a tu administrador para más información.
    </p>
  </div>
</div>
