import { Component, OnDestroy } from '@angular/core';
import { filter, take, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { loadDescriptions, loadDescriptionsCount, openDescriptionForm } from '../../core/ngrx/descriptions/descriptions.actions';
import { select, Store } from '@ngrx/store';
import { getDescriptionsState } from '../../core/ngrx/descriptions/descriptions.selectors';
import { loadExplanations, loadExplanationsCount, openExplanationForm } from '../../core/ngrx/explanations/explanations.actions';
import { getTopicsState } from '../../core/ngrx/topics/topics.selectors';
import { loadTopics } from '../../core/ngrx/topics/topics.actions';
import { loadQuestions, loadQuestionsCount, openQuestionForm, openQuestionView } from '../../core/ngrx/questions/questions.actions';
import { getExplanationsState } from '../../core/ngrx/explanations/explanations.selectors';
import { getQuestionsState } from '../../core/ngrx/questions/questions.selectors';
import { ActivatedRoute } from '@angular/router';
import { showAlert } from '../../core/ngrx/alerts/alerts.actions';
import { HelperService } from '../../services/Helper/helper.service';
import { getDashboardState } from '../../core/ngrx/dashboard/dashboard.selectors';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { changeActiveTab } from '../../core/ngrx/dashboard/dashboard.actions';
import { getFlashcardsState } from '../../core/ngrx/flashcards/flashcards.selectors';
import { loadFlashcards, loadFlashcardsCount, openFlashcardForm } from '../../core/ngrx/flashcards/flashcards.actions';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'em-edit-questions',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnDestroy {

  $destroy: Subject<boolean> = new Subject<boolean>();
  initialLoadingDesc: Subject<boolean> = new Subject<boolean>();
  initialLoadingCards: Subject<boolean> = new Subject<boolean>();
  initialLoadingExpl: Subject<boolean> = new Subject<boolean>();
  initialLoadingQues: Subject<boolean> = new Subject<boolean>();

  activeTabIndex = 0;
  topics: Object;

  constructor (private store: Store<any>, private helperService: HelperService, private route: ActivatedRoute) {
    const initialSubscription = combineLatest([
      this.initialLoadingDesc,
      this.initialLoadingExpl,
      this.initialLoadingQues,
      this.initialLoadingCards,
    ]).pipe(takeUntil(this.$destroy), filter(([d, e, q, f]) => d && e && q && f)).subscribe(() => {
      initialSubscription.unsubscribe();
      // tslint:disable-next-line:no-magic-numbers
      const isValidEditionPath = this.route.snapshot.url.length === 3 && this.route.snapshot.url[2].path;
      // tslint:disable-next-line:no-magic-numbers
      const isValidCreationPath = this.route.snapshot.url.length === 2 && this.route.snapshot.url[1].path === 'create';
      if ((isValidEditionPath || isValidCreationPath) && !this.helperService.isNavigatedBack) {
        const routeSnapshot = this.route.snapshot.url;
        let id = undefined;
        // tslint:disable-next-line:no-magic-numbers
        if (routeSnapshot.length === 3) id = routeSnapshot[2].path;
        this.openForm(routeSnapshot[0].path, routeSnapshot[1].path, id);
      }
    });
    this.runInitialDispatchers();
  }

  runInitialDispatchers () {
    this.store.pipe(
      takeUntil(this.initialLoadingDesc),
      select(getDescriptionsState),
      filter(state => !state.loading),
    ).subscribe((descriptions) => {
      if (descriptions && descriptions.previewsIndexLoaded === -1) {
        if (descriptions.previewsMaxIndex === -1) {
          this.store.dispatch(loadDescriptionsCount());
        } else {
          this.store.dispatch(loadDescriptions({ paginateVal: 0 }));
        }
      } else {
        this.initialLoadingDesc.next(true);
        this.initialLoadingDesc.unsubscribe();
      }
    });
    this.store.pipe(
      takeUntil(this.initialLoadingExpl),
      select(getExplanationsState),
      filter(state => !state.loading),
    ).subscribe((explanations) => {
      if (explanations && explanations.previewsIndexLoaded === -1) {
        if (explanations.previewsMaxIndex === -1) {
          this.store.dispatch(loadExplanationsCount());
        } else {
          this.store.dispatch(loadExplanations({ paginateVal: 0 }));
        }
      } else {
        this.initialLoadingExpl.next(true);
        this.initialLoadingExpl.unsubscribe();
      }
    });
    this.store.pipe(
      takeUntil(this.initialLoadingQues),
      select(getQuestionsState),
      filter(state => !state.loading),
    ).subscribe((questions) => {
      if (questions && questions.previewsIndexLoaded === -1) {
        if (questions.previewsMaxIndex === -1) {
          this.store.dispatch(loadQuestionsCount());
        } else {
          this.store.dispatch(loadQuestions({ paginateVal: 0 }));
        }
      } else {
        this.initialLoadingQues.next(true);
        this.initialLoadingQues.unsubscribe();
      }
    });
    this.store.pipe(
      takeUntil(this.initialLoadingCards),
      select(getFlashcardsState),
      filter(state => !state.loading),
    ).subscribe((flashCards) => {
      if (flashCards && flashCards.previewsIndexLoaded === -1) {
        if (flashCards.previewsMaxIndex === -1) {
          this.store.dispatch(loadFlashcardsCount());
        } else {
          this.store.dispatch(loadFlashcards({ paginateVal: 0 }));
        }
      } else {
        this.initialLoadingCards.next(true);
        this.initialLoadingCards.unsubscribe();
      }
    });
    this.store.pipe(take(1), select(getTopicsState)).subscribe((topicsState) => {
      if (isNullOrUndefined(topicsState.topicList)) {
        this.store.dispatch(loadTopics());
      }
    });
    this.store.pipe(take(1), select(getDashboardState)).subscribe((dashboardState) => {
      const tabs = {
        Questions: 0,
        Descriptions: 1,
        Explanations: 2,
        Topics: 3,
        IdGenerator: 4,
        Flashcards: 5,
      };
      this.activeTabIndex = !isNaN(tabs[dashboardState.activeTab]) ? tabs[dashboardState.activeTab] : 0;
    });
  }

  openForm (form: string, type: string, id: string) {
    switch (form) {
      case 'descriptions':
        return this.store.dispatch(openDescriptionForm({ preview: id }));
      case 'explanations':
        return this.store.dispatch(openExplanationForm({ preview: id }));
      case 'flashcards':
        return this.store.dispatch(openFlashcardForm({ preview: id }));
      case 'questions':
        if (type === 'edit' || type === 'create') {
          return this.store.dispatch(openQuestionForm({ preview: id }));
        }
        return this.store.dispatch(openQuestionView({ preview: id }));
      default:
        return this.store.dispatch(showAlert({ alertType: 'error', error: `Recurso "${form}:${id}" no encontrado`, title: 'Oops...' }));
    }
  }

  changedTab (event: MatTabChangeEvent) {
   switch (event.tab.textLabel) {
     case 'Preguntas':
       return this.store.dispatch(changeActiveTab({ tab: 'Questions' }));
     case 'Descripciones':
       return this.store.dispatch(changeActiveTab({ tab: 'Descriptions' }));
     case 'Explicaciones':
       return this.store.dispatch(changeActiveTab({ tab: 'Explanations' }));
     case 'Materias':
       return this.store.dispatch(changeActiveTab({ tab: 'Topics' }));
     case 'Generar ID':
       return this.store.dispatch(changeActiveTab({ tab: 'IdGenerator' }));
     case 'Flashcards':
       return this.store.dispatch(changeActiveTab({ tab: 'Flashcards' }));
   }
  }

  ngOnDestroy () {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
