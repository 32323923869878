<div mat-dialog-title class="columns is-gapless">
  <div class="column is-1" *ngIf="!viewForm.get('mobile').value">
    <button mat-icon-button [matTooltip]="'Copiar ID de la pregunta seleccionada'"
            matTooltipPosition="above" (click)="copyIDToClipboard(question.id)">
      <mat-icon color="primary">bookmarks</mat-icon>
    </button>
    <button mat-icon-button [matTooltip]="'Copiar URL de la pregunta seleccionada'"
            matTooltipPosition="above" (click)="copyURLToClipboard(question.id)">
      <mat-icon color="primary">link</mat-icon>
    </button>
  </div>
  <div class="column" [ngClass]="!viewForm.get('mobile').value ? 'is-11' : 'is-12'">
    <div mat-dialog-title class="is-flex flex-space-between" [ngClass]="{ 'flex-direction-column': !!viewForm.get('mobile').value }">
      <h1 [matTooltip]="question.title" matTooltipPosition="below">Ver pregunta "{{ question.title }}"</h1>
      <div>
        <form [formGroup]="viewForm">
          <mat-slide-toggle formControlName="mobile" color="primary">Simular vista de móvil</mat-slide-toggle>
        </form>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-content>
  <mat-spinner class="initialLoading" *ngIf="loading || resourcesLoading || imagesAreLoading()"></mat-spinner>
  <section *ngIf="!loading && !resourcesLoading" [ngClass]="{ hidden: imagesAreLoading() }">
    <h1 class="title is-5 has-text-primary margin-top-big">Planteamiento</h1>
    <div *ngIf="description">
      <ng-container *ngFor="let content of description.data; let i = index">
        <div class="has-text-justified" *ngIf="description.type[i] === 'txt'" matLine>
          <em-html-text-previewer [html]="content"></em-html-text-previewer>
        </div>
        <div *ngIf="description.type[i] === 'img'">
          <img [src]="getImageFromName(content)" (load)="finishedLoadingImage(content)">
        </div>
      </ng-container>
    </div>
    <div class="has-text-danger" *ngIf="!description">Sin planteamiento</div>
    <h1 class="title is-5 has-text-primary margin-top-big">Pregunta</h1>
    <ng-container *ngFor="let content of question.data; let i = index">
      <p class="has-text-justified" *ngIf="question.type[i] === 'txt'">{{content}}</p>
      <div *ngIf="question.type[i] === 'img'">
        <img [src]="getImageFromName(content)" (load)="finishedLoadingImage(content)">
      </div>
    </ng-container>
    <section class="margin-top-big">
      <div class="message" *ngFor="let option of getAnswers()"
           [ngClass]="{ 'is-success': isCorrectAnswer(option), 'is-primary': !isCorrectAnswer(option) }">
        <div class="message-body">
          <div *ngFor="let content of option.data; let i = index">
            <p class="has-text-justified" *ngIf="option.type[i] === 'txt'">{{content}}</p>
            <div *ngIf="question.type[i] === 'img'">
              <img [src]="getImageFromName(content)" (load)="finishedLoadingImage(content)">
            </div>
          </div>
        </div>
      </div>
    </section>
    <h1 class="title is-5 has-text-primary margin-top-big">Explicación</h1>
    <div *ngIf="explanation">
      <ng-container *ngFor="let content of explanation.data; let i = index">
        <p class="is-flex align-items-center is-size-5 color-primary" *ngIf="explanation.type[i] === 'txt' && isHeadingText(content)">
          <figure class="image is-32x32"><img [src]="getIconSrc(content)"></figure>
          {{content}}
        </p>
        <div class="has-text-justified" *ngIf="explanation.type[i] === 'txt' && !isHeadingText(content)" matLine>
          <em-html-text-previewer [html]="content"></em-html-text-previewer>
        </div>
        <div *ngIf="explanation.type[i] === 'img'">
          <img [src]="getImageFromName(content)" (load)="finishedLoadingImage(content)">
        </div>
      </ng-container>
    </div>
    <div class="has-text-danger" *ngIf="!explanation">Sin explicación</div>
    <ng-container *ngIf="explanation && explanation.resources && explanation.resources.length > 0">
      <h1 class="title is-5 has-text-primary margin-top-big">Recursos</h1>
      <div class="message is-dark" *ngFor="let resource of explanation.resources">
        <div class="message-body columns is-vcentered" style="margin: 0; padding: 0;">
          <div class="column is-1 is-flex flex-center" *ngIf="resource.type === 'pdf'" matTooltip="PDF" matTooltipPosition="left">
            <mat-icon color="primary">picture_as_pdf</mat-icon>
          </div>
          <div class="column is-1 is-flex flex-center" *ngIf="resource.type === 'link'" matTooltip="Link" matTooltipPosition="left">
            <mat-icon color="primary">link</mat-icon>
          </div>
          <div class="column is-1 is-flex flex-center" *ngIf="resource.type === 'bibliography'" matTooltip="Bibliografía" matTooltipPosition="left">
            <mat-icon color="primary">book</mat-icon>
          </div>
          <div class="column is-11" *ngIf="resource.type === 'bibliography'">{{resource.title}}</div>
          <div class="column is-11" *ngIf="resource.type !== 'bibliography'">
            <a [href]="resource.url" target="_blank" [matTooltip]="'Seguir URL: ' + resource.url" matTooltipPosition="above">{{resource.title}}</a>
          </div>
        </div>
      </div>
    </ng-container>

  </section>
</div>

<div mat-dialog-actions align="end">
  <button [disabled]="loading" mat-raised-button (click)="close()" color="accent">Regresar</button>
  <button [disabled]="loading" mat-raised-button (click)="edit()" color="primary">Editar</button>
</div>
