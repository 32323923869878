import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../../environments/environment';
import { from, Observable, of } from 'rxjs';
import { NgxImageCompressService } from 'ngx-image-compress';
import { catchError, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ImageService {

  constructor (
    private fireStoreDB: AngularFirestore,
    private storage: AngularFireStorage,
    private imageCompressService: NgxImageCompressService,
  ) { }

  uploadImage (image: string, section: string): AngularFireUploadTask {
    const picId = this.fireStoreDB.createId();
    const extension = image.indexOf('data:image/png') === 0 ? 'png' : 'jpg';
    return this.storage.ref(`${environment.projectId}/${section}/${picId}.${extension}`).putString(image, 'data_url');
  }

  deleteImage (name: string, section: string): Observable<any> {
    return this.storage.ref(`${environment.projectId}/${section}/${name}`).delete();
  }

  getImageRef (name: string, section: string): Observable<any> {
    return this.storage.ref(`${environment.projectId}/${section}/${name}`).getDownloadURL();
  }

  compressImage (fileInput: string, orientation: number, ratioPercent: number, qualityPercent: number): Observable<string> {
    const originalSize = this.imageCompressService.byteCount(fileInput);
    return from(this.imageCompressService.compressFile(fileInput, orientation, ratioPercent, qualityPercent)).pipe(
      take(1),
      tap((image) => {
        const updatedSize = this.imageCompressService.byteCount(image);
        const percent = 100;
        const decimals = 4;
        const compressionPercent = percent - (updatedSize * percent / originalSize);
        console.info(`Compressed, from ${originalSize} bytes to ${updatedSize} bytes` +
                       `(compressed ${compressionPercent.toFixed(decimals)}%) with ratio ${ratioPercent}%, and quality ${qualityPercent}%`);
      }),
      catchError((error) => {
        console.info('Error while converting', error);
        return of(error);
      }),
    );
  }

  static mapRange (x: number, inMin: number, inMax: number, outMin: number, outMax: number): number {
    return (x - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  }

}
