import { Component, ViewEncapsulation } from '@angular/core';
import * as _cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'em-html-text-editor',
  templateUrl: './html-text-editor.component.html',
  styleUrls: ['./html-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HtmlTextEditorComponent {
  public data = '';
  public configuration = {
    toolbarGroups: [
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'blocks'] },
      // { name: 'document', groups: ['mode'] },
      { name: 'styles', groups: ['styles'] },
      { name: 'colors', groups: ['colors'] },
    ],
    stylesSet: [
      { name: 'Texto muy pequeño', element: 'font', attributes: { size: '1' } },
      { name: 'Texto pequeño', element: 'font', attributes: { size: '2' } },
      { name: 'Texto normal', element: 'font', attributes: { size: '3' } },
      { name: 'Texto grande', element: 'font', attributes: { size: '4' } },
      { name: 'Texto muy grande', element: 'font', attributes: { size: '5' } },
    ],
    coreStyles_bold: { element: 'b', overrides: 'strong' },
    coreStyles_italic: { element: 'i', overrides: 'em' },
    format_tags: 'p;h1',
    format_h1: { element: 'h3' },
    colorButton_colors: 'FF5359,064DFF,00AB4E,FF6C14',
    colorButton_backStyle: { element: 'span', styles: { 'background-color': '#(color)' } },
    colorButton_foreStyle: { element: 'font', attributes: { color: '#(color)' } },
    colorButton_enableAutomatic: false,
    removePlugins: 'blockquote',
    removeButtons: '',
    extraPlugins: 'colorbutton',
  };

  constructor () {}

  getContent (): string {
    const data: string = (_cloneDeep(this.data))
      .replace(/^\n|\n$/g, '')
    ;
    const replacedData: string = (<string>_cloneDeep(this.data))
      .replace('<p>', '')
      .replace('</p>', '')
    ;
    if (data.startsWith('<p>') && data.endsWith('</p>') && replacedData.indexOf('<p>') === -1 && replacedData.indexOf('<h3>') === -1) {
      return replacedData;
    }
    return `<!DOCTYPE html><html>${data}</html>`;
  }

  setContent (content: string): void {
    this.data = _cloneDeep(content);
  }

  parseHtmlForTitle (html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    if (doc.body.textContent) {
      const textString = doc.body.textContent;
      if (textString.includes('\n')) {
        return textString.substring(0, textString.indexOf('\n'));
      }
      return textString;
    }
    return '';
  }

}
