import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NotFoundComponent } from './components/permission-components/not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomMaterialModule } from './mat.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DescriptionFormComponent } from './components/description-components/description-form/description-form.component';
import { ExplanationFormComponent } from './components/explanation-components/explanation-form/explanation-form.component';
import { DescriptionDashboardComponent } from './components/description-components/description-dashboard/description-dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxImageCompressService } from 'ngx-image-compress';
import { DescriptionService } from './services/Description/description.service';
import { ExplanationService } from './services/Explanation/explanation.service';
import { ImageService } from './services/Image/image.service';
import { QuestionService } from './services/Question/question.service';
import { TopicService } from './services/Topic/topic.service';
import { UserService } from './services/User/user.service';
import { ExplanationDashboardComponent } from './components/explanation-components/explanation-dashboard/explanation-dashboard.component';
import { TopicDashboardComponent } from './components/topic-components/topic-dashboard/topic-dashboard.component';
import { QuestionDashboardComponent } from './components/question-components/question-dashboard/question-dashboard.component';
import { QuestionFormComponent } from './components/question-components/question-form/question-form.component';
import { LoginComponent } from './components/permission-components/login/login.component';
import { NotAllowedComponent } from './components/permission-components/not-allowed/not-allowed.component';
import { UserEditComponent } from './components/user-components/user-edit/user-edit.component';
import { UserFormComponent } from './components/user-components/user-form/user-form.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { descriptionReducer } from './core/ngrx/descriptions/descriptions.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DescriptionsEffects } from './core/ngrx/descriptions/descriptions.effects';
import { AlertsEffects } from './core/ngrx/alerts/alerts.effects';
import { authenticationReducer, clearStateOnLogoutReducer } from './core/ngrx/authentication/authentication.reducer';
import { AuthenticationEffects } from './core/ngrx/authentication/authentication.effects';
import { usersReducer } from './core/ngrx/editorUsers/editorUsers.reducer';
import { EditorUsersEffects } from './core/ngrx/editorUsers/editorUsers.effects';
import { explanationReducer } from './core/ngrx/explanations/explanations.reducer';
import { ExplanationsEffects } from './core/ngrx/explanations/explanations.effects';
import { topicReducer } from './core/ngrx/topics/topics.reducer';
import { TopicsEffects } from './core/ngrx/topics/topics.effects';
import { questionReducer } from './core/ngrx/questions/questions.reducer';
import { QuestionsEffects } from './core/ngrx/questions/questions.effects';
import { QuestionViewComponent } from './components/question-components/question-view/question-view.component';
import { HtmlTextPreviewerComponent } from './components/em-shared/html-text-previewer/html-text-previewer.component';
import { HtmlTextEditorComponent } from './components/em-shared/html-text-editor/html-text-editor.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { ReferenceFormComponent } from './components/explanation-components/reference-form/reference-form.component';
import { FlashcardDashboardComponent } from './components/flashcard-components/flashcard-dashboard/flashcard-dashboard.component';
import { dashboardReducer } from './core/ngrx/dashboard/dashboard.reducer';
import { FlashcardEditionFormComponent } from './components/flashcard-components/flashcard-form/flashcard-form.component';
import { FlashcardFormComponent } from './components/explanation-components/flashcard-form/flashcard-form.component';
import { flashcardReducer } from './core/ngrx/flashcards/flashcards.reducer';
import { FlashcardsEffects } from './core/ngrx/flashcards/flashcards.effects';
import {
  FlashcardExplanationFormComponent,
} from './components/flashcard-components/flashcard-explanation-form/flashcard-explanation-form.component';
import { FlashcardIdGeneratorComponent } from './components/flashcard-components/flashcard-id-generator/flashcard-id-generator.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    DashboardComponent,
    DescriptionFormComponent,
    ExplanationFormComponent,
    DescriptionDashboardComponent,
    ExplanationDashboardComponent,
    TopicDashboardComponent,
    QuestionDashboardComponent,
    QuestionFormComponent,
    LoginComponent,
    NotAllowedComponent,
    UserEditComponent,
    UserFormComponent,
    QuestionViewComponent,
    HtmlTextEditorComponent,
    HtmlTextPreviewerComponent,
    ReferenceFormComponent,
    FlashcardDashboardComponent,
    FlashcardFormComponent,
    FlashcardEditionFormComponent,
    FlashcardExplanationFormComponent,
    FlashcardIdGeneratorComponent,
  ],
  entryComponents: [
    DescriptionFormComponent,
    ExplanationFormComponent,
    QuestionFormComponent,
    QuestionViewComponent,
    UserFormComponent,
    FlashcardEditionFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    // AngularFirestoreModule.enablePersistence(), //For enabling cacheing
    StoreModule.forRoot(
      {
        auth: authenticationReducer,
        topics: topicReducer,
        dashboard: dashboardReducer,
      },
      {
        metaReducers: [clearStateOnLogoutReducer],
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      },
    ),
    StoreModule.forFeature('questions', questionReducer),
    StoreModule.forFeature('descriptions', descriptionReducer),
    StoreModule.forFeature('flashcards', flashcardReducer),
    StoreModule.forFeature('explanations', explanationReducer),
    StoreModule.forFeature('users', usersReducer),
    EffectsModule.forRoot(
      [
        DescriptionsEffects,
        AlertsEffects,
        AuthenticationEffects,
        EditorUsersEffects,
        ExplanationsEffects,
        TopicsEffects,
        QuestionsEffects,
        FlashcardsEffects,
      ],
    ),
    StoreDevtoolsModule.instrument(
      {
        maxAge: 25,
      },
    ),
    AngularFireAuthModule,
    AngularFireStorageModule,
    BrowserAnimationsModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    CKEditorModule,
  ],
  providers: [
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    DescriptionService,
    ExplanationService,
    ImageService,
    QuestionService,
    TopicService,
    UserService,
    NgxImageCompressService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
