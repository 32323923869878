import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserService } from '../../../services/User/user.service';
import {
  cancelledRequest,
  deleteUser,
  deleteUserFail,
  deleteUserSuccess,
  loadUsers,
  loadUsersFail,
  loadUsersSuccess, openEditUserForm, openEditUserFormFail, openEditUserFormSuccess, saveUser, saveUserFail, saveUserSuccess,
} from './editorUsers.actions';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { EditorUser, EditorUserPreview } from './editorUsers.interfaces';
import { firestore } from 'firebase/app';
import { getAuthState } from '../authentication/authentication.selectors';
import { from, of } from 'rxjs';
import { showAlert } from '../alerts/alerts.actions';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserFormComponent } from '../../../components/user-components/user-form/user-form.component';
import { MatDialog } from '@angular/material';

@Injectable() export class EditorUsersEffects {

  constructor (
    private store: Store<any>,
    private actions$: Actions,
    private userService: UserService,
    private fireStoreDB: AngularFirestore,
    private dialog: MatDialog,
  ) {}

  loadUsers$ = createEffect(() => this.actions$.pipe(
    ofType(loadUsers),
    mergeMap((action) => {
      return this.userService.getUsersPreviews().pipe(
        mergeMap((previews: EditorUserPreview[]) => {
          const userPreviews = previews.map((p) => {
            if (p.reference instanceof firestore.DocumentReference) {
              p.reference = p.reference.path;
            }
            return p;
          });
          return this.store.select(getAuthState).pipe(take(1), map((authState) => {
            if (authState.user.newToOld) {
              userPreviews.reverse();
              return loadUsersSuccess({ userPreviews, total: previews.length });
            }
            return loadUsersSuccess({ userPreviews, total: previews.length });
          }));
        }),
        catchError((error) => {
          return this.store.select(getAuthState).pipe(take(1), map((authState) => {
            if (authState && authState.user) {
              return loadUsersFail({ error });
            }
            return cancelledRequest();
          }));
        }),
      );
    }),
  ));

  deleteUser$ = createEffect(() => this.actions$.pipe(
    ofType(deleteUser),
    mergeMap((action) => {
      return from(this.userService.deleteUser(action.user)).pipe(
        map(() => {
          this.store.dispatch(showAlert(
            {
              alertType: 'success',
              title: 'Eliminado!',
              message: `El usuario de ${action.user.email} ya no tiene acceso al sistema ni se encuentra en la base de datos.`,
            },
          ));
          return deleteUserSuccess({ user: action.user });
        }),
        catchError((error) => {
          return of(deleteUserFail({ error }));
        }),
      );
    }),
  ));

  openEditUserForm$ = createEffect(() => this.actions$.pipe(
    ofType(openEditUserForm),
    mergeMap((action) => {
      if (action.userPreview === undefined) {
        this.dialog.open(UserFormComponent, {
          width: '1110px',
          disableClose: true,
        });
        return of(openEditUserFormSuccess({ user: undefined }));
      }
      return this.userService.getUser(action.userPreview.reference).pipe(
        take(1),
        map((user: EditorUser) => {
          user.id = this.userService.getUserId(action.userPreview.reference);
          this.dialog.open(UserFormComponent, {
            width: '1110px',
            disableClose: true,
          });
          return openEditUserFormSuccess({ user });
        }),
        catchError((error) => {
          return of(openEditUserFormFail({ error }));
        }),
      );
    }),
  ));

  saveUser$ = createEffect(() => this.actions$.pipe(
    ofType(saveUser),
    mergeMap((action) => {
      return from(this.userService.saveUser(action.user)).pipe(
        map(() => {
          return saveUserSuccess({ user: action.user });
        }),
        catchError((error) => {
          return of(saveUserFail({ error }));
        }),
      );
    }),
  ));

  usersErrors$ = createEffect(() => this.actions$.pipe(
    ofType(loadUsersFail, openEditUserFormFail, saveUserFail, deleteUserFail),
    mergeMap((action) => {
      return of(showAlert({ alertType: 'error', error: action.error, title: 'Oops...' }));
    }),
  ));

}
