import { Injectable } from '@angular/core';
import { Action, AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, map, pairwise } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Flashcard } from '../../core/ngrx/flashcards/flashcards.interfaces';

@Injectable({
  providedIn: 'root',
})
export class HelperService {

  private previousUrlStack = [];
  isNavigatedBack: boolean;

  constructor (private fireStoreDB: AngularFirestore, private router: Router) {
    const maxUrlStack = 3;
    this.router.events
      .pipe(filter((event: any) => event instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        if (!this.isNavigatedBack) {
          if (this.previousUrlStack.length === maxUrlStack) {
            this.previousUrlStack.shift();
          }
          this.previousUrlStack.push(events[0].urlAfterRedirects);
        }
      })
    ;
  }

  generateUid (): string {
    return this.fireStoreDB.createId();
  }

  getDocumentData<T> (reference: firestore.DocumentReference | string): Observable<T | undefined> {
    const path = reference instanceof firestore.DocumentReference ? reference.path : reference;
    const doc = this.fireStoreDB.doc<T>(path);
    if (!doc.ref) {
      return of(undefined);
    }
    return doc.snapshotChanges().pipe(map((a: Action<DocumentSnapshot<T>>) => a.payload.data()));
  }

  copyToClipboard (string: string): void {
    const listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (string));
      e.preventDefault();
      document.removeEventListener('copy', null);
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }

  transformReferenceToId (reference: firestore.DocumentReference | string): string {
    if (reference instanceof firestore.DocumentReference) {
      return reference.id;
    }
    return this.fireStoreDB.doc(reference).ref.id;
  }

  transformPathToReference (reference: firestore.DocumentReference | string): firestore.DocumentReference {
    if (reference instanceof firestore.DocumentReference) {
      return reference;
    }
    return this.fireStoreDB.doc(reference).ref;
  }

  transformReferenceToString (reference: firestore.DocumentReference | string): string {
    if (reference instanceof firestore.DocumentReference) {
      return reference.id;
    }
    return this.fireStoreDB.doc(reference).ref.path;
  }

  navigateTo (route: string) {
    this.isNavigatedBack = false;
    return this.router.navigate([route]);
  }

  navigateBack () {
    if (this.previousUrlStack.length === 0) {
      return this.navigateHome();
    }
    const path = this.previousUrlStack.pop();
    this.isNavigatedBack = true;
    return this.router.navigate(([path.substring(1, path.length)]));
  }

  navigateHome () {
    this.isNavigatedBack = false;
    return this.router.navigate((['editor']));
  }

}
