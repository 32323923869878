import { Action, createReducer, on } from '@ngrx/store';
import { TopicObject, TopicState } from './topics.interfaces';
import { isNullOrUndefined } from 'util';
import {
  loadTopics,
  loadTopicsFail,
  loadTopicsSuccess, addLocalTopic, updateLocalTopic,
  uploadTopics, uploadTopicsFail,
  uploadTopicsSuccess,
} from './topics.actions';
import * as _cloneDeep from 'lodash/cloneDeep';
import { TopicService } from '../../../services/Topic/topic.service';

const topicsInitialState: TopicState = {
  serverTopicList: undefined,
  topicList: undefined,
  loading: false,
  localChangesDirty: false,
};

const topicsLocalReducer = createReducer(
  topicsInitialState,
  on(loadTopics, uploadTopics, (state: TopicState) => {
    const newState: TopicState = {
      ...state,
      loading: true,
    };
    return newState;
  }),
  on(loadTopicsSuccess, uploadTopicsSuccess, (state: TopicState, { topics }) => {
    const newState: TopicState = {
      ...state,
      serverTopicList: topics,
      topicList: topics,
      localChangesDirty: false,
      loading: false,
    };
    return newState;
  }),
  on(addLocalTopic, (state: TopicState, { topic, topicParentProperty }) => {
    const topics: TopicObject = _cloneDeep(state.topicList);
    if (!isNullOrUndefined(topicParentProperty)) {
      if (isNullOrUndefined(topics[topicParentProperty].subtopics))  {
        topics[topicParentProperty].subtopics = {};
      }
      const nextIndex = TopicService.getTopicObjectNextIndex(topics[topicParentProperty].subtopics);
      topics[topicParentProperty].subtopics[`s${nextIndex}`] = topic;
    } else {
      const nextIndex = TopicService.getTopicObjectNextIndex(topics);
      topics[`t${nextIndex}`] = topic;
    }
    const newState: TopicState = {
      ...state,
      topicList: topics,
      localChangesDirty: true,
      loading: false,
    };
    return newState;
  }),
  on(updateLocalTopic, (state: TopicState, { topic, topicProperty, subtopicProperty }) => {
    const topics: TopicObject = _cloneDeep(state.topicList);
    if (!isNullOrUndefined(subtopicProperty)) {
      topics[topicProperty].subtopics[subtopicProperty] = topic;
    } else {
      topics[topicProperty] = topic;
    }
    const newState: TopicState = {
      ...state,
      topicList: topics,
      localChangesDirty: true,
      loading: false,
    };
    return newState;
  }),
  on(loadTopicsFail, uploadTopicsFail, (state: TopicState, { error }) => {
    const newState: TopicState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
);

export function topicReducer (state: TopicState | undefined, action: Action) {
  return topicsLocalReducer(state, action);
}
