import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { dataType } from '../core/ngrx/dataTypes/dataTypes.interfaces';

export class HelperValidators {

  static isValidData (data: string[], type: dataType[]): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (data.length === 0 && type.length === 0) {
        return { empty: true };
      }
      return null;
    };
  }

  static requiredIf (expression: boolean): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (expression && (c.value === '' || isNullOrUndefined(c.value))) {
        return { required: true };
      }
      return null;
    };
  }

  static checkURL (type: 'pdf' | 'link' | 'bibliography'): ValidatorFn {
    const urlRegExp = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (type !== 'bibliography' && c.value && !c.value.toLocaleLowerCase().match(urlRegExp)) {
        return { url: true };
      }
      if (type === 'pdf' && !c.value.toLocaleLowerCase().endsWith('.pdf')) {
        return { pdf: true };
      }
      return null;
    };
  }

  static validYear (c: AbstractControl): { [key: string]: boolean } | null {
    const currentYear = new Date().getFullYear();
    const maxLenth = 4;
    if (c.value !== '' && !isNullOrUndefined(c.value) && (!c.value.match(/^[0-9]+$/) || c.value.toString().length !== maxLenth)) {
      return { year: true };
    }
    return null;
  }

  static mustEqual (stringToCompare: string): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value && c.value !== stringToCompare) {
        return { equal: true };
      }
      return null;
    };
  }

  static invalidIf (invalidFlag: boolean): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (invalidFlag) {
        return { incorrect: true };
      }
      return null;
    };
  }

  static isValidFirebaseId (c: AbstractControl): { [key: string]: boolean } | null {
    const value: string = c.value;
    const maxLength = 30;
    const minLength = 15;
    let isNotValid = false;
    if (value) {
      isNotValid = isNotValid || !!value.match(/[^A-Za-z0-9]+/g);
      isNotValid = isNotValid || value.length > maxLength;
      isNotValid = isNotValid || value.length < minLength;
      if (isNotValid) {
        return { invalidId: true };
      }
    }
    return null;
  }

}
