import { DocumentReference } from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore-types';
import { dataType, PaginationValue } from '../dataTypes/dataTypes.interfaces';

export const explanationTitles = [
  { text: 'Introducción', iconPath: 'assets/icons/iconsIntroductionBlue@3x.png' },
  { text: 'Epidemiología', iconPath: 'assets/icons/iconsEpidemiologyBlue@3x.png' },
  { text: 'Cuadro clínico', iconPath: 'assets/icons/iconsClinicalPictureBlue@3x.png' },
  { text: 'Fisiopatología', iconPath: 'assets/icons/iconsEpidemiologyBlue@3x.png' },
  { text: 'Tratamiento', iconPath: 'assets/icons/iconsClinicalTreatmentBlue@3x.png' },
  { text: 'Pronóstico', iconPath: 'assets/icons/iconsForecastBlue@3x.png' },
  { text: 'Abordaje y diagnóstico', iconPath: 'assets/icons/iconsClinicalTreatmentBlue@3x.png' },
];

export interface ExplanationState {
  selectedExplanation: SelectedExplanationState;
  explanations: ExplanationsState;
}

export interface ExplanationsState {
  pagination: PaginationValue;
  previews: ExplanationPreview[];
  previewsPages: ExplanationPreview[][];
  previewsTotalCount: number;
  previewsIndexLoaded: number;
  previewsMaxIndex: number;
  loading: boolean;
  error?: any;
}

export interface SelectedExplanationState {
  explanation: Explanation;
  uploadingImages: boolean;
  loading: boolean;
  error?: any;
}

export interface Explanation {
  id: string;
  title: string;
  data: string[];
  type: dataType[];
  isHumanGeo: boolean;
  created: Timestamp;
  updated: Timestamp;
  resources: Resource[];
  flashcards?: string[];
  index?: number;
}

export interface ExplanationCount {
  totalCount: number;
}

export interface ExplanationDocPreview {
  data: ExplanationPreview[];
}

export interface ExplanationPreview {
  title: string;
  hasResources: boolean;
  hasFlashcards: boolean;
  isHumanGeo: boolean;
  created: Timestamp;
  updated: Timestamp;
  reference: DocumentReference | string;
  loading?: boolean;
}

export type ResourceType = 'pdf' | 'link' | 'bibliography';

export interface Resource {
  title: string;
  type: ResourceType;
  url?: string;
}
