import { createAction, props } from '@ngrx/store';
import { Flashcard } from './flashcards.interfaces';
import { ImageRefs } from '../dataTypes/dataTypes.interfaces';

export const cancelledRequest = createAction(
  '[Flashcards] Cancelled request',
);
export const resetSelectedFlashcard = createAction(
  '[Flashcards] Reset selected flashcard',
);
export const changeFlashcardsPaginationValue = createAction(
  '[Flashcards] Change pagination value',
  props<{ newIndex?: number, newPagValue?: number }>(),
);

export const loadFlashcards = createAction(
  '[Flashcards] Load flashcards',
  props<{ paginateVal: number }>(),
);
export const loadFlashcardsSuccess = createAction(
  '[Flashcards] Load flashcards success',
  props<{ flashcardsData: Flashcard[], index: number }>(),
);
export const loadFlashcardsFail = createAction(
  '[Flashcards] Load flashcards failure',
  props<{ error: any }>(),
);

export const loadNewFlashcardsPage = createAction(
  '[Flashcards] Load new flashcards page',
);
export const loadNewFlashcardsPageSuccess = createAction(
  '[Flashcards] Load new flashcards page success',
  props<{ flashcardsData: Flashcard[], index: number }>(),
);
export const loadNewFlashcardsPageFail = createAction(
  '[Flashcards] Load new flashcards page failure',
  props<{ error: any }>(),
);

export const loadFlashcardsCount = createAction(
  '[Flashcards] Load flashcards count',
);
export const loadFlashcardsCountSuccess = createAction(
  '[Flashcards] Load flashcards count success',
  props<{ flashcardsTotalLength: number, flashcardsMaxIndex: number }>(),
);
export const loadFlashcardsCountFail = createAction(
  '[Flashcards] Load flashcards count failure',
  props<{ error: any }>(),
);

export const openFlashcardForm = createAction(
  '[Flashcards] Open flashcard form',
  props<{ preview: Flashcard | string }>(),
);
export const openFlashcardFormSuccess = createAction(
  '[Flashcards] Open flashcard form success',
  props<{ flashcard: Flashcard }>(),
);
export const openFlashcardFormFail = createAction(
  '[Flashcards] Open flashcard form failure',
  props<{ error: any }>(),
);

export const saveFlashcard = createAction(
  '[Flashcards] Save flashcard',
  props<{ flashcard: Flashcard, imagesToDelete: ImageRefs[], previews: ImageRefs[] }>(),
);
export const saveFlashcardSuccess = createAction(
  '[Flashcards] Save flashcard success',
  props<{ id: string }>(),
);
export const saveFlashcardFail = createAction(
  '[Flashcards] Save flashcard fail',
  props<{ error: any }>(),
);
