<div class="main-container">
  <mat-card>
    <form class="filter-bar columns is-centered is-vcentered is-gapless" autocomplete="off">
      <div class="column is-6">
        <button mat-raised-button color="accent" (click)="openDescriptionForm()">Nueva descripción</button>
      </div>
      <div class="column is-6">
        <mat-paginator [length]="descriptionsLength" [pageSize]="defaultMatPaginatorValue"
                       [pageSizeOptions]="[5, 10, 20, 50]" (page)="changePageFromDashboard($event)" [disabled]="isLoading"></mat-paginator>
      </div>
    </form>
  </mat-card>
  <ng-container *ngIf="!isLoading && !pageLoading">
    <div class="columns">
      <div class="column is-2 has-text-weight-bold is-flex flex-items-center flex-center">ID</div>
      <div class="column is-9 has-text-weight-bold is-flex flex-items-center flex-center">Título</div>
      <div class="column is-1 has-text-weight-bold is-flex flex-items-center flex-center">Acciones</div>
    </div>
    <div class="columns dataRow text-small" *ngFor="let description of filteredDescriptions">
      <div class="column is-2 is-flex flex-items-center flex-start">
        <button mat-icon-button [matTooltip]="'Copiar ID'" matTooltipPosition="above" (click)="copyIDToClipboard(description.reference)">
          <mat-icon [color]="copiedId === helper.transformReferenceToId(description.reference) ? 'primary' : undefined">bookmarks</mat-icon>
        </button>
        <small>{{ helper.transformReferenceToId(description.reference) }}</small>
      </div>
      <div class="column is-9 is-flex flex-items-center flex-start">
        <h3>{{ description.title }}</h3>
      </div>
      <div class="column is-1 is-flex flex-items-center flex-end">
        <button [ngClass]="{'button is-loading': description.loading}" mat-raised-button color="primary"
                (click)="openDescriptionForm(description)" matTooltip="Editar" matTooltipPosition="above">
          <mat-icon matListIcon>edit</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <mat-spinner *ngIf="isLoading || pageLoading"></mat-spinner>
</div>
