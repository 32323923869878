import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Action, AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { firestore } from 'firebase/app';
import { EditorUser, EditorUserPreview } from '../../core/ngrx/editorUsers/editorUsers.interfaces';
import * as _ from 'lodash';

const Timestamp = firestore.Timestamp;

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor (private fireStoreDB: AngularFirestore) { }

  getUsersPreviews (): Observable<EditorUserPreview[]> {
    return this.fireStoreDB.doc<EditorUserPreview[]>(`editorUsers/previews`).snapshotChanges().pipe(map(
      (a: any) => {
        const preview = a.payload.data();
        return preview ? preview.data : [];
      }),
    );
  }

  getUser (reference: firestore.DocumentReference | string): Observable<EditorUser> {
    const path = reference instanceof firestore.DocumentReference ? reference.path : reference;
    return this.fireStoreDB.doc<EditorUser>(path).snapshotChanges().pipe(map(
      (a: Action<DocumentSnapshot<EditorUser>>) => {
        const user = a.payload.data();
        return user;
      }),
    );
  }

  getUserId (reference: firestore.DocumentReference | string): string {
    if (reference instanceof firestore.DocumentReference) {
      return reference.id;
    }
    return this.fireStoreDB.doc(reference).ref.id;
  }

  saveUser (user: EditorUser): Promise<void> {
    const id = user.id ? user.id : this.fireStoreDB.createId();
    let newUser: EditorUser = _.cloneDeep(user);
    if (!newUser.created) {
      newUser = { ...newUser, created: Timestamp.now() };
    } else {
      newUser.created = new Timestamp(newUser.created.seconds, newUser.created.nanoseconds);
    }
    delete newUser.id;
    return this.fireStoreDB.doc(`editorUsers/${id}`).set(newUser);
  }

  deleteUser (user: EditorUserPreview): Promise<void> {
    const path = user.reference instanceof firestore.DocumentReference ? user.reference.path : user.reference;
    return this.fireStoreDB.doc(path).delete();
  }

}
