import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { ExplanationPreview } from '../../../core/ngrx/explanations/explanations.interfaces';
import { combineLatest, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { select, Store } from '@ngrx/store';
import { auditTime, take, takeUntil } from 'rxjs/operators';
import { getExplanationsState, getSelectedExplanationState } from '../../../core/ngrx/explanations/explanations.selectors';
import {
  changeExplanationsPaginationValue,
  loadExplanations,
  loadNewExplanationsPage,
} from '../../../core/ngrx/explanations/explanations.actions';
import { getAuthState } from '../../../core/ngrx/authentication/authentication.selectors';
import { firestore } from 'firebase/app';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from '../../../services/Helper/helper.service';

@Component({
  selector: 'em-explanation',
  templateUrl: './explanation-dashboard.component.html',
  styleUrls: ['./explanation-dashboard.component.scss'],
})
export class ExplanationDashboardComponent implements OnInit, OnDestroy {

  $destroy: Subject<boolean> = new Subject<boolean>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  defaultMatPaginatorValue = environment.defaultPaginatorValue;
  loadedPaginator = false;

  isLoading = true;
  pageLoading = false;
  isReversed = false;
  lastPaginationIndexLoaded = -1;
  explanationsLength: number;
  maxIndex: number;
  explanations: ExplanationPreview[];
  filteredExplanations: ExplanationPreview[];
  copiedId = '';

  constructor (private store: Store<any>, private route: ActivatedRoute, private helper: HelperService) {}

  ngOnInit () {
    const checkTime = 50;
    let interval;
    this.setStateSubscriptions();
    this.route.url.subscribe((segmentArray) => {
      if (!interval && segmentArray.length === 1 && segmentArray[0].path === 'editor') {
        this.pageLoading = true;
        interval = setInterval(
          () => {
            if (!this.isLoading && this.paginator) {
              this.setInitialPaginationValues();
              clearInterval(interval);
            }
          },
          checkTime,
        );
      }
    });
  }

  setStateSubscriptions () {
    const auditTimeVal = 150;
    const states = combineLatest([
      this.store.pipe(takeUntil(this.$destroy), auditTime(auditTimeVal), select(getExplanationsState)),
      this.store.pipe(takeUntil(this.$destroy), auditTime(auditTimeVal), select(getSelectedExplanationState)),
    ]);
    states.subscribe(([explanations, selectedExplanation]) => {
      if (!explanations.loading && explanations.previewsTotalCount > 0) {
        this.explanations = explanations.previews;
        this.filteredExplanations = explanations.previews.slice(0, this.defaultMatPaginatorValue);
        this.explanationsLength = explanations.previewsTotalCount;
        this.lastPaginationIndexLoaded = explanations.previewsIndexLoaded;
        if (this.maxIndex && this.maxIndex < explanations.previewsMaxIndex) {
          this.store.dispatch(loadNewExplanationsPage());
          this.maxIndex = explanations.previewsMaxIndex;
        } else {
          this.maxIndex = explanations.previewsMaxIndex;
        }
        if (this.paginator) {
          this.changedPage({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize });
        }
      }
      this.isLoading = explanations.loading || selectedExplanation.loading;
    });
    this.store.pipe(takeUntil(this.$destroy), select(getAuthState)).subscribe((authState) => {
      if (authState && authState.user) {
        this.isReversed = authState.user.newToOld;
      }
    });
  }

  openExplanationForm (expl?: ExplanationPreview) {
    if (expl) {
      const route = `explanations/edit/${this.helper.transformReferenceToId(expl.reference)}`;
      return this.helper.navigateTo(route);
    }
    return this.helper.navigateTo('explanations/create');
  }

  changePageFromDashboard (event) {
    if (!this.pageLoading) {
      this.store.dispatch(changeExplanationsPaginationValue({ newPagValue: event.pageSize, newIndex: event.pageIndex }));
    }
    this.changedPage(event);
  }

  changedPage (event) {
    const firstIndexOfExplRequired = event.pageSize * (event.pageIndex);
    const lastIndexOfExplRequired = event.pageSize * (event.pageIndex + 1) - 1;
    this.filteredExplanations = this.explanations.slice(firstIndexOfExplRequired, lastIndexOfExplRequired + 1);
    let paginationIndexRequired = Math.trunc((lastIndexOfExplRequired) / environment.paginateVal);
    if (this.isReversed) {
      const lastPageSize = this.explanationsLength % environment.paginateVal;
      if (lastIndexOfExplRequired % environment.paginateVal > lastPageSize) {
        paginationIndexRequired += 1;
      }
    }
    if (paginationIndexRequired > this.lastPaginationIndexLoaded && paginationIndexRequired <= this.maxIndex) {
      this.store.dispatch(loadExplanations({ paginateVal: paginationIndexRequired }));
    }
  }

  copyIDToClipboard (id: string | firestore.DocumentReference) {
    const time = 2500;
    const stringId = this.helper.transformReferenceToId(id);
    this.helper.copyToClipboard(stringId);
    this.copiedId = stringId;
    setTimeout(() => this.copiedId = '', time);
  }

  setInitialPaginationValues () {
    if (!this.loadedPaginator) {
      this.loadedPaginator = true;
      this.store.pipe(select(getExplanationsState), take(1)).subscribe((explanationState) => {
        this.pageLoading = true;
        const paginator = this.paginator;
        paginator._changePageSize(explanationState.pagination.pageValue);
        paginator.pageIndex = explanationState.pagination.activeIndex;
        this.pageLoading = false;
      });
    }
  }

  ngOnDestroy (): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
