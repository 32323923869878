<div class="columns is-vcentered is-centered">
  <div class="column is-2">
    <img class="em-icon" src="../../../../assets/icon_512.png">
  </div>
  <div class="column is-6">
    <p class="is-size-1 has-text-danger">404</p>
    <p class="is-size-3 has-text-danger">Página no encontrada</p>

    <p class="has-text-danger">Parece ser que no encontramos lo que estabas buscando</p>
  </div>
</div>
