import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { showAlert } from '../../../core/ngrx/alerts/alerts.actions';
import { HelperService } from '../../../services/Helper/helper.service';

@Component({
  selector: 'em-flashcard-id-generator',
  templateUrl: './flashcard-id-generator.component.html',
  styleUrls: ['./flashcard-id-generator.component.scss'],
})
export class FlashcardIdGeneratorComponent {
  currentId = '';
  newFlag = false;

  constructor (private formBuilder: FormBuilder, private helperService: HelperService, private store: Store<any>) {}

  generateUid () {
    const highlightTime = 3000;
    const newId = this.helperService.generateUid();
    this.helperService.copyToClipboard(newId);
    this.currentId = newId;
    this.newFlag = true;
    this.store.dispatch(showAlert({
      toast: true,
      position: 'top',
      showCancel: false,
      timer: 5000,
      alertType: 'success',
      title: `ID "${newId}" generado y copiado al portapapeles.`,
    }));
    setTimeout(() => this.newFlag = false, highlightTime);
  }

}
