import { Component, OnDestroy } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Topic, TopicFlatNode, TopicObject } from '../../../core/ngrx/topics/topics.interfaces';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getTopicsState } from '../../../core/ngrx/topics/topics.selectors';
import { addLocalTopic, updateLocalTopic, uploadTopics } from '../../../core/ngrx/topics/topics.actions';
import { isNullOrUndefined } from 'util';
import * as _ from 'lodash';

@Component({
  selector: 'em-topic',
  templateUrl: './topic-dashboard.component.html',
  styleUrls: ['./topic-dashboard.component.scss'],
})
export class TopicDashboardComponent implements OnDestroy {

  $destroy: Subject<boolean> = new Subject<boolean>();
  transformer (node: Topic, level: number) {
    return {
      level,
      expandable: !!node.subtopics && Object.keys(node.subtopics).length > 0,
      name: node.name,
    };
  }
  treeControl = new FlatTreeControl<TopicFlatNode>(node => node.level, node => node.expandable);
  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => this.transformTopics(node.subtopics),
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: TopicFlatNode) => node.expandable;

  topics: TopicObject;
  topicForm: FormGroup;
  activeTopicProperty: string;
  activeSubTopicProperty: string;
  activeTopicMessage: string;
  isLoading = true;
  isSubtopicCreation = false;
  isDirty = false;

  constructor (private formBuilder: FormBuilder, private store: Store<any>) {
    this.topicForm = this.formBuilder.group({ newTopic: [''], updateTopic: [''] });
    this.setStateSubscriptions();
  }

  setStateSubscriptions () {
    this.store.pipe(takeUntil(this.$destroy), select(getTopicsState)).subscribe((topics) => {
      if (!topics.loading && !topics.error) {
        this.topics = topics.topicList;
        this.dataSource.data = this.transformTopics(this.topics);
        this.updateTree();
        this.resetActiveIndex();
      }
      this.isDirty = topics.localChangesDirty;
      this.isLoading = topics.loading;
    });
  }

  transformTopics (object: TopicObject): any[] {
    return Object.entries(object).sort(([a], [b]) => {
      return parseInt(a.substr(1), 10) > parseInt(b.substr(1), 10) ? 1 : -1;
    }).map(([, topic]) => topic);
  }

  addTopic () {
    const topic = this.topicForm.get('newTopic').value;
    const newTopic: Topic = { name: topic, counters: { en: 0, es: 0 } };
    this.store.dispatch(addLocalTopic({ topic: newTopic }));
  }

  edit (node: TopicFlatNode) {
    this.resetActiveIndex();
    const topicsArr = Object.entries(this.topics);
    topicsArr.forEach(([i, t]) => {
      if (t.name === node.name && node.level === 0) {
        this.activeTopicProperty = i;
        this.activeTopicMessage = `Actualizar materia: ${node.name}`;
        this.topicForm.get('updateTopic').setValue(node.name);
      }
      if (!isNullOrUndefined(t.subtopics) && node.level === 1) {
        const subtopicsArr = Object.entries(t.subtopics);
        subtopicsArr.forEach(([j , s]) => {
          if (s.name === node.name) {
            this.activeTopicProperty = i;
            this.activeSubTopicProperty = j;
            this.activeTopicMessage = `Actualizar submateria de ${t.name}: ${node.name}`;
            this.topicForm.get('updateTopic').setValue(node.name);
          }
        });
      }
    });
  }

  resetActiveIndex () {
    this.isSubtopicCreation = false;
    this.activeTopicProperty = undefined;
    this.activeSubTopicProperty = undefined;
  }

  addSubTopic (node: TopicFlatNode) {
    this.resetActiveIndex();
    const topicsArr = Object.entries(this.topics);
    topicsArr.forEach(([i, t]) => {
      if (t.name === node.name && node.level === 0) {
        this.activeTopicProperty = i;
        this.isSubtopicCreation = true;
        this.activeTopicMessage = `Añadir submateria para ${node.name}`;
        this.topicForm.get('updateTopic').setValue('');
      }
    });
  }

  cancelUpdate () {
    this.topicForm.get('updateTopic').setValue('');
    this.activeTopicMessage = undefined;
    this.resetActiveIndex();
  }

  updateTopic () {
    const content = this.topicForm.get('updateTopic').value;
    if (isNullOrUndefined(this.activeTopicProperty)) return;
    if (isNullOrUndefined(this.activeSubTopicProperty)) {
      if (this.isSubtopicCreation) {
        const newTopic: Topic = { name: content, counters: { en: 0, es: 0 } };
        this.store.dispatch(addLocalTopic({ topic: newTopic, topicParentProperty: this.activeTopicProperty }));
      } else {
        const topic: Topic = _.cloneDeep(this.topics[this.activeTopicProperty]);
        topic.name = content;
        this.store.dispatch(updateLocalTopic({ topic, topicProperty: this.activeTopicProperty }));
      }
    } else {
      const topic: Topic = _.cloneDeep(this.topics[this.activeTopicProperty]);
      topic.subtopics[this.activeSubTopicProperty] = { ...topic.subtopics[this.activeSubTopicProperty], name: content };
      this.store.dispatch(updateLocalTopic({ topic, topicProperty: this.activeTopicProperty }));
    }
  }

  uploadToServer () {
    if (!this.isDirty) return;
    this.store.dispatch(uploadTopics({ topics: this.topics }));
  }

  updateTree (active?: number) {
    this.topicForm.get('newTopic').setValue('');
    this.topicForm.get('updateTopic').setValue('');
    this.resetActiveIndex();
    this.activeTopicMessage = undefined;
    this.dataSource.data = this.transformTopics(this.topics);
    this.isDirty = true;
    if (active !== undefined) {
      this.treeControl.expand(this.treeControl.dataNodes.filter(n => n.level === 0)[active]);
    }
  }

  ngOnDestroy (): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
