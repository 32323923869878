import { Injectable } from '@angular/core';
import { TopicService } from '../../../services/Topic/topic.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { from, of } from 'rxjs';
import {
  cancelledRequest,
  loadTopics,
  loadTopicsFail,
  loadTopicsSuccess,
  uploadTopics,
  uploadTopicsFail,
  uploadTopicsSuccess,
} from './topics.actions';
import { showAlert } from '../alerts/alerts.actions';
import { getAuthState } from '../authentication/authentication.selectors';
import { select, Store } from '@ngrx/store';

@Injectable() export class TopicsEffects {
  constructor (private store: Store<any>, private actions$: Actions, private topicService: TopicService) {}

  loadTopics$ = createEffect(() => this.actions$.pipe(
    ofType(loadTopics),
    concatMap(action => of(action).pipe(withLatestFrom(
      this.store.pipe(select(getAuthState)),
    ))),
    mergeMap(([, authState]) => {
      return this.topicService.getTopics().pipe(
        map((topics) => {
          return loadTopicsSuccess({ topics });
        }),
        catchError((error) => {
          if (authState && authState.user) {
            return of(loadTopicsFail({ error }));
          }
          return of(cancelledRequest());
        }),
      );
    }),
  ));

  uploadTopics$ = createEffect(() => this.actions$.pipe(
    ofType(uploadTopics),
    mergeMap((action) => {
      const topicsObject = action.topics;
      return from(this.topicService.saveTopics(topicsObject)).pipe(
        map(() => {
          return uploadTopicsSuccess({ topics: topicsObject });
        }),
        catchError((error) => {
          return of(uploadTopicsFail({ error }));
        }),
      );
    }),
  ));

  topicsErrors$ = createEffect(() => this.actions$.pipe(
    ofType(loadTopicsFail, uploadTopicsFail),
    mergeMap((action) => {
      return of(showAlert({ alertType: 'error', error: action.error, title: 'Oops...' }));
    }),
  ));

}
