import { createAction, props } from '@ngrx/store';
import { Description, DescriptionPreview } from './descriptions.interfaces';
import { ImageRefs } from '../dataTypes/dataTypes.interfaces';

export const cancelledRequest = createAction(
  '[Descriptions] Cancelled request',
);
export const resetSelectedDescription = createAction(
  '[Descriptions] Reset selected description',
);
export const changeDescriptionsPaginationValue = createAction(
  '[Descriptions] Change pagination value',
  props<{ newIndex?: number, newPagValue?: number }>(),
);

export const loadDescriptions = createAction(
  '[Descriptions] Load descriptions',
  props<{ paginateVal: number }>(),
);
export const loadDescriptionsSuccess = createAction(
  '[Descriptions] Load descriptions success',
  props<{ descriptionPreviews: DescriptionPreview[], index: number }>(),
);
export const loadDescriptionsFail = createAction(
  '[Descriptions] Load descriptions failure',
  props<{ error: any }>(),
);

export const loadNewDescriptionsPage = createAction(
  '[Descriptions] Load new descriptions page',
);
export const loadNewDescriptionsPageSuccess = createAction(
  '[Descriptions] Load new descriptions page success',
  props<{ descriptionPreviews: DescriptionPreview[], index: number }>(),
);
export const loadNewDescriptionsPageFail = createAction(
  '[Descriptions] Load new descriptions page failure',
  props<{ error: any }>(),
);

export const loadDescriptionsCount = createAction(
  '[Descriptions] Load descriptions count',
);
export const loadDescriptionsCountSuccess = createAction(
  '[Descriptions] Load descriptions count success',
  props<{ descriptionsTotalLength: number, descriptionsMaxIndex: number }>(),
);
export const loadDescriptionsCountFail = createAction(
  '[Descriptions] Load descriptions count failure',
  props<{ error: any }>(),
);

export const openDescriptionForm = createAction(
  '[Descriptions] Open description form',
  props<{ preview: DescriptionPreview | string }>(),
);
export const openDescriptionFormSuccess = createAction(
  '[Descriptions] Open description form success',
  props<{ description: Description }>(),
);
export const openDescriptionFormFail = createAction(
  '[Descriptions] Open description form failure',
  props<{ error: any }>(),
);

export const saveDescription = createAction(
  '[Descriptions] Save description',
  props<{ description: Description, imagesToDelete: ImageRefs[] }>(),
);
export const saveDescriptionSuccess = createAction(
  '[Descriptions] Save description success',
  props<{ id: string }>(),
);
export const saveDescriptionFail = createAction(
  '[Descriptions] Save description fail',
  props<{ error: any }>(),
);

export const saveDescriptionWithImages = createAction(
  '[Descriptions] Save description with images',
  props<{ imagesToUpload: string[], imagesToDelete: ImageRefs[], description: Description }>(),
);
export const saveDescriptionWithImagesSuccess = createAction(
  '[Descriptions] Save description with images success',
  props<{ id: string }>(),
);
export const saveDescriptionWithImagesFail = createAction(
  '[Descriptions] Save description with images fail',
  props<{ error: any }>(),
);
