import { Component, OnDestroy } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MatDialogRef } from '@angular/material/dialog';
import { getSelectedQuestionState } from '../../../core/ngrx/questions/questions.selectors';
import { take, takeUntil } from 'rxjs/operators';
import { Question } from '../../../core/ngrx/questions/questions.interfaces';
import { DescriptionService } from '../../../services/Description/description.service';
import { ExplanationService } from '../../../services/Explanation/explanation.service';
import { Explanation, explanationTitles } from '../../../core/ngrx/explanations/explanations.interfaces';
import { Description } from '../../../core/ngrx/descriptions/descriptions.interfaces';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ImageService } from '../../../services/Image/image.service';
import { dataType, ImageRefs } from '../../../core/ngrx/dataTypes/dataTypes.interfaces';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../../environments/environment';
import { HelperService } from '../../../services/Helper/helper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'em-question-view',
  templateUrl: './question-view.component.html',
  styleUrls: ['./question-view.component.scss'],
})
export class QuestionViewComponent implements OnDestroy {

  $destroy: Subject<boolean> = new Subject<boolean>();

  viewForm: FormGroup;
  loading = true;
  resourcesLoading = true;
  question: Question;
  explanation: Explanation;
  description: Description;
  images: ImageRefs[] = [];
  explanationTitles = explanationTitles;

  constructor (
    private formBuilder: FormBuilder,
    private store: Store<any>,
    private imageService: ImageService,
    private descriptionService: DescriptionService,
    private explanationService: ExplanationService,
    private helper: HelperService,
    private router: Router,
    private dialogRef: MatDialogRef<QuestionViewComponent>,
  ) {
    this.viewForm = this.formBuilder.group({ mobile: '' });
    this.viewForm.get('mobile').valueChanges.pipe(takeUntil(this.$destroy)).subscribe((val: boolean) => {
      let width = '1240px';
      if (val) {
        width = '400px';
      }
      this.dialogRef.updateSize(width);
    });
    this.setStateSubscriptions();
  }

  setStateSubscriptions () {
    this.store.pipe(
      takeUntil(this.$destroy),
      select(getSelectedQuestionState),
    ).subscribe((question) => {
      this.loading = question.loading;
      if (!question.loading && !question.error) {
        this.question = question.question;
        this.getAnswers();
        this.loadResources();
        this.loadImages('question');
      }
    });
  }

  loadResources () {
    this.resourcesLoading = true;
    const observables = forkJoin([
      this.descriptionService.getDescription(this.question.descriptionReference).pipe(take(1)),
      this.explanationService.getExplanation(this.question.explanationReference).pipe(take(1)),
    ]);
    observables.subscribe(([description, explanation]) => {
      this.description = description;
      this.explanation = explanation;
      this.loadImages('explanation');
      this.loadImages('description');
      this.resourcesLoading = false;
    });
  }

  loadImages (type: 'question' | 'explanation' | 'description') {
    if (isNullOrUndefined(this[type])) return;
    const images = this[type].data.filter((ques, i) => this[type].type[i] === 'img');
    if (images && images.length > 0) {
      images.forEach((imgName) => {
        this.imageService.getImageRef(imgName, `${type}s`).toPromise().then((ref) => {
          this.images.push({ name: imgName, URL: ref, loading: true });
        });
      });
    }
  }

  imagesAreLoading (): boolean {
    const loadingImages = this.images.filter(img => img.loading === true);
    return loadingImages.length !== 0;
  }

  getImageFromName (name: string): string {
    const image = this.images.find(i => i.name === name);
    return image ? image.URL : '';
  }

  getAnswers (): { data: string[], type: dataType[] }[] {
    return Object.values(this.question ? this.question.options : {});
  }

  isCorrectAnswer (option: { data: string[], type: dataType[] }): boolean {
    const index = Object.values(this.question.options).indexOf(option);
    return Object.keys(this.question.options)[index] === this.question.answer;
  }

  isHeadingText (text: string): boolean {
    return this.explanationTitles.findIndex(title => title.text === text) !== -1;
  }

  getIconSrc (text: string): string {
    if (this.isHeadingText(text)) {
      const titleObj = this.explanationTitles.find(title => title.text === text);
      if (titleObj) {
        return titleObj.iconPath;
      }
      return '';
    }
    return '';
  }

  finishedLoadingImage (name: string) {
    const image = this.images.find(i => i.name === name);
    image.loading = false;
  }

  copyIDToClipboard (id: string) {
    this.helper.copyToClipboard(id);
  }

  copyURLToClipboard (id: string) {
    const url = `${environment.projectUrl}/questions/edit/${id}`;
    this.helper.copyToClipboard(url);
  }

  close (): void {
    this.dialogRef.close();
  }

  edit () {
    const route = `questions/edit/${this.question.id}`;
    return this.router.navigate([route]);
  }

  ngOnDestroy (): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
