import { createAction, props } from '@ngrx/store';
import { Question, QuestionCount, QuestionPreview, Status } from './questions.interfaces';
import { ImageRefs } from '../dataTypes/dataTypes.interfaces';

export const cancelledRequest = createAction(
  '[Questions] Cancelled request',
);
export const changeQuestionsPaginationValue = createAction(
  '[Questions] Change pagination value',
  props<{ status: Status | '', newIndex?: number, newPagValue?: number }>(),
);
export const changeQuestionsPaginationValueSuccess = createAction(
  '[Questions] Change pagination value success',
  props<{ status: Status | '', newIndex?: number, newPagValue?: number }>(),
);
export const changeQuestionsPaginationValueFail = createAction(
  '[Questions] Change pagination value failure',
  props<{ error: any }>(),
);

export const loadQuestions = createAction(
  '[Questions] Load questions',
  props<{ paginateVal: number }>(),
);
export const loadQuestionsSuccess = createAction(
  '[Questions] Load questions success',
  props<{ questionPreviews: QuestionPreview[], index: number }>(),
);
export const loadQuestionsFail = createAction(
  '[Questions] Load questions failure',
  props<{ error: any }>(),
);

export const loadNewQuestionsPage = createAction(
  '[Questions] Load new questions page',
);
export const loadNewQuestionsPageSuccess = createAction(
  '[Questions] Load new questions page success',
  props<{ questionPreviews: QuestionPreview[], index: number }>(),
);
export const loadNewQuestionsPageFail = createAction(
  '[Questions] Load new questions page failure',
  props<{ error: any }>(),
);

export const loadQuestionsCount = createAction(
  '[Questions] Load questions count',
);
export const loadQuestionsCountSuccess = createAction(
  '[Questions] Load questions count success',
  props<{ questionCountObject: QuestionCount, questionsMaxIndex: number }>(),
);
export const loadQuestionsCountFail = createAction(
  '[Questions] Load questions count failure',
  props<{ error: any }>(),
);

export const openQuestionForm = createAction(
  '[Questions] Open question form',
  props<{ preview: QuestionPreview | string }>(),
);
export const openQuestionFormSuccess = createAction(
  '[Questions] Open question form success',
  props<{ question: Question }>(),
);
export const openQuestionFormFail = createAction(
  '[Questions] Open question form failure',
  props<{ error: any }>(),
);

export const saveQuestion = createAction(
  '[Questions] Save question',
  props<{ question: Question, imagesToDelete: ImageRefs[] }>(),
);
export const saveQuestionSuccess = createAction(
  '[Questions] Save question success',
  props<{ id: string }>(),
);
export const saveQuestionFail = createAction(
  '[Questions] Save question fail',
  props<{ error: any }>(),
);

export const saveQuestionWithImages = createAction(
  '[Questions] Save question with images',
  props<{ imagesToUpload: string[], imagesToDelete: ImageRefs[], question: Question }>(),
);
export const saveQuestionWithImagesSuccess = createAction(
  '[Questions] Save question with images success',
  props<{ id: string }>(),
);
export const saveQuestionWithImagesFail = createAction(
  '[Questions] Save question with images fail',
  props<{ error: any }>(),
);

export const openQuestionView = createAction(
  '[Questions] Open question view',
  props<{ preview: QuestionPreview | string }>(),
);
export const openQuestionViewSuccess = createAction(
  '[Questions] Open question view success',
  props<{ question: Question }>(),
);
export const openQuestionViewFail = createAction(
  '[Questions] Open question view failure',
  props<{ error: any }>(),
);

export const publishQuestion = createAction(
  '[Questions] Publish question',
  props<{ question: QuestionPreview }>(),
);
export const publishQuestionSuccess = createAction(
  '[Questions] Publish question success',
);
export const publishQuestionFail = createAction(
  '[Questions] Publish question fail',
  props<{ error: any }>(),
);

export const deactivateQuestion = createAction(
  '[Questions] Deactivate question',
  props<{ question: QuestionPreview }>(),
);
export const deactivateQuestionSuccess = createAction(
  '[Questions] Deactivate question success',
);
export const deactivateQuestionFail = createAction(
  '[Questions] Deactivate question fail',
  props<{ error: any }>(),
);

export const reactivateQuestion = createAction(
  '[Questions] Reactivate question',
  props<{ question: QuestionPreview }>(),
);
export const reactivateQuestionSuccess = createAction(
  '[Questions] Reactivate question success',
);
export const reactivateQuestionFail = createAction(
  '[Questions] Reactivate question fail',
  props<{ error: any }>(),
);

export const deleteQuestion = createAction(
  '[Questions] Delete question',
  props<{ question: QuestionPreview }>(),
);
export const deleteQuestionSuccess = createAction(
  '[Questions] Delete question success',
);
export const deleteQuestionFail = createAction(
  '[Questions] Delete question fail',
  props<{ error: any }>(),
);
