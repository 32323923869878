<div class="main-container">
  <mat-card>
    <form [formGroup]="filterForm" class="filter-bar columns is-centered is-vcentered is-gapless" autocomplete="off">
      <div class="column is-3">
        <button mat-raised-button color="accent" *ngIf="userPermits.canCreateQuestions" (click)="openQuestionForm()">Nueva pregunta</button>
      </div>
      <div class="column is-4 has-text-centered">
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option *ngFor="let status of statusValues" [value]="status.value">{{ status.text }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="column is-5" *ngIf="filteredQuestions">
        <mat-paginator [length]="selectedStatusCount" [pageSize]="defaultMatPaginatorValue"
                       [pageSizeOptions]="[5, 10, 20, 50]" (page)="changePageFromDashboard($event, true)" [disabled]="isLoading"></mat-paginator>
      </div>
    </form>
  </mat-card>
  <ng-container *ngIf="!isLoading && !pageLoading">
    <div class="columns">
      <div class="column is-4 is-flex flex-items-center flex-start">
        <h3 class="has-text-weight-bold">Título de la pregunta</h3>
      </div>
      <div class="column is-2 has-text-weight-bold is-flex flex-items-center flex-center">Status</div>
      <div class="column is-2 has-text-weight-bold is-flex flex-items-center flex-center">Usuario que actualizó</div>
      <div class="column is-2 has-text-weight-bold is-flex flex-items-center flex-center">Fecha de creación</div>
      <div class="column is-2 has-text-weight-bold is-flex flex-items-center flex-end">Acciones</div>
    </div>
    <div class="columns text-small" *ngFor="let question of filteredQuestions">
      <div class="column is-4 is-flex flex-items-center flex-start">
        <mat-icon class="margin-right-small">description</mat-icon>
        <h3>{{ question.title }}</h3>
      </div>
      <div class="column is-2 is-flex flex-items-center flex-space-between padding-small items-help">
        <mat-icon [matTooltip]="(question.hasDescription ? 'Si' : 'No') + ' tiene descripción'"
                  matTooltipPosition="left" [color]="question.hasDescription ? 'primary' : undefined">subtitles</mat-icon>
        <mat-icon [matTooltip]="getStatusTitle(question.status)" matTooltipPosition="above"
                  [ngClass]="getStatusClass(question.status)">{{getStatusIcon(question.status)}}</mat-icon>
        <mat-icon [matTooltip]="(question.hasExplanation ? 'Si' : 'No') + ' tiene explicación'"
                  matTooltipPosition="right" [color]="question.hasExplanation ? 'primary' : undefined">note</mat-icon>
      </div>
      <div class="column is-2 is-flex flex-items-center flex-center">{{ question.lastUpdatedBy }}</div>
      <div class="column is-2 is-flex flex-items-center flex-center">
        {{ question.created.toDate().toLocaleDateString('es-MX', localeDateOptions) }}
      </div>
      <div class="column is-2 is-flex flex-items-center flex-end">
        <button [ngClass]="{'button is-loading': question.loading}" mat-mini-fab color="primary" matTooltip="Ver"
                (click)="openQuestionView(question)" matTooltipPosition="left" *ngIf="userPermits.canViewQuestion(question)" >
          <mat-icon>remove_red_eye</mat-icon>
        </button>
        <button [ngClass]="{'button is-loading': question.loading}" mat-mini-fab color="accent" matTooltip="Editar"
                (click)="openQuestionForm(question)" matTooltipPosition="above" *ngIf="userPermits.canEditQuestion(question)" >
          <mat-icon>edit</mat-icon>
        </button>
        <button [ngClass]="{'button is-loading': question.loading}" mat-mini-fab color="primary" matTooltip="Aprobar y publicar"
                (click)="approveAndPublishQuestion(question)" matTooltipPosition="above" *ngIf="userPermits.canPublishQuestion(question)" >
          <mat-icon>vertical_align_top</mat-icon>
        </button>
        <button [ngClass]="{'button is-loading': question.loading}" mat-mini-fab color="warn" matTooltip="Rechazar y desactivar"
                (click)="rejectAndDeactivate(question)" matTooltipPosition="above" *ngIf="userPermits.canPublishQuestion(question)" >
          <mat-icon>clear</mat-icon>
        </button>
        <button [ngClass]="{'button is-loading': question.loading}" mat-mini-fab color="warn" matTooltip="Desactivar"
                (click)="deactivateQuestion(question)" matTooltipPosition="above" *ngIf="userPermits.canUnPublishQuestion(question)" >
          <mat-icon>vertical_align_bottom</mat-icon>
        </button>
        <button [ngClass]="{'button is-loading': question.loading}" mat-mini-fab color="primary" matTooltip="Reactivar"
                (click)="reactivateQuestion(question)" matTooltipPosition="above" *ngIf="userPermits.canRePublishQuestion(question)" >
          <mat-icon>trending_up</mat-icon>
        </button>
        <button [ngClass]="{'button is-loading': question.loading}" mat-mini-fab color="warn" matTooltip="Eliminar"
                (click)="deleteQuestion(question)" matTooltipPosition="right" *ngIf="userPermits.canDeleteQuestion" >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <mat-spinner *ngIf="isLoading || pageLoading"></mat-spinner>
</div>
