import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControlDirective, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ImageService } from '../../../services/Image/image.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';
import { isNullOrUndefined } from 'util';
import { HelperValidators } from '../../../validators/helper-validators';
import * as _cloneDeep from 'lodash/cloneDeep';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { allStatusArray, languages, Option, Question, Status, Audit } from '../../../core/ngrx/questions/questions.interfaces';
import { dataType, ImageRefs } from '../../../core/ngrx/dataTypes/dataTypes.interfaces';
import { Topic, TopicObject } from '../../../core/ngrx/topics/topics.interfaces';
import { DescriptionPreview, DescriptionsState } from '../../../core/ngrx/descriptions/descriptions.interfaces';
import { ExplanationPreview, ExplanationsState } from '../../../core/ngrx/explanations/explanations.interfaces';
import { firestore } from 'firebase/app';
import { select, Store } from '@ngrx/store';
import { getSelectedQuestionState } from '../../../core/ngrx/questions/questions.selectors';
import { getDescriptionsState, getSelectedDescriptionState } from '../../../core/ngrx/descriptions/descriptions.selectors';
import { getExplanationsState, getSelectedExplanationState } from '../../../core/ngrx/explanations/explanations.selectors';
import { environment } from '../../../../environments/environment';
import { getTopicsState } from '../../../core/ngrx/topics/topics.selectors';
import { loadExplanations, loadExplanationsCount, resetSelectedExplanation } from '../../../core/ngrx/explanations/explanations.actions';
import { loadDescriptions, loadDescriptionsCount, resetSelectedDescription } from '../../../core/ngrx/descriptions/descriptions.actions';
import { saveQuestion, saveQuestionWithImages } from '../../../core/ngrx/questions/questions.actions';
import { getAuthState } from '../../../core/ngrx/authentication/authentication.selectors';
import { DescriptionService } from '../../../services/Description/description.service';
import { ExplanationService } from '../../../services/Explanation/explanation.service';
import { HelperService } from '../../../services/Helper/helper.service';
import { KeyValue } from '@angular/common';
import { EditorUser } from '../../../core/ngrx/editorUsers/editorUsers.interfaces';

@Component({
  selector: 'em-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss'],
})
export class QuestionFormComponent implements OnDestroy {

  $destroy: Subject<boolean> = new Subject<boolean>();

  dataAndTypeForm: FormGroup;
  optionAndTopicForm: FormGroup;
  optionalsForm: FormGroup;
  @ViewChild('textEntered', { static: true }) quesForm: FormControlDirective;

  question: Question = { id: null, answer: null, data: [], index: null, language: null, options: null,
    status: null, subtopicID: null, title: null, topicID: null, type: [],
    created: null, lastUpdatedBy: null, lastUpdated: null, audit: {} };
  removedImages: ImageRefs[] = [];
  uploadedImages: ImageRefs[] = [];

  editing: number;
  loading = false;
  initialLoaded = false;
  isReversed = false;
  isAnEditForm: boolean;
  imageLoading = true;
  errorOnSave:string;
  attemptedToSave = false;

  topics: TopicObject;
  descriptionsState: DescriptionsState;
  descriptionActivePreviews: DescriptionPreview[] = [];
  descriptionFilteredPreviews: DescriptionPreview[] = [];
  descriptionOffset: number;
  newDescriptionSubscription: Subscription;
  newDescriptionCheckInterval;
  createdDescriptionLoading = false;
  searchingDescription = false;
  searchDescriptionError = false;
  descriptionShownIndex: number;
  explanationsState: ExplanationsState;
  explanationActivePreviews: ExplanationPreview[] = [];
  explanationFilteredPreviews: ExplanationPreview[] = [];
  explanationOffset: number;
  newExplanationSubscription: Subscription;
  newExplanationCheckInterval;
  createdExplanationLoading = false;
  searchingExplanation = false;
  searchExplanationError = false;
  explanationShownIndex: number;
  availableStatus = allStatusArray;
  statusSelectorEnabled = false;
  questionStatus: Status;
  currentUser: EditorUser;

  langs = languages;
  minOptions = 2;
  maxOptions = 5;
  ansArray = ['a', 'b', 'c', 'd', 'e'];
  paginateChangeValue: number = environment.paginateVal;
  intervalCheckTime = 500;

  constructor (
    private formBuilder: FormBuilder,
    private store: Store<any>,
    private dialogRef: MatDialogRef<QuestionFormComponent>,
    private imageService: ImageService,
    private helperService: HelperService,
    private descriptionService: DescriptionService,
    private explanationService: ExplanationService,
    private imageCompressService: NgxImageCompressService,
  ) {
    this.store.dispatch(resetSelectedDescription());
    this.store.dispatch(resetSelectedExplanation());
    this.store.pipe(takeUntil(this.$destroy), select(getAuthState)).subscribe((authState) => {
      if (authState && authState.user) {
        this.isReversed = authState.user.newToOld;
        this.setStateSubscriptions();
      }
    });
  }

  setStateSubscriptions () {
    this.store.pipe(takeUntil(this.$destroy), select(getSelectedQuestionState)).subscribe((selectedQuestion) => {
      const loading = selectedQuestion.loading || selectedQuestion.uploadingImages;
      if (!loading && !selectedQuestion.error && !this.attemptedToSave && !this.initialLoaded) {
        this.question = !isNullOrUndefined(selectedQuestion.question) ? _cloneDeep(selectedQuestion.question) : this.question;
        this.questionStatus = _cloneDeep(this.question.status);
        this.isAnEditForm = !isNullOrUndefined(selectedQuestion.question);
      }
      if (!loading && !selectedQuestion.error && this.attemptedToSave) {
        this.dialogRef.close();
      }
      if (selectedQuestion.error) {
        this.attemptedToSave = false;
      }
    });
    this.store.pipe(takeUntil(this.$destroy), select(getDescriptionsState)).subscribe((descriptions) => {
      if (!descriptions.loading && !descriptions.error) {
        this.descriptionsState = descriptions;
        this.descriptionOffset = this.descriptionsState.previewsTotalCount % environment.paginateVal;
        if (!this.initialLoaded) {
          this.filterPreviews('description', 0);
        }
      }
    });
    this.store.pipe(takeUntil(this.$destroy), select(getExplanationsState)).subscribe((explanations) => {
      if (!explanations.loading && !explanations.error) {
        this.explanationsState = explanations;
        this.explanationOffset = this.explanationsState.previewsTotalCount % environment.paginateVal;
        if (!this.initialLoaded) {
          this.filterPreviews('explanation', 0);
        }
      }
    });
    this.store.pipe(takeUntil(this.$destroy), select(getTopicsState)).subscribe((topics) => {
      if (!topics.loading && !topics.error) {
        this.topics = _cloneDeep(topics.serverTopicList);
      }
    });
    this.store.pipe(takeUntil(this.$destroy), select(getAuthState)).subscribe((auth) => {
      this.statusSelectorEnabled = auth.appPermits.canDeleteQuestion;
      this.currentUser = auth.user;
    });
    combineLatest(
      [
        this.store.pipe(takeUntil(this.$destroy), select(getSelectedQuestionState)),
        this.store.pipe(takeUntil(this.$destroy), select(getDescriptionsState)),
        this.store.pipe(takeUntil(this.$destroy), select(getExplanationsState)),
        this.store.pipe(takeUntil(this.$destroy), select(getTopicsState)),
      ],
    ).subscribe(([selectedQuestion, descriptions, explanations, topics]) => {
      this.loading = selectedQuestion.loading || selectedQuestion.uploadingImages
        || descriptions.loading || explanations.loading || topics.loading;
      if (!isNullOrUndefined(this.isAnEditForm) && !isNullOrUndefined(this.topics) && !this.formsAreSetUp()
        && !isNullOrUndefined(this.descriptionsState) && !isNullOrUndefined(this.explanationsState)
      ) {
        this.generateDataAndTypeForm(this.isAnEditForm);
        this.generateOptionAndTopicForm(this.isAnEditForm);
        this.generateOptionalsForm(this.isAnEditForm);
        this.initialLoaded = true;
      }
    });
  }

  formsAreSetUp (): boolean {
    return !!this.dataAndTypeForm && !!this.optionAndTopicForm && !!this.optionalsForm;
  }

  get getOptionsArray (): FormArray {
    return <FormArray>this.optionAndTopicForm.get('options');
  }

  get getImagesArray (): FormArray {
    return <FormArray>this.dataAndTypeForm.get('images');
  }

  subtopicIsDisabled (): boolean {
    return isNullOrUndefined(this.optionAndTopicForm.get('topic').value) || this.optionAndTopicForm.get('topic').value === '';
  }

  sortTopics (a: KeyValue<string, Topic>, b: KeyValue<string, Topic>): number {
    return parseInt(a.key.substr(1), 10) > parseInt(b.key.substr(1), 10) ? 1 : -1;
  }

  newDescription () {
    const expectedCalls = 5;
    if (this.newDescriptionSubscription) {
      this.newDescriptionSubscription.unsubscribe();
      this.newDescriptionSubscription = undefined;
    }
    this.helperService.navigateTo('descriptions/create');
    this.newDescriptionSubscription = this.store.pipe(take(expectedCalls), select(getSelectedDescriptionState)).subscribe((description) => {
      if (!description.loading && description.description && description.description.id) {
        this.store.dispatch(loadDescriptionsCount());
        this.createdDescriptionLoading = true;
        this.setCreatedDescriptionInterval(description.description.id);
      }
    });
  }

  editSelectedDescription () {
    const control = this.optionalsForm.get('description');
    if (control && control.value) {
      const description = this.helperService.transformReferenceToId(control.value);
      this.helperService.navigateTo(`descriptions/edit/${description}`);
    }
  }

  setCreatedDescriptionInterval (expectedId: string) {
    let maxIndex = this.isReversed ? 0 : this.descriptionsState.previewsMaxIndex;
    this.runInitialDataLoaders('Des', this.descriptionsState.previewsMaxIndex);
    if (this.newDescriptionCheckInterval) {
      clearInterval(this.newDescriptionCheckInterval);
      this.newDescriptionCheckInterval = undefined;
    }
    this.newDescriptionCheckInterval = setInterval(
      () => {
        const lastIndex = this.isReversed ? 0 : this.descriptionsState.previews.length - 1;
        const last = this.descriptionsState.previews[lastIndex];
        const lastPath: string = last.reference instanceof firestore.DocumentReference ? last.reference.path : last.reference;
        const lastId: string = lastPath.substring(lastPath.lastIndexOf('/') + 1, lastPath.length);
        if (this.descriptionShownIndex === maxIndex && lastId === expectedId) {
          setTimeout(() => {
            if (last.reference instanceof firestore.DocumentReference) {
              this.optionalsForm.get('description').setValue(last.reference.path);
            } else {
              this.optionalsForm.get('description').setValue(last.reference);
            }
            clearInterval(this.newDescriptionCheckInterval);
            this.newDescriptionCheckInterval = undefined;
            this.refreshData('Des', maxIndex);
            this.createdDescriptionLoading = false;
          });
        } else {
          if (this.descriptionsState.previewsMaxIndex !== maxIndex) {
            maxIndex = this.isReversed ? 0 : this.descriptionsState.previewsMaxIndex;
            this.runInitialDataLoaders('Des', this.descriptionsState.previewsMaxIndex);
          }
        }
      },
      this.intervalCheckTime,
    );
  }

  newExplanation () {
    const expectedCalls = 5;
    if (this.newExplanationSubscription) {
      this.newExplanationSubscription.unsubscribe();
      this.newExplanationSubscription = undefined;
    }
    this.helperService.navigateTo('explanations/create');
    this.newExplanationSubscription = this.store.pipe(take(expectedCalls), select(getSelectedExplanationState)).subscribe((explanation) => {
      if (!explanation.loading && explanation.explanation && explanation.explanation.id) {
        this.store.dispatch(loadExplanationsCount());
        this.createdExplanationLoading = true;
        this.setCreatedExplanationInterval(explanation.explanation.id);
      }
    });
  }

  editSelectedExplanation () {
    const control = this.optionalsForm.get('explanation');
    if (control && control.value) {
      const explanation = this.helperService.transformReferenceToId(control.value);
      return this.helperService.navigateTo(`explanations/edit/${explanation}`);
    }
  }

  setCreatedExplanationInterval (expectedId: string) {
    let maxIndex = this.isReversed ? 0 : this.explanationsState.previewsMaxIndex;
    this.runInitialDataLoaders('Exp', this.explanationsState.previewsMaxIndex);
    if (this.newExplanationCheckInterval) {
      clearInterval(this.newExplanationCheckInterval);
      this.newExplanationCheckInterval = undefined;
    }
    this.newExplanationCheckInterval = setInterval(
      () => {
        const lastIndex = this.isReversed ? 0 : this.explanationsState.previews.length - 1;
        const last = this.explanationsState.previews[lastIndex];
        const lastPath: string = last.reference instanceof firestore.DocumentReference ? last.reference.path : last.reference;
        const lastId: string = lastPath.substring(lastPath.lastIndexOf('/') + 1, lastPath.length);
        if (this.explanationShownIndex === maxIndex && lastId === expectedId) {
          setTimeout(() => {
            if (last.reference instanceof firestore.DocumentReference) {
              this.optionalsForm.get('explanation').setValue(last.reference.path);
            } else {
              this.optionalsForm.get('explanation').setValue(last.reference);
            }
            clearInterval(this.newExplanationCheckInterval);
            this.newExplanationCheckInterval = undefined;
            this.refreshData('Exp', maxIndex);
            this.createdExplanationLoading = false;
          });
        } else {
          if (this.explanationsState.previewsMaxIndex !== maxIndex) {
            maxIndex = this.isReversed ? 0 : this.explanationsState.previewsMaxIndex;
            this.runInitialDataLoaders('Exp', this.explanationsState.previewsMaxIndex);
          }
        }
      },
      this.intervalCheckTime,
    );
  }

  refreshData (type: 'Exp' | 'Des', newValue: number) {
    const expectedCalls = 2;
    const valueToLoad = newValue + 1;
    if (type === 'Exp') {
      if (valueToLoad > this.explanationsState.previewsIndexLoaded && valueToLoad <= this.explanationsState.previewsMaxIndex) {
        this.store.dispatch(loadExplanations({ paginateVal: valueToLoad }));
        this.store.pipe(take(expectedCalls), select(getExplanationsState)).subscribe((explanations) => {
          if (!explanations.loading && !explanations.error) {
            this.filterPreviews('explanation', newValue);
          }
        });
      } else {
        this.filterPreviews('explanation', newValue);
      }
    } else {
      if (valueToLoad > this.descriptionsState.previewsIndexLoaded && valueToLoad <= this.descriptionsState.previewsMaxIndex) {
        this.store.dispatch(loadDescriptions({ paginateVal: valueToLoad }));
        this.store.pipe(take(expectedCalls), select(getDescriptionsState)).subscribe((descriptions) => {
          if (!descriptions.loading && !descriptions.error) {
            this.filterPreviews('description', newValue);
          }
        });
      } else {
        this.filterPreviews('description', newValue);
      }
    }
  }

  runInitialDataLoaders (type: 'Exp' | 'Des', requiredIndex: number) {
    let requiredValue = requiredIndex;
    if (this.isReversed) {
      const maxIndex = type === 'Exp' ? this.explanationsState.previewsMaxIndex : this.descriptionsState.previewsMaxIndex;
      requiredValue = maxIndex - requiredValue + 1;
    }
    if (type === 'Exp') {
      const finishedExplanations: Subject<boolean> = new Subject<boolean>();
      let lastRequestedIndex = this.explanationsState.previewsIndexLoaded;
      this.store.pipe(takeUntil(finishedExplanations), select(getExplanationsState)).subscribe((explanations) => {
        const isNotLoading: boolean = !explanations.loading;
        const isPreviousThanTheRequiredIndex: boolean = explanations.previewsIndexLoaded < requiredValue;
        const isNextValueAlreadyInState: boolean = explanations.previewsIndexLoaded + 1 !== lastRequestedIndex;
        if (isNotLoading && isPreviousThanTheRequiredIndex && isNextValueAlreadyInState) {
          lastRequestedIndex = explanations.previewsIndexLoaded + 1;
          this.store.dispatch(loadExplanations({ paginateVal: explanations.previewsIndexLoaded + 1 }));
        }
        if (explanations.previewsIndexLoaded >= requiredValue) {
          finishedExplanations.next(true);
          finishedExplanations.unsubscribe();
          this.searchingExplanation = false;
          this.refreshData('Exp', this.isReversed ? requiredValue - 1 : requiredValue);
        }
      });
    } else {
      const finishedDescriptions: Subject<boolean> = new Subject<boolean>();
      let lastRequestedIndex = this.descriptionsState.previewsIndexLoaded;
      this.store.pipe(takeUntil(finishedDescriptions), select(getDescriptionsState)).subscribe((descriptions) => {
        const isNotLoading: boolean = !descriptions.loading;
        const isPreviousThanTheRequiredIndex: boolean = descriptions.previewsIndexLoaded < requiredValue;
        const isNextValueAlreadyInState: boolean = descriptions.previewsIndexLoaded + 1 !== lastRequestedIndex;
        if (isNotLoading && isPreviousThanTheRequiredIndex && isNextValueAlreadyInState) {
          lastRequestedIndex = descriptions.previewsIndexLoaded + 1;
          this.store.dispatch(loadDescriptions({ paginateVal: descriptions.previewsIndexLoaded + 1 }));
        }
        if (descriptions.previewsIndexLoaded >= requiredValue) {
          finishedDescriptions.next(true);
          finishedDescriptions.unsubscribe();
          this.searchingDescription = false;
          this.refreshData('Des', this.isReversed ? requiredValue - 1 : requiredValue);
        }
      });
    }
  }

  generateDataAndTypeForm (isEditForm: boolean) {
    let images = [];
    if (isEditForm) {
      images = this.question.data.filter((ques, i) => this.question.type[i] === 'img');
      if (images && images.length === 0) {
        this.imageLoading = false;
      } else {
        images.forEach((imgName) => {
          this.imageService.getImageRef(imgName, 'questions').toPromise().then((ref) => {
            this.uploadedImages.push({ name: imgName, URL: ref, loading: true });
          });
        });
      }
    } else {
      this.imageLoading = false;
    }
    this.dataAndTypeForm = this.formBuilder.group(
      {
        textEntered: ['', HelperValidators.isValidData(this.question.data, this.question.type)],
        title: [this.question.title],
        images: this.formBuilder.array([this.createImageInput()]),
      },
    );
    if (!this.isAnEditForm || images.length !== this.question.type.length) {
      this.dataAndTypeForm.get('title').disable();
    }
  }

  generateOptionAndTopicForm (isEditForm: boolean) {
    let options;
    if (isEditForm) {
      options = this.question.options;
    }
    this.optionAndTopicForm = this.formBuilder.group(
      {
        language: [isEditForm ? this.question.language : '', Validators.required],
        topic: [this.question.topicID, Validators.required],
        subtopic: [this.question.subtopicID, Validators.required],
        options: this.formBuilder.array(
          [
            this.createOptionInput(isEditForm ? options.a : null),
            this.createOptionInput(isEditForm ? options.b : null),
          ]),
        answer: [this.question.answer, Validators.required],
      },
    );
    if (isEditForm && options.c) {
      const arr = <FormArray>this.optionAndTopicForm.get('options');
      arr.push(this.createOptionInput(options.c));
    }
    if (isEditForm && options.d) {
      const arr = <FormArray>this.optionAndTopicForm.get('options');
      arr.push(this.createOptionInput(options.d));
    }
    if (isEditForm && options.e) {
      const arr = <FormArray>this.optionAndTopicForm.get('options');
      arr.push(this.createOptionInput(options.e));
    }
    this.optionAndTopicForm.get('topic').valueChanges.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.optionAndTopicForm.get('subtopic').enable();
      this.optionAndTopicForm.get('subtopic').setValue('');
    });
  }

  generateOptionalsForm (isEditForm: boolean) {
    let descPath = '';
    let explPath = '';
    if (isEditForm) {
      if (this.question.descriptionReference) {
        if (this.question.descriptionReference instanceof firestore.DocumentReference) {
          descPath = this.question.descriptionReference.path;
        } else {
          descPath = this.question.descriptionReference;
        }
        this.runInitialDataLoaders('Des', this.question.descriptionPreviewIndex);
      }
      if (this.question.explanationReference) {
        if (this.question.explanationReference instanceof firestore.DocumentReference) {
          explPath = this.question.explanationReference.path;
        } else {
          explPath = this.question.explanationReference;
        }
        this.runInitialDataLoaders('Exp', this.question.explanationPreviewIndex);
      }
    }
    this.optionalsForm = this.formBuilder.group(
      {
        description: descPath,
        descriptionSearch: '',
        descriptionIdSearch: '',
        explanation: explPath,
        explanationSearch: '',
        explanationIdSearch: '',
        year: [isEditForm ? this.question.year : '', HelperValidators.validYear],
      },
    );
    this.optionalsForm.get('explanationSearch').valueChanges.pipe(takeUntil(this.$destroy)).subscribe((text: string) => {
      if (text === '' || isNullOrUndefined(text)) {
        return this.explanationFilteredPreviews = this.explanationActivePreviews;
      }
      return this.explanationFilteredPreviews = this.explanationActivePreviews.filter((preview) => {
        return preview.title.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) !== -1;
      });
    });
    this.optionalsForm.get('descriptionSearch').valueChanges.pipe(takeUntil(this.$destroy)).subscribe((text: string) => {
      if (text === '' || isNullOrUndefined(text)) {
        return this.descriptionFilteredPreviews = this.descriptionActivePreviews;
      }
      return this.descriptionFilteredPreviews = this.descriptionActivePreviews.filter((preview) => {
        return preview.title.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) !== -1;
      });
    });
    this.optionalsForm.get('description').valueChanges.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.searchDescriptionError = false;
      this.optionalsForm.get('descriptionIdSearch').setValidators([]);
    });
    this.optionalsForm.get('explanation').valueChanges.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.searchExplanationError = false;
      this.optionalsForm.get('explanationIdSearch').setValidators([]);
    });
    const time = 500;
    this.optionalsForm.get('descriptionIdSearch').valueChanges
      .pipe(takeUntil(this.$destroy), debounceTime(time))
      .subscribe((id: string) => id ? this.searchForResourceById('description', id) : undefined)
    ;
    this.optionalsForm.get('explanationIdSearch').valueChanges
      .pipe(takeUntil(this.$destroy), debounceTime(time))
      .subscribe((id: string) => id ? this.searchForResourceById('explanation', id) : undefined)
    ;

  }

  drop (event: CdkDragDrop<string[]>) {
    moveItemInArray(this.question.data, event.previousIndex, event.currentIndex);
    moveItemInArray(this.question.type, event.previousIndex, event.currentIndex);
  }

  loadedImage (imgName: string) {
    const image = this.uploadedImages.find(image => image.name === imgName);
    if (image) {
      image.loading = false;
    }
    if (!this.uploadedImages.filter(image => image.loading).length) {
      this.imageLoading = false;
    }
  }

  createImageInput () {
    return this.formBuilder.group(
      {
        image: '',
      },
    );
  }

  createOptionInput (option?: Option) {
    const value = option ? option.data[0] : '';
    return this.formBuilder.group(
      {
        option: [value, Validators.required],
      },
    );
  }

  searchForResourceById (type: 'description' | 'explanation', id: string) {
    if (type === 'description') {
      const path = `${environment.projectId}/descriptions/${id}`;
      this.searchingDescription = true;
      this.optionalsForm.get('descriptionIdSearch').disable({ emitEvent:false });
      this.descriptionService.getDescription(path).pipe(take(1)).subscribe((description) => {
        if (description) {
          const requiredIndex = Math.trunc(description.index / environment.paginateVal);
          this.optionalsForm.get('description').setValue(path);
          this.runInitialDataLoaders('Des', requiredIndex);
        } else {
          this.searchDescriptionError = true;
          this.searchingDescription = false;
          this.optionalsForm.get('descriptionIdSearch').setValidators([HelperValidators.invalidIf(true)]);
        }
        this.optionalsForm.get('descriptionIdSearch').enable({ emitEvent:false });
      });
    } else {
      const path = `${environment.projectId}/explanations/${id}`;
      this.searchingExplanation = true;
      this.optionalsForm.get('explanationIdSearch').disable({ emitEvent:false });
      this.explanationService.getExplanation(path).pipe(take(1)).subscribe((explanation) => {
        if (explanation) {
          const requiredIndex = Math.trunc(explanation.index / environment.paginateVal);
          this.optionalsForm.get('explanation').setValue(path);
          this.runInitialDataLoaders('Exp', requiredIndex);
        } else {
          this.searchExplanationError = true;
          this.searchingExplanation = false;
          this.optionalsForm.get('explanationIdSearch').setValidators([HelperValidators.invalidIf(true)]);
        }
        this.optionalsForm.get('explanationIdSearch').enable({ emitEvent:false });
      });
    }
  }

  addOption () {
    const arr = <FormArray>this.optionAndTopicForm.get('options');
    if (arr.length < this.maxOptions) {
      arr.push(this.createOptionInput());
      this.setScrollToEnd();
    }
  }

  removeOption () {
    const arr = <FormArray>this.optionAndTopicForm.get('options');
    if (arr.length > this.minOptions) {
      arr.removeAt(arr.length - 1);
    }
  }

  addText (): void {
    const contenido = this.dataAndTypeForm.get('textEntered').value;
    this.dataAndTypeForm.get('title').disable();
    if (contenido !== '' && !isNullOrUndefined(contenido)) {
      if (this.question.type.filter(ques => ques === 'txt').length === 0) {
        this.question.title = contenido;
        this.dataAndTypeForm.get('title').enable();
        setTimeout(() => {
          this.dataAndTypeForm.get('title').setValue(contenido);
          this.dataAndTypeForm.get('title').disable();
        });
      }
      this.question.type.push('txt');
      this.question.data.push(contenido);
      this.dataAndTypeForm.get('textEntered').reset();
    }
  }

  clickedAddImage (i: number): void {
    const text = 'questionImage';
    document.getElementById(text + i).click();
  }

  addImage (event): void {
    const image = event.target.files[0];
    if (!image) {
      return;
    }
    this.errorOnSave = undefined;
    const mimeType = image.type;
    if (mimeType.match(/image\/jpeg/) === null && mimeType.match(/image\/png/) === null) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      const factor = 1024;
      const mbAllowed = 1.5;
      const size = this.imageCompressService.byteCount(<string>reader.result);
      if (size > mbAllowed * factor * factor) {
        this.errorOnSave = 'size';
        return;
      }
      const imageInstance = new Image();
      imageInstance.src = <string>reader.result;
      imageInstance.onload = () => {
        const type = imageInstance.width > imageInstance.height ? -1 : 1;
        this.compress(<string>reader.result, type, size, mbAllowed * factor * factor);
      };
      this.question.type.push('preview');
      this.question.data.push(<string>reader.result);
      this.dataAndTypeForm.get('textEntered').updateValueAndValidity();
      if (this.question.type.filter(ques => ques === 'txt').length === 0) {
        this.dataAndTypeForm.get('title').enable();
      }
    };
    const imageControls = <FormArray>this.dataAndTypeForm.get('images');
    imageControls.push(this.createImageInput());
  }

  removeItem (index: number): void {
    if (this.question.type[index] === 'preview') {
      const imageControls = <FormArray>this.dataAndTypeForm.get('images');
      let counter = 0;
      this.question.type.forEach((type, i) => {
        if (type === 'preview') {
          if (i === index) {
            imageControls.removeAt(counter);
            this.question.type.splice(index, 1);
            this.question.data.splice(index, 1);
          } else {
            counter = counter + 1;
          }
        }
      });
    } else {
      if (this.question.type[index] === 'img') {
        const img = this.uploadedImages.find(image => image.name === this.question.data[index]);
        this.removedImages.push(img);
      } else if (this.question.type.filter(ques => ques === 'txt').length === 1
        && this.question.type[index] === 'txt') {
        this.dataAndTypeForm.get('title').enable();
      }
      this.question.type.splice(index, 1);
      this.question.data.splice(index, 1);
    }
  }

  editItem (index: number) {
    this.editing = index;
    this.dataAndTypeForm.get('textEntered').setValue(this.question.data[index]);
  }

  updateText () {
    const contenido = this.dataAndTypeForm.get('textEntered').value;
    let titleIndex = -1;
    this.question.type.forEach((tipo, i) => {
      if (tipo === 'txt' && titleIndex === -1) {
        titleIndex = i;
      }
    });
    if (this.editing === titleIndex) {
      this.question.title = contenido;
      this.dataAndTypeForm.get('title').enable();
      setTimeout(() => {
        this.dataAndTypeForm.get('title').setValue(contenido);
        this.dataAndTypeForm.get('title').disable();
      });
    }
    this.question.data[this.editing] = contenido;
    this.cancelUpdateText();
  }

  cancelUpdateText () {
    this.editing = undefined;
    this.dataAndTypeForm.get('textEntered').setValue('');
    this.dataAndTypeForm.get('textEntered').reset();
  }

  filterPreviews (type: 'description' | 'explanation', index: number) {
    if (type === 'description') {
      let previews: DescriptionPreview[] = _cloneDeep(this.descriptionsState.previewsPages[index]);
      if (this.descriptionsState.previewsPages[index + 1]) {
        previews = previews.concat(_cloneDeep(this.descriptionsState.previewsPages[index + 1]));
      }
      if (this.isReversed) {
        const startVal = index === 0 ? 0 : environment.paginateVal - this.descriptionOffset;
        previews = previews.slice(startVal, environment.paginateVal + startVal);
      }
      this.descriptionActivePreviews = previews;
      this.descriptionFilteredPreviews = previews;
      this.descriptionShownIndex = index;
    } else {
      let previews: ExplanationPreview[] = _cloneDeep(this.explanationsState.previewsPages[index]);
      if (this.explanationsState.previewsPages[index + 1]) {
        previews = previews.concat(_cloneDeep(this.explanationsState.previewsPages[index + 1]));
      }
      if (this.isReversed) {
        const startVal = index === 0 ? 0 : environment.paginateVal - this.explanationOffset;
        previews = previews.slice(startVal, environment.paginateVal + startVal);
      }
      this.explanationActivePreviews = previews;
      this.explanationFilteredPreviews = previews;
      this.explanationShownIndex = index;
    }
  }

  attempt () {
    if (this.dataAndTypeForm.valid && this.optionAndTopicForm.valid) {
      this.errorOnSave = undefined;
      if (!this.loading) {
        const control = this.dataAndTypeForm.get('title');
        // Check if title is correctly set
        if (this.question.title || (control.enabled && control.value !== '')) {
          if (control.enabled && control.value !== '') {
            this.question.title = control.value;
          }
          this.loading = true;
          // Check if there's any image for uploading to server
          if (this.question.type.filter(t => t === 'preview').length) {
            // Upload each image to server
            this.question.data.forEach((data, i) => {
              if (this.question.type[i] === 'preview') {
                this.imageService.uploadImage(data, 'questions').then((resp: UploadTaskSnapshot) => {
                  this.question.data[i] = resp.metadata.name;
                  this.question.type[i] = 'img';
                  // After uploading the last image, save the question
                  if (!this.question.type.filter(t => t === 'preview').length) {
                    this.saveAfterUpload();
                  }
                });
              }
            });
          } else {
            this.saveAfterUpload();
          }
        } else {
          this.dataAndTypeForm.get('title').enable();
          this.errorOnSave = 'title';
        }
      }
    }
  }

  getImageSrc (imgName: string, type: dataType) {
    const img = this.uploadedImages.find(img => img.name === imgName);
    if (type === 'img') {
      return img ? img.URL : '';
    }
    return imgName;
  }

  saveAfterUpload () {
    if (this.removedImages.length) {
      this.removedImages.forEach((image) => {
        this.imageService.deleteImage(image.name, 'questions');
      });
    }
    if (!this.isAnEditForm) {
      this.question.status = 'created';
    }
    const opt = this.optionAndTopicForm.getRawValue();
    const optionals = this.optionalsForm.getRawValue();
    // opt.topic = `t${opt.topic + 1}`;
    // opt.subtopic = `s${opt.subtopic + 1}`;
    const options = {};
    opt.options.forEach((option, i) => {
      const optionsArr = ['a', 'b', 'c', 'd', 'e'];
      options[optionsArr[i]] = { data: [option.option], type: ['txt'] };
    });
    opt.options = options;
    this.question.answer = opt.answer;
    this.question.language = opt.language;
    this.question.options = opt.options;
    this.question.topicID = opt.topic;
    this.question.subtopicID = opt.subtopic;
    this.question.descriptionReference = optionals.description ? optionals.description : null;
    this.question.descriptionPreviewIndex = this.getCurrentIndexActive('description', optionals.description);
    this.question.explanationReference = optionals.explanation ? optionals.explanation : null;
    this.question.explanationPreviewIndex = this.getCurrentIndexActive('explanation', optionals.explanation);
    this.question.year = optionals.year ? optionals.year : null;
    if (this.statusSelectorEnabled && this.isAnEditForm && this.question.status !== this.questionStatus) {
      this.question.status = this.questionStatus;
      this.question.audit = this.generateAuditObject(this.question.audit, this.question.status);
    }
    const previews = this.question.data.filter((img, i) => this.question.type[i] === 'preview');
    if (previews.length > 0) {
      this.store.dispatch(saveQuestionWithImages(
        { imagesToUpload: previews, question: this.question, imagesToDelete: this.removedImages },
      ));
      this.attemptedToSave = true;
    } else {
      this.store.dispatch(saveQuestion({ question: this.question, imagesToDelete: this.removedImages }));
      this.attemptedToSave = true;
    }
  }

  getCurrentIndexActive (type: 'description' | 'explanation', path: string): number {
    if (path) {
      let preview: number;
      let maxIndex: number;
      if (type === 'description') {
        maxIndex = this.descriptionsState.previewsMaxIndex;
        preview = this.descriptionsState.previews.findIndex((p) => {
          if (p.reference instanceof firestore.DocumentReference) {
            return p.reference.path === path;
          }
          return p.reference === path;
        });
      } else {
        maxIndex = this.explanationsState.previewsMaxIndex;
        preview = this.explanationsState.previews.findIndex((p) => {
          if (p.reference instanceof firestore.DocumentReference) {
            return p.reference.path === path;
          }
          return p.reference === path;
        });
      }
      const paginationVal = Math.floor(preview / this.paginateChangeValue);
      if (this.isReversed) {
        return maxIndex - paginationVal;
      }
      return paginationVal;
    }
    return null;
  }

  compress (fileInput: string, orientation: number, size: number, maxSizeAllowed: number) {
    const minSize = 40000;
    const minQualityFactor = 40;
    const minRatioFactor = 50;
    const maxFactor = 95;
    const ratio = Math.round(QuestionFormComponent.mapRange(size, minSize, maxSizeAllowed, maxFactor, minRatioFactor));
    const quality = Math.round(QuestionFormComponent.mapRange(size, minSize, maxSizeAllowed, maxFactor, minQualityFactor));
    this.imageCompressService.compressFile(fileInput, orientation, ratio, quality).then(
      (image) => {
        const updatedSize = this.imageCompressService.byteCount(<string>image);
        const percent = 100;
        const decimals = 4;
        const compressionPercent = percent - (updatedSize * percent / size);
        this.question.data.pop();
        this.question.data.push(image);
        console.info(`Compressed, from ${size} bytes to ${updatedSize} bytes` +
                       `(compressed ${compressionPercent.toFixed(decimals)}%) with ratio ${ratio}, and quality ${quality}`);
      },
      error => console.info('Error while converting', error),
    );
  }

  static mapRange (x: number, inMin: number, inMax: number, outMin: number, outMax: number): number {
    return (x - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  }

  copyIDToClipboard (id: string) {
    this.helperService.copyToClipboard(id);
  }

  copyURLToClipboard (id: string) {
    const url = `${environment.projectUrl}/questions/edit/${id}`;
    this.helperService.copyToClipboard(url);
  }

  setScrollToEnd () {
    setTimeout(() => {
      document.querySelector('.mat-dialog-content')
        .scrollTo(0, document.querySelector('.mat-dialog-content').scrollHeight);
    });
  }

  generateAuditObject (currentAudit: Audit, newStatus: Status): Audit {
    switch (newStatus) {
      case 'published':
        return { ...currentAudit, published: firestore.Timestamp.now(), publishedBy: this.currentUser.email };
      case 'unpublished':
        return { ...currentAudit, unpublished: firestore.Timestamp.now(), unpublishedBy: this.currentUser.email };
      case 'deleted':
        return { ...currentAudit, deleted: firestore.Timestamp.now(), deletedBy: this.currentUser.email };
      default:
        return currentAudit;
    }
  }

  close (): void {
    this.dialogRef.close();
  }

  ngOnDestroy () {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
