import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HelperValidators } from '../../../validators/helper-validators';
import { environment } from '../../../../environments/environment';
import { EditorUser, rolesKeys } from '../../../core/ngrx/editorUsers/editorUsers.interfaces';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getSelectedUserState } from '../../../core/ngrx/editorUsers/editorUsers.selectors';
import * as userActions from '../../../core/ngrx/editorUsers/editorUsers.actions';
import * as authActions from '../../../core/ngrx/authentication/authentication.actions';
import { getAuthState } from '../../../core/ngrx/authentication/authentication.selectors';
import * as _cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'em-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnDestroy {

  $destroy: Subject<boolean> = new Subject<boolean>();

  userForm: FormGroup;
  user: EditorUser;
  localeDateOptions = environment.localeDateOptions;
  loading = true;
  isAnEditForm = false;
  isSelfForm = false;
  roles = rolesKeys;
  minPassLength = 6;
  attemptedToSave = false;

  constructor (private formBuilder: FormBuilder,
               private store: Store<any>,
               private dialogRef: MatDialogRef<UserFormComponent>,
               @Inject(MAT_DIALOG_DATA) private data: any) {
    this.isSelfForm = data && data.isSelf;
    if (this.isSelfForm) {
      this.setSelfUserStateSubsciptions();
      this.isAnEditForm = true;
      this.user = data.user;
      this.generateEditForm();
    } else {
      this.setStateSubscriptions();
    }
  }

  setStateSubscriptions () {
    this.store.pipe(
      takeUntil(this.$destroy),
      select(getSelectedUserState),
    ).subscribe((selectedUser) => {
      this.loading = selectedUser.loading;
      if (!this.loading && !selectedUser.error && !this.attemptedToSave) {
        if (selectedUser.user) {
          this.user = _cloneDeep(selectedUser.user);
          this.isAnEditForm = true;
          this.generateEditForm();
        } else {
          this.generateCreateForm();
        }
      }
      if (!this.loading && !selectedUser.error && this.attemptedToSave) {
        this.dialogRef.close();
      }
      if (selectedUser.error) {
        this.attemptedToSave = false;
      }
    });
  }

  setSelfUserStateSubsciptions () {
    this.store.pipe(
      takeUntil(this.$destroy),
      select(getAuthState),
    ).subscribe((authState) => {
      this.loading = authState.loading;
      if (authState.updated && !authState.loading && !authState.error && this.attemptedToSave) {
        this.dialogRef.close();
      }
    });
  }

  generateCreateForm () {
    this.userForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(this.minPassLength)]],
        password_confirm: ['', Validators.required],
        roles: [[], Validators.required],
        whatShowFirst: ['', Validators.required],
      },
    );
    this.userForm.get('password').valueChanges.subscribe((pass) => {
      this.userForm.get('password_confirm').setValidators([Validators.minLength(this.minPassLength)]);
      this.userForm.get('password_confirm').updateValueAndValidity();
      this.userForm.get('password_confirm').setValidators([HelperValidators.mustEqual(pass)]);
      this.userForm.get('password_confirm').updateValueAndValidity();
    });
  }

  generateEditForm () {
    this.userForm = this.formBuilder.group(
      {
        email: [this.user.email, [Validators.required, Validators.email]],
        password: ['', [Validators.minLength(this.minPassLength)]],
        password_confirm: [''],
        roles: [Object.keys(this.user.roles), Validators.required],
        whatShowFirst: [this.user.newToOld ? 'new' : 'old', Validators.required],
      },
    );
    this.userForm.get('password').valueChanges.subscribe((pass) => {
      this.userForm.get('password_confirm').setValidators([Validators.minLength(this.minPassLength)]);
      this.userForm.get('password_confirm').updateValueAndValidity();
      this.userForm.get('password_confirm').setValidators([HelperValidators.requiredIf(pass), HelperValidators.mustEqual(pass)]);
      this.userForm.get('password_confirm').updateValueAndValidity();
    });
    if (this.isAnEditForm) {
      this.userForm.get('email').disable();
    }
    if (this.isSelfForm) {
      this.userForm.get('roles').disable();
    }
  }

  close () {
    this.dialogRef.close();
  }

  attempt () {
    if (this.loading || this.userForm.invalid) return;
    this.loading = true;
    this.prepareUserDataForAttempt();
    if (this.isSelfForm) {
      this.store.dispatch(authActions.saveUser({ user: this.user }));
    } else {
      this.store.dispatch(userActions.saveUser({ user: this.user }));
    }
    this.attemptedToSave = true;
  }

  prepareUserDataForAttempt () {
    const form = this.userForm.getRawValue();
    if (!this.isAnEditForm) {
      this.user = {
        email: form.email,
        created: undefined,
        roles: undefined,
        newToOld: true,
      };
      this.user.email = form.email;
    }
    if (form.password) {
      this.user.password = form.password;
    }
    if (!this.isSelfForm) {
      this.user.roles = {};
      form.roles.forEach((r) => { this.user.roles[r] = true; });
    }
    this.user.newToOld = form.whatShowFirst === 'new';
  }

  ngOnDestroy (): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
