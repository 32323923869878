<h1 mat-dialog-title>
  <span>{{ isAnEditForm ? 'Editar usuario: ' + user.email : 'Crear usuario' }}</span>
  <span>{{ isAnEditForm ? user.created.toDate().toLocaleDateString('es-MX', localeDateOptions) : '' }}</span>
</h1>
<div mat-dialog-content class="columns is-centered">
  <form class="column is-8" *ngIf="userForm" [formGroup]="userForm" autocomplete="off">
    <mat-form-field>
      <input matInput placeholder="Correo electrónico" formControlName="email" autocomplete="off">
      <mat-error *ngIf="userForm.get('email').errors?.required">El correo electrónico es obligatorio.</mat-error>
      <mat-error *ngIf="userForm.get('email').errors?.email">El formato del correo electrónico no es válido.</mat-error>
      <mat-hint>Por motivos de seguridad, el correo electrónico no puede ser modificado posteriormente.</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Forma de desplegar datos</mat-label>
      <mat-select formControlName="whatShowFirst">
        <mat-option [value]="'new'">El más nuevo se muestra primero</mat-option>
        <mat-option [value]="'old'">El más antiguo se muestra primero</mat-option>
      </mat-select>
      <mat-hint>Esto afectará a la vista de usuarios, descripciones, explicaciones y preguntas</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Contraseña" formControlName="password" type="password" autocomplete="off">
      <mat-hint *ngIf="isAnEditForm">Este campo debe llenarse si se quiere cambiar la contraseña del usuario.</mat-hint>
      <mat-error *ngIf="userForm.get('password').errors?.required">La contraseña es obligatoria.</mat-error>
      <mat-error *ngIf="userForm.get('password').errors?.minlength">La contraseña debe tener mínimo 6 caracteres.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Confirma Constraseña" formControlName="password_confirm" type="password" autocomplete="off">
      <mat-error *ngIf="isAnEditForm && userForm.get('password_confirm').errors?.required">Este campo es obligatorio si se quiere cambio de contraseña.</mat-error>
      <mat-error *ngIf="!isAnEditForm && userForm.get('password_confirm').errors?.required">Es obligatorio confirmar la contraseña.</mat-error>
      <mat-error *ngIf="userForm.get('password_confirm').errors?.equal">Las contraseñas no coinciden.</mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="!this.isSelfForm">
      <mat-label>Roles</mat-label>
      <mat-select formControlName="roles" multiple>
        <mat-option *ngFor="let role of roles" [value]="role.value">{{role.text}}</mat-option>
      </mat-select>
      <mat-hint>
        <ul>
          <li><b>{{roles[0].text}}</b>: El usuario podrá crear y editar preguntas que se encuentren en status 'creada' y 'desactivada'.</li>
          <li><b>{{roles[1].text}}</b>: El usuario podrá mover el status de las preguntas y ver su contenido.</li>
          <li><b>{{roles[2].text}}</b>: El usuario podrá crear, editar y eliminar cuentas de usuario para el editor.</li>
          <li><b>{{roles[0].text}} y {{roles[1].text}}</b>: El usuario podrá editar preguntas 'publicadas' sin cambiar su status. <span class="tag is-primary">Nuevo</span></li>
        </ul>
      </mat-hint>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button [disabled]="loading" mat-raised-button (click)="close()">Cancelar</button>
  <button *ngIf="userForm" [ngClass]="{ 'button is-loading': loading }" [title]="userForm.invalid ? 'Es necesario completar el formulario para guardar' : ''"
          (click)="attempt()" color="primary" [disabled]="userForm.invalid" mat-raised-button>Guardar</button>
</div>
