import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './components/permission-components/not-found/not-found.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/permission-components/login/login.component';
import { AuthenticationGuard, AuthenticationGuardInverse, QuestionsGuard, UsersGuard } from './guards/authentication.guard';
import { NotAllowedComponent } from './components/permission-components/not-allowed/not-allowed.component';
import { UserEditComponent } from './components/user-components/user-edit/user-edit.component';
import {
  DescriptionEditionGuard,
  ExplanationEditionGuard,
  FlashcardEditionGuard,
  QuestionEditionGuard,
  QuestionViewGuard,
} from './guards/edition.guard';
import { DescriptionCreationGuard, ExplanationCreationGuard, FlashcardCreationGuard, QuestionCreationGuard } from './guards/creation.guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [AuthenticationGuardInverse] },
  { path: 'editor', component: DashboardComponent, canActivate: [AuthenticationGuard, QuestionsGuard]  },
  { path: 'users', component: UserEditComponent, canActivate: [AuthenticationGuard, UsersGuard]  },
  { path: 'descriptions/create', component: DashboardComponent, canActivate: [AuthenticationGuard, DescriptionCreationGuard] },
  { path: 'descriptions/edit/:id', component: DashboardComponent, canActivate: [AuthenticationGuard, DescriptionEditionGuard] },
  { path: 'explanations/create', component: DashboardComponent, canActivate: [AuthenticationGuard, ExplanationCreationGuard] },
  { path: 'explanations/edit/:id', component: DashboardComponent, canActivate: [AuthenticationGuard, ExplanationEditionGuard] },
  { path: 'flashcards/create', component: DashboardComponent, canActivate: [AuthenticationGuard, FlashcardCreationGuard] },
  { path: 'flashcards/edit/:id', component: DashboardComponent, canActivate: [AuthenticationGuard, FlashcardEditionGuard] },
  { path: 'questions/create', component: DashboardComponent, canActivate: [AuthenticationGuard, QuestionCreationGuard] },
  { path: 'questions/edit/:id', component: DashboardComponent, canActivate: [AuthenticationGuard, QuestionEditionGuard] },
  { path: 'questions/view/:id', component: DashboardComponent, canActivate: [AuthenticationGuard, QuestionViewGuard] },
  { path: 'notFound', component: NotFoundComponent },
  { path: 'notAllowed', component: NotAllowedComponent },
  { path: '**',  redirectTo: '/notFound' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
