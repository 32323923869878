import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatListModule,
  MatTabsModule,
  MatToolbarModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatPaginatorIntl,
  MatTreeModule,
  MatSelectModule,
  MatStepperModule,
  MatTooltipModule,
} from '@angular/material';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatCheckboxModule } from '@angular/material/checkbox';

const matModules = [
  MatButtonModule,
  MatIconModule,
  MatToolbarModule,
  MatTabsModule,
  MatDialogModule,
  MatListModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatTreeModule,
  MatSelectModule,
  MatStepperModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  NgxMatSelectSearchModule,
];

const spanishRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) {
    return `0 de ${length}`;
  }

  const lengthMod = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < lengthMod ?
    Math.min(startIndex + pageSize, lengthMod) :
    startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} de ${lengthMod}`;
};

export function getSpanishPaginatorIntl () {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Registros por página:';
  paginatorIntl.nextPageLabel = 'Página siguiente';
  paginatorIntl.previousPageLabel = 'Página anterior';
  paginatorIntl.getRangeLabel = spanishRangeLabel;

  return paginatorIntl;
}

@NgModule({
  imports: matModules,
  exports: matModules,
  providers: [
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() },
  ],
})
export class CustomMaterialModule { }
