import { Component } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, Validators } from '@angular/forms';
import { HelperValidators } from '../../../validators/helper-validators';
import { Resource, ResourceType } from '../../../core/ngrx/explanations/explanations.interfaces';

@Component({
  selector: 'em-reference-form',
  templateUrl: './reference-form.component.html',
  styleUrls: ['./reference-form.component.scss'],
})
export class ReferenceFormComponent {

  resourcesTypes: ResourceType[] = ['pdf', 'link', 'bibliography'];
  constructor (public controlContainer: ControlContainer, private formBuilder: FormBuilder) {}

  public get getResourcesArray (): FormArray {
    return <FormArray>this.controlContainer.control.get('resourcesArray');
  }

  createResourceInputForm (res?: Resource) {
    const title = res && res.title ? res.title : '';
    const type = res && res.type ? res.type : '';
    const url = res && res.url ? res.url : '';
    return this.formBuilder.group(
      {
        title: [title, Validators.required],
        type: [type, Validators.required],
        url: [url],
      },
    );
  }

  addReference (res?: Resource) {
    const arr = <FormArray>this.controlContainer.control.get('resourcesArray');
    arr.push(this.createResourceInputForm(res));
    const c = arr.controls[arr.controls.length - 1];
    c.get('type').valueChanges.subscribe((val) => {
      c.get('url').setValidators(null);
      c.get('url').updateValueAndValidity();
      c.get('url').setValidators([HelperValidators.requiredIf(val !== 'bibliography'), HelperValidators.checkURL(val)]);
      c.get('url').updateValueAndValidity();
    });
  }

  removeReference (index: number) {
    const arr = <FormArray>this.controlContainer.control.get('resourcesArray');
    arr.removeAt(index);
  }

}
