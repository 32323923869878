import { Action, ActionReducerMap, createReducer, on } from '@ngrx/store';
import { SelectedUserState, UsersState, UserState } from './editorUsers.interfaces';
import {
  deleteUser,
  deleteUserFail, deleteUserSuccess,
  loadUsers,
  loadUsersFail,
  loadUsersSuccess,
  openEditUserForm,
  openEditUserFormFail,
  openEditUserFormSuccess, saveUser, saveUserFail, saveUserSuccess,
} from './editorUsers.actions';

const usersInitialState: UsersState = {
  previews: [],
  previewsTotalCount: -1,
  loading: false,
};

const userInitialState: SelectedUserState = {
  user: undefined,
  loading: false,
};

const usersLocalReducer = createReducer(
  usersInitialState,
  on(loadUsers, (state: UsersState) => {
    const newState: UsersState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(loadUsersSuccess, (state: UsersState, { userPreviews, total }) => {
    const newState: UsersState = {
      ...state,
      previewsTotalCount: total,
      previews: userPreviews,
      loading: false,
    };
    return newState;
  }),
  on(loadUsersFail, (state: UsersState, { error }) => {
    const newState: UsersState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
);

const userLocalReducer = createReducer(
  userInitialState,
  on(openEditUserForm, (state: SelectedUserState) => {
    const newState: SelectedUserState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(openEditUserFormSuccess, (state: SelectedUserState, { user }) => {
    const newState: SelectedUserState = {
      ...state,
      user,
      loading: false,
    };
    return newState;
  }),
  on(saveUser, (state: SelectedUserState) => {
    const newState: SelectedUserState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(saveUserSuccess, (state: SelectedUserState, { user }) => {
    const newState: SelectedUserState = {
      ...state,
      user,
      loading: false,
    };
    return newState;
  }),
  on(deleteUser, (state: SelectedUserState) => {
    const newState: SelectedUserState = {
      ...state,
      loading: true,
      error: undefined,
    };
    return newState;
  }),
  on(deleteUserSuccess, (state: SelectedUserState) => {
    const newState: SelectedUserState = {
      ...state,
      user: undefined,
      loading: false,
    };
    return newState;
  }),
  on(openEditUserFormFail, saveUserFail, deleteUserFail, (state: SelectedUserState, { error }) => {
    const newState: SelectedUserState = {
      ...state,
      error,
      loading: false,
    };
    return newState;
  }),
);

export function userReducer (state: UsersState | undefined, action: Action) {
  return usersLocalReducer(state, action);
}

export function selectedUserReducer (state: SelectedUserState | undefined, action: Action) {
  return userLocalReducer(state, action);
}

export const usersReducer: ActionReducerMap<UserState> = {
  selectedUser: selectedUserReducer,
  users: userReducer,
};
